@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
	#MainImg {
		width: 100%;
		#MainImgInner {
			width: 100%;
			height: auto;
			overflow: hidden;
			position: relative;
			display: block;
			background-color: #fff;
			.slideImg {
				width: 100%;
				min-width: 1280px;
				padding-top: 64.51425247236766%;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				li {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background-repeat: no-repeat;
					background-position: center;
					background-size: auto 100%;
					padding-left: 0;
					margin-top: 0 !important;
					&:before { display: none; }
					&:nth-of-type(1) {
						background-image: url(../img/contents/top/mainimg_01.jpg);
						animation: slider01 24s -1.992s linear infinite;
					}
					&:nth-of-type(2) {
						background-image: url(../img/contents/top/mainimg_02.jpg);
						animation: slider02 24s linear infinite;
					}
					&:nth-of-type(3) {
						background-image: url(../img/contents/top/mainimg_03.jpg);
						animation: slider03 24s linear infinite;
					}
				}
			}
			.button {
				position: absolute;
				width: 228px;
				height: 46px;
				background: #000 url(../img/contents/arrow_03.png) no-repeat right 16px center;
				background-size: 12px auto;
				font-size: 15px;
				color: #fff;
				letter-spacing: 0.02em;
				line-height: 46px;
				padding-left: 20px;
				border-radius: 25px;
				right: 40px;
				bottom: 40px;
			}
		}
	}
	#Main {
		#ContBox01 {
			padding: 0;
			margin-bottom: 175px;
			.innerBasic { max-width: 100%; }
			.flexBox {
				flex-wrap: wrap;
				justify-content: space-between;
				.flexItem {
					width: calc( (100% - 2px) / 3 );
					height: 361px;
					border-bottom: 1px solid #fff;
					a {
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						background: #284376 url(../img/contents/arrow_01.png) no-repeat right 41px bottom 47px;
						background-size: 10px auto;
						transition: background-color 0.5s ease;
						&:hover { background-color: #00266f; }
					}
					figure {
						width: 74.09470752089136%;
						height: 210px;
						overflow: hidden;
						img {
							width: 100%;
							// height: auto;
							height: 100%;
							object-fit: cover;
						}
					}
					p {
						font-size: 22px;
						font-weight: bold;
						color: #fff;
						letter-spacing: 0.025em;
						line-height: 32px;
						position: absolute;
						left: 34px;
						bottom: 38px;
					}
					&_04 a {
						background-image: url(../img/contents/link_02.png);
						background-position: right 40px bottom 48px;
						background-size: 14px auto;
					}
				}
			}
		}
		#ImportPost {
			margin-bottom: 104px;
		}
		#ContBox02 {
		}
		#ContBox03 {
			position: relative;
			.boxHead {
				width: calc(100% + 119px);
				background-color: #f8f8f8;
				padding: 41px 0 66px;
				margin-left: -59px;
				border-top: 1px solid #e6e6e6;
				border-bottom: 1px solid #e6e6e6;
				h2 {
					font-size: 32px;
					font-weight: bold;
					letter-spacing: 0.025em;
					line-height: 44px;
					text-align: center;
					position: relative;
					small {
						display: block;
						font-size: 26px;
						letter-spacing: 0.025em;
						margin-bottom: -2px;
					}
					&:after {
						position: absolute;
						content: '';
						width: 64px;
						height: 6px;
						background-color: #009a6a;
						left: 50%;
						bottom: -20px;
						transform: translateX(-50%);
					}
				}
			}
			.boxContent {
				padding: 56px 25px 163px 0;
				.flexBox {
					justify-content: center;
					.flexItem01 {
						flex-grow: 1;
						img {
							display: block;
							width: 100%;
							max-width: 626px;
							height: auto;
							margin: 0 auto;
						}
					}
					.flexItem02 {
						position: relative;
						padding-bottom: 67px;
						margin-left: 24px;
						ul {
							li {
								width: 290px;
								height: 58px;
								text-align: center;
								border-radius: 40px;
								opacity: 1;
								transition: opacity 0.5s ease;
								padding-left: 0;
								&:before { display: none; }
								span {
									display: inline-block;
									font-size: 16px;
									font-weight: bold;
									color: #fff;
									line-height: 58px;
									background: url(../img/contents/arrow_01.png) no-repeat right center;
									background-size: 8px auto;
									padding-right: 27px;
								}
								&.js-pop-01 { background-color: #712512; }
								&.js-pop-02 { background-color: #a4381a; }
								&.js-pop-03 { background-color: #e0684e; }
								&.js-pop-04 { background-color: #f099a8; }
								&.js-pop-05 { background-color: #e95f7b; }
								&.js-pop-06 { background-color: #ba2b76; }
								&.js-pop-07 { background-color: #25437d; }
								&.js-pop-08 { background-color: #65437c; }
								&.js-pop-09 { background-color: #49873c; }
								&.js-pop-10 { background-color: #3a682f; }
								&.js-pop-06, &.js-pop-10 {
                                    padding-top: 9px;
                                    span{
                                        line-height: 20px;
                                    }
                                }
								& + li { margin-top: 20px; }
								&:hover {
									cursor: pointer;
									opacity: 0.7;
								}
							}
						}
						p {
							position: absolute;
							font-size: 14px;
							letter-spacing: 0;
							line-height: 24px;
							white-space: nowrap;
							right: 0;
							bottom: 0;
						}
					}
				}
			}
			.popOpenWrapper {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100vh;
				background-color: rgba(2552,255,255,0.8);
				padding-left: 201px;
				z-index:51;
				display: none;
				&.open {
					display: flex;
				}
				.popOpenInner {
					position: relative;
					display: flex;
					width: 100%;
					height: 100%;
					justify-content: center;
					align-items: center;
					padding: 70px 0;
					&.center {
						top: 50%;
						transform: translateY(-50%);
					}
					&:hover { cursor: pointer; }
				}
				.popOpen {
					display: none;
					width: 59.31417979610751%;
					height: 100%;
					max-height: 100%;
					background-color: #f8f8f8;
					border: 1px solid #ececec;
					position: relative;
					z-index: 1;
					&:hover { cursor: auto; }
					h3 {
						font-size: 24px;
						font-weight: bold;
						color: #fff;
						letter-spacing: 0.025em;
						line-height: 30px;
						padding: 34px 70px 35px;
					}
					.content {
						height: calc(100% - 99px);
			    	overflow-y: scroll;
			    	scrollbar-width: thin; /*firefox用*/
			    	&::-webkit-scrollbar { width: 6px; }
			    	&::-webkit-scrollbar-thumb {
			    		background: #d7d7d7;
						  border-radius: 6px;
			    	}
						.inner {
							height: auto;
				    	padding: 34px 60px 56px 69px;
				    }
			    	h4 {
			    		font-size: 20px;
			    		font-weight: bold;
			    		text-align: center;
			    		& + p { margin-top: 10px; }
			    	}
						p {
							letter-spacing: 0.025em;
							line-height: 30px;
						}
						figure {
							margin-top: 24px;
							img {
								width: 100%;
								height: auto;
							}
						}
						a {
							display: block;
							width: 152px;
							height: 46px;
							background: #000 url(../img/contents/arrow_01.png) no-repeat right 39px center;
							background-size: 8px auto;
							font-size: 14px;
							font-weight: bold;
							color: #fff;
							letter-spacing: 0.05em;
							line-height: 46px;
							padding-left: 42px;
							margin: 28px auto 0;
							border-radius: 30px;
							& + h4 { margin-top: 50px; }
						}
					}
					&.is_visible { display: block; }
				}
				button {
					position: absolute;
					&.popClose {
						width: 58px;
						height: 58px;
						background: url(../img/contents/top/close.png) no-repeat left top;
						background-size: 100% auto;
						top: -67px;
						right: -67px;
					}
					&.popPrev {
						width: 22px;
						height: 32px;
						background: url(../img/contents/top/arrowL.png) no-repeat left top;
						background-size: 100% auto;
						top: 50%;
						left: -56px;
						transform: translateY(-50%);
					}
					&.popNext {
						width: 22px;
						height: 32px;
						background: url(../img/contents/top/arrowR.png) no-repeat left top;
						background-size: 100% auto;
						top: 50%;
						right: -56px;
						transform: translateY(-50%);
					}
				}
				.popOpen01 h3 { background-color: #712512; }
				.popOpen02 h3 { background-color: #a4381a; }
				.popOpen03 h3 { background-color: #e0684e; }
				.popOpen04 h3 { background-color: #f099a8; }
				.popOpen05, .popOpen06 {
					.content a { margin-top: 14px; }
				}
				.popOpen05 h3 { background-color: #e95f7b; }
				.popOpen06 h3 { background-color: #ba2b76; }
				.popOpen07 h3 { background-color: #25437d; }
				.popOpen08 h3 { background-color: #65437c; }
				.popOpen09 h3 { background-color: #49873c; }
				.popOpen10 h3 { background-color: #3a682f; }
			}
		}
	}
}

@keyframes slider01 {
  0% { opacity: 0; }
  8.3% { opacity: 1; }
  33.3% { opacity: 1; }
  41.6% { opacity: 0; }
  80% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes slider02 {
  0% { opacity: 0; }
  25.1% { opacity: 0; }
  33.4% { opacity: 1; }
  58.4% { opacity: 1; }
  66.7% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes slider03 {
  0% { opacity: 0; }
  27% { opacity: 0; }
  58.4% { opacity: 0; }
  66.7% { opacity: 1; }
  91.7% { opacity: 1; }
  100% { opacity: 0; }
}

/*
教育学研究科・教育学部について
-------------------------------------*/
#PageOverview, #PageFacility,#PagePublications {
	#MainImg {
		#MainImgInner {
			figure { background-image: url(../img/contents/overview/mainimg.jpg); }
		}
	}
	#Main {
		padding-top: 110px;
		padding-bottom: 200px;
	}
	&:not(.pageIndex) {
		#Main {
			.boxContent {
				p {
					line-height: 40px;
					& + p { margin-top: 40px; }
				}
			}
		}
	}
}
#PageOverview.pageIndex {
	#Main {
		padding-top: 112px;
		padding-bottom: 199px;
		#ContBox02 {
			.boxContent { padding-bottom: 197px; }
		}
	}
}

/*
研究科長あいさつ
-------------------------------------*/
#PageOverview.pageDean {
 	#Main {
 		#ContBox01 {
 			margin-bottom: 188px;
 			.boxHead {
 				h3 { margin: 40px 0 110px; }
 			}
 			.boxContent {
 				figure {
 					position: relative;
 					padding-bottom: 126px;
 					margin-bottom: 76px;
 					img { width: 100%; }
 					figcaption {
 						position: absolute;
 						right: 133px;
 						bottom: 0;
 						p {
 							font-size: 26px;
 							letter-spacing: 0;
 							line-height: 1;
 							margin-bottom: 12px;
 						}
 						span {
 							display: block;
 							font-size: 15px;
 							letter-spacing: 0;
 							line-height: 24px;
 						}
 						&:before {
 							position: absolute;
 							content: '';
 							width: 1px;
 							height: 96px;
 							background-color: #000;
 							top: -9px;
 							left: -42px;
 						}
 						&:after {
 							position: absolute;
 							content: '';
 							width: 140px;
 							height: 1px;
 							background-color: #000;
 							top: 38px;
 							left: -168px;
 						}
 					}
 				}
 				.text { padding: 0 20px; }
 			}
 		}
 		#ContainerMenu {
 			.flexBox .flexItem01 { background-color: #e1f4ee; }
 		}
 	}
}

/*
歩み
-------------------------------------*/
#PageOverview.pageHistory {
 	#Main {
 		#ContBox01 {
 			margin-top: 48px;
 			margin-bottom: 192px;
 			.boxContent {
 				& > p {
 					padding-left: 20px;
 					padding-right: 20px;
 				}
 			}
 		}
 		#ContainerMenu {
 			.flexBox .flexItem02 { background-color: #e1f4ee; }
 		}
 	}
}

/*
管理運営組織
-------------------------------------*/
#PageOverview.pageMoorg {
	#Main {
		padding-top: 111px;
		#ContBox01 {
			margin-top: 48px;
			margin-bottom: 	201px;
			.boxContent {
				& > p {
					padding-left: 20px;
 					padding-right: 20px;
				}
				figure {
					margin-top: 72px;
					img { width: 100%; }
				}
			}
		}
		#ContainerMenu {
 			.flexBox .flexItem03 { background-color: #e1f4ee; }
 		}
	}
}

/*
研究教育組織
-------------------------------------*/
#PageOverview.pageReorg {
	#Main {
		#ContBox01 {
			margin-top: 48px;
			margin-bottom: 	190px;
			.boxContent {
				& > p { text-indent: 1em; }
				figure {
					margin-top: 49px;
					margin-bottom: 85px;
					img { width: 100%; }
				}
				.subBox {
					&_content {
						padding-left: 28px;
					}
					& + .subBox { margin-top: 57px; }
				}
			}
		}
		#ContainerMenu {
 			.flexBox .flexItem04 { background-color: #e1f4ee; }
 		}
	}
}

/*
附属施設等
-------------------------------------*/
#PageFacility {
	#Main {
		#ContainerMenu {
 			.flexBox .flexItem05 { background-color: #e1f4ee; }
 		}
	}
}
#PageFacility.pageIndex {
	#Main {
		padding-top: 111px;
		#ContBox01 {
			margin-top: 59px;
			margin-bottom: 200px;
			.flexBox {
				flex-wrap: wrap;
				margin-bottom: -63px;
				.flexItem {
					width: calc( (100% - 60px) / 3 );
					margin-right: 30px;
					margin-bottom: 63px;
					a {
						display: block;
						width: 100%;
						height: 100%;
						transition: 0.5s ease;
						img { transition: 0.5s ease; }
						&:hover {
							color: #009a6a;
							dt img { opacity: 0.7; }
						}
					}
					dt {
						width: 100%;
						img { width: 100%; }
					}
					dd {
						font-weight: bold;
						line-height: 40px;
						background: url(../img/contents/arrow_02.png) no-repeat right 3px center;
						background-size: 8px auto;
						padding: 14px 0;
						border-bottom: 1px solid #009a6a;
					}
					&:nth-of-type(3n) { margin-right: 0; }
				}
			}
		}
	}
}

/*
附属施設等 教育学部図書室
-------------------------------------*/
#PageFacility.pageLibrary {
	#Main {
		.lowerLink {
			&_item_01 a { color: #888; }
		}
		.lowerPageTitle { margin-top: 96px; }
		& > figure {
			width: calc(100% - 119px);
			max-width: 1000px;
			margin: 47px auto 97px;
			img { width: 100%; }
		}
		#ContBox01 {
			.boxContent { margin-top: 40px; }
		}
		#ContBox02 {
			margin-top: 100px;
			margin-bottom: 200px;
			.boxContent {
				padding-top: 27px;
				& > p {
					padding-left: 23px;
					margin-bottom: 25px;
				}
				.pdfLink {
					border-top: 1px solid #e9e9e9;
					margin-bottom: 98px;
					a {
						display: block;
						width: 100%;
						line-height: 40px;
						background: url(../img/contents/arrow_02.png) no-repeat right 31px center;
						background-size: 10px auto;
						padding: 26px 50px 33px 42px;
						border-bottom: 1px solid #e9e9e9;
						&:hover { color: #009b6b; }
						&:after { display: none; }
						span {
							display: inline-block;
							background: url(../img/contents/icon_pdf.png) no-repeat right 1px top 9px;
							background-size: 19px auto;
							padding-right: 34px;
						}
					}
				}
				.subBox {
					& + .subBox { margin-top: 69px; }
					&_title {
						font-size: 22px;
						margin-bottom: 16px;
					}
					&_content {
						padding-left: 34px;
						h5 {
							font-size: 18px;
							font-weight: bold;
							letter-spacing: 0;
							line-height: 30px;
							margin-bottom: 7px;
							text-indent: -16px;
						}
						& > p { line-height: 26px; }
						.dotList {
							padding-left: 5px;
							& + h5 { margin-top: 8px; }
						}
						p {
							& + ul { margin-top: 18px; }
							strong {
								font-size: 18px;
								font-weight: bold;
								line-height: 40px;
							}
							& + table { margin-top: 20px; }
						}
					}
				}
				.subBox01 {
					.subBox_content {
						p {
							padding-left: 20px;
							margin-top: 27px;
						}
						ul {
							li span {
								display: block;
								margin-top: 10px;
							}
						}
					}
				}
				.subBox04 {
					.subBox_content {
						padding-left: 28px;
						table {
							margin-bottom: 16px;
							th.bg-green {
								&:nth-of-type(1) { width: 26%; }
								&:nth-of-type(2) { width: 34%; }
								&:nth-of-type(3) { width: 26%; }
								&:nth-of-type(4) { width: 14%; }
							}
							td {
								small { font-size: 15px; }
								&:nth-of-type(2),
								&:nth-of-type(3) { text-align: center; }
							}
						}
					}
				}
				.subBox07 {
					.subBox_content {
						ul + h5 { margin-top: 17px; }
					}
				}
				.subBox09 {
					.subBox_content {
						ul li {
							small {
								display: block;
								font-size: 15px;
								margin: 5px 0 8px;
							}
							a { line-height: 36px; }
						}
					}
				}
				.subBox11 {
					.subBox_content {
						p {
							line-height: 36px;
							margin-top: -5px;
						}
					}
				}
			}
		}
	}
}

/*
附属施設等 グローバル教育展開オフィス
-------------------------------------*/
#PageFacility.pageGlobal {
	#Main {
		.lowerLink {
			&_item_02 a { color: #888; }
		}
		.lowerPageTitle { margin-top: 96px; }
		.contBox {
			margin-top: 148px;
			.boxHead {
				.title {
					font-size: 22px;
				}
			}
			.boxContent {
				.subBox {
					margin-top: 48px;
					&_content {
						margin-top: 30px;
						p {
							padding-left: 22px;
							margin-bottom: 41px;
						}
					}
				}
			}
		}
		#ContBox01 {
			margin-top: 29px;
			.boxContent {
				.text {
					padding-left: 22px;
					img {
						float: right;
						width: 300px;
						margin: 6px 0 54px 40px;
					}
				}
				figure {
					margin-top: 73px;
					img { width: 100%; }
				}
			}
		}
		#ContBox04 {
			margin-bottom: 146px;
			.boxContent {
				p.red {
					color: #ff0000;
					padding-left: 22px;
					margin-top: 14px;
				}
			}
		}
	}
}

/*
附属施設等 心理教育相談室
-------------------------------------*/
#PageFacility.pageRoom {
	#Main {
		.lowerLink {
			&_item_03 a { color: #888; }
		}
		.lowerPageTitle { margin-top: 96px; }
		.contBox {
			margin-top: 97px;
			.boxContent { margin-top: 31px; }
			p { padding-left: 22px; }
		}
		#ContBox01 {
			margin-top: 29px;
			.boxContent {
				margin-top: 0;
				.text {
					img {
						float: right;
						width: 300px;
						margin: 6px 0 54px 40px;
					}
				}
				figure {
					width: 100%;
					margin-top: 64px;
					img { width: 100%; }
				}
			}
		}
		#ContBox02 {
			.boxContent {
				.subBox {
					background-color: #eff8f5;
					padding: 38px 49px 34px;
					border-radius: 10px;
					margin-top: 41px;
					h4 {
						font-size: 22px;
						font-weight: bold;
						color: #009a6b;
						letter-spacing: 0.025em;
						line-height: 30px;
					}
					ul {
						padding-left: 23px;
						margin-top: 18px;
						li {
							line-height: 40px;
							padding-left: 12px;
							&:before {
								width: 3px;
								height: 3px;
								top: 18px;
							}
							& + li { margin-top: 0; }
						}
					}
				}
			}
		}
		#ContBox03 {
			.boxContent {
				table { margin-top: 40px; 	}
			}
		}
		#ContBox04 {
			.boxContent {
				h4 {
					font-size: 18px;
					font-weight: bold;
					line-height: 30px;
					padding-left: 11px;
					margin-bottom: 7px;
				}
				p {
					& + h4 { margin-top: 17px; }
				}
			}
		}
		#ContBox05 {
			margin-bottom: 200px;
			.boxContent {
				.map {
					justify-content: space-between;
					margin-top: 36px;
					.mapItem {
						width: calc( (100% - 30px) / 2  );
						figure {
							border: 1px solid #ececec;
							img { width: 100%; }
						}
						a {
							display: block;
							width: 162px;
							height: 42px;
							font-size: 14px;
							font-weight: bold;
							color: #009b6b;
							letter-spacing: 0.05em;
							line-height: 40px;
							background: url(../img/contents/icon_pdf.png) no-repeat right 37px center;
							background-size: 18px auto;
							padding-left: 38px;
							margin: 30px auto 0;
							border: 1px solid #e9e9e9;
							border-radius: 42px;
							transition: 0.5s ease;
							&:hover {
								color: #fff;
								background-color: #009b6b;
								border-color: #009b6b;
							}
						}
					}
				}
			}
		}
	}
}

/*
附属施設等 こころの支援室
-------------------------------------*/
#PageFacility.pageSupport {
	#Main {
		.lowerLink {
			&_item_04 a { color: #888; }
		}
		.lowerPageTitle { margin-top: 96px; }
		#ContBox01 {
			margin-top: 29px;
			margin-bottom: 188px;
			.boxContent {
				padding-left: 22px;
				img {
					float: right;
					width: 300px;
					margin: 6px 0 27px 40px;
				}
				ol {
					margin-bottom: 17px;
					& > li {
						font-size: 18px;
						font-weight: bold;
						line-height: 30px;
					}
				}
				ul {
					margin: 18px 0 42px;
					padding-left: 12px;
					li { font-weight: 500; }
				}
			}
		}
	}
}

/*
附属施設等 附属臨床教育実践研究センター
-------------------------------------*/
#PageFacility.pageResearch {
	#Main {
		.lowerLink {
			&_item_05 a { color: #888; }
		}
		.lowerPageTitle { margin-top: 96px; }
		#ContBox01 {
			margin-top: 29px;
			.boxContent {
				padding-left: 22px;
				img {
					float: right;
					width: 300px;
					margin: 6px 0 27px 40px;
				}
			}
		}
		#ContBox02 {
			margin-top: 84px;
			margin-bottom: 197px;
			.boxContent {
				padding-top: 78px;
				figure {
					&:last-of-type { margin-top: 130px; }
					img { width: 100%; }
				}
			}
		}
	}
}

/*
刊行物・資料
-------------------------------------*/
#PagePublications {
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		.pageTitle {
			position: relative;
			.button {
				position: absolute;
				height: 46px;
				font-size: 14px;
				font-weight: bold;
				color: #009b6b;
				letter-spacing: 0.05em;
				line-height: 46px;
				background: #fff url(../img/contents/arrow_02.png) no-repeat right 32px center;
				background-size: 8px auto;
				padding: 0 52px 0 34px;
				border-radius: 46px;
				top: 27px;
				right: 21px;
				&:hover {
					background-color: #009b6b;
					background-image: url(../img/contents/arrow_01.png);
					color: #fff;
					opacity: 1;
				}
			}
		}
		.contBox {
			.boxContent {
				.postList {
					li {
						a {
							padding-left: 41px;
							.content { width: 100%; }
						}
					}
				}
			}
		}
		#ContainerMenu {
			.flexBox .flexItem06 { background-color: #e1f4ee; }
		}
	}
}
#PagePublications.pageIndex {
	#Main {
		.lowerLink {
			&_item a {
				padding-right: 20px;
				background-image: url(../img/contents/arrow_04.png);
				background-position: right 2px center;
				background-size: 11px auto;
			}
		}
		.contBox {
			margin-top: 135px;
			.boxHead {
				position: relative;
				margin-bottom: 47px;
				.button {
					position: absolute;
					display: block;
					height: 46px;
					font-size: 14px;
					font-weight: bold;
					color: #009b6b;
					letter-spacing: 0.05em;
					line-height: 44px;
					background-color: #fff;
					padding: 0 31px 0 34px;
					border: 1px solid #e9e9e9;
					border-radius: 40px;
					top: -9px;
					right: 0;
					span {
						display: inline-block;
						background: url(../img/contents/arrow_02.png) no-repeat right center;
						background-size: 8px auto;
						padding-right: 21px;
					}
					&:hover {
						opacity: 1;
						background-color: #009b6b;
						color: #fff;
						border-color: #009b6b;
						span { background-image: url(../img/contents/arrow_01.png); }
					}
				}
			}
		}
		#ContBox01 {
			margin-top: 100px;
		}
		#ContBox05 {
			.boxHead { margin-bottom: 27px; }
			.boxContent {
				.post {
					padding-left: 22px;
					p {
						letter-spacing: 0;
						line-height: 40px;
					}
				}
			}
		}
		#ContBox06 {
			margin-bottom: 204px;
			.boxHead { margin-bottom: 64px; }
		}
	}
}

/*
刊行物・資料 カテゴリ
-------------------------------------*/
#PagePublications.pageCategory {
	#Main {
		.lowerLink {
			.currect a { color: #888; }
		}
		#ContBox01 {
			margin-top: 100px;
			margin-bottom: 185px;
			.boxContent {
				padding-top: 47px;
				.postList {
					li a { padding-left: 40px; }
				}
				.thumbPostList {
					&_item {
						.content p { line-height: 26px; }
					}
				}
			}
		}
	}
}

/*
刊行物・資料 記事詳細
-------------------------------------*/
#PagePublications.pageEntry {
	#Main {
		#ContBox01 {
			margin-top: 100px;
			margin-bottom: 197px;
			.boxContent {
				padding-top: 57px;
				.entryHead {
					flex-wrap: wrap;
	        p {
	          font-size: 25px;
	          font-weight: bold;
	          letter-spacing: 0.025em;
	          line-height: 30px;
	          padding-bottom: 16px;
	          border-bottom: 2px solid #009a6b;
	        }
	        div {
	          flex-grow: 1;
	          border-bottom: 2px solid #ececec;
	        }
				}
				.entryBox {
					padding-top: 35px;
					padding-left: 22px;
				}
			}
		}
	}
}

/*
広報用ロゴマークについて
-------------------------------------*/
#PageOverview.pageEduc {
	#Main {
		#ContBox01 {
			margin-top: 55px;
			margin-bottom: 187px;
			.boxContent {
				figure {
					text-align: center;
					margin-bottom: 43px;
					img {
						width: 208px;
						height: auto;
					}
				}
			}
		}
		#ContainerMenu {
			.flexBox .flexItem07 { background-color: #e1f4ee; }
		}
	}
}

/*
お問い合わせ先
-------------------------------------*/
#PageOverview.pageContact {
	#Main {
		.contBox {
			margin-top: 90px;
			.boxContent {
				margin-top: 55px;
				padding-left: 21px;
				p {
					line-height: 26px;
					margin-bottom: 26px;
					&.red { color: #ff0000; }
				}
				table + p { margin-top: 33px; }
			}
		}
		#ContBox01 {
			margin-top: 41px;
		}
		#ContBox05 { margin-bottom: 194px; }
		#ContainerMenu {
			.flexBox .flexItem08 { background-color: #e1f4ee; }
		}
	}
}

/*
アクセス
-------------------------------------*/
#PageOverview.pageAccess {
	#Main {
		.contBox {
			.boxHead {
				.boxTitle {
					line-height: 40px;
					&:before { top: 9px; }
				}
			}
		}
		#ContBox01 {
			margin-top: 45px;
			.boxContent {
				padding-top: 43px;
				&:after {
					display: block;
					content: '';
					clear: both;
				}
				.map {
					position: relative;
					width: 100%;
					padding-top: 65.625%;
					margin-bottom: 28px;
					border: 1px solid #ececec;
					overflow: hidden;
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				a {
					float: right;
					display: block;
					width: 200px;
					height: 46px;
					font-size: 14px;
					font-weight: bold;
					color: #009b6b;
					letter-spacing: 0.05em;
					line-height: 46px;
					background: url(../img/contents/link_03.png) no-repeat right 30px top 16px;
					background-size: 11px auto;
					padding-left: 30px;
					border: 1px solid #f3f3f3;
					border-radius: 46px;
					&:hover {
						opacity: 1;
						color: #fff;
						background-image: url(../img/contents/link_02.png);
						background-color: #009b6b;
						border-color: #009b6b;
					}
				}
			}
		}
		#ContBox02 {
			margin-top: 111px;
			.boxContent {
				padding-top: 43px;
				figure {
					img { width: 100%; }
				}
			}
		}
		#ContBox03 {
			margin-top: 134px;
			margin-bottom: 188px;
			.boxContent {
				padding-top: 44px;
				& > p { padding-left: 22px; }
				.contSubBox {
					margin-top: 51px;
				}
				.subBox {
					margin-top: 46px;
					&_title {
						margin-bottom: 15px;
					}
					&_content {
						padding-left: 22px;
						ol {
							& + p { margin-top: 35px; }
							li ul { margin-top: 10px; }
						}
						p {
							& + ol { margin-top: 16px; }
						}
					}
				}
			}
		}
		#ContainerMenu {
			.flexBox .flexItem09 { background-color: #e1f4ee; }
		}
	}
}

/*
学部受験生の方へ トップページ
-------------------------------------*/
#PageAdmissions {
	#MainImg {
		#MainImgInner {
			figure { background-image: url(../img/contents/admissions/mainimg.jpg); }
		}
	}
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
	}
	&:not(.pageIndex) {
		#Main {
			.boxContent {
				p {
					line-height: 40px;
					& + p { margin-top: 40px; }
				}
			}
		}
	}

	.containerMenu {
		.flexBox {
			.flexItem07, .flexItem08 {
				background-position: right 18px center;
		    background-image: url(../img/contents/icon_link.png);
		    background-size: 20px auto;
			}
		}
	}
}
#PageAdmissions.pageIndex {
	#Main {
		padding-bottom: 239px;
		#ContBox02 {
			.boxContent {
				padding-bottom: 198px;
			}
		}
	}
}

/*
学部受験生の方へ
-------------------------------------*/
#PageAdmissions.pageInfo {
	#Main {
		.lowerLink {
			&_item {
				a {
					background-image: url(../img/contents/arrow_04.png);
					background-size: 12px auto;
				}
			}
		}
		.contBox {
			margin-top: 139px;
			.boxTitle {
				span {
					font-size: 24px;
					color: #ff0000;
					padding-left: 10px;
				}
			}
			.boxContent {
				.subBox {
					margin-top: 60px;
					&_title { margin-bottom: 17px; }
					&_content {
						padding-left: 35px;
						p { line-height: 26px; }
						ul {
							li + li { margin-top: 8px; }
						}
					}
				}
				.borderTitle { margin-bottom: 20px; }
				.square {
					position: relative;
					line-height: 28px;
					padding-left: 16px;
					strong { font-weight: bold; }
					&:before {
						position: absolute;
						content: '';
						width: 3px;
						height: 3px;
						background-color: #000;
						top: 12px;
						left: 4px;
					}
					& + p:not(.square) {
						line-height: 26px;
						padding-left: 28px;
						margin-top: 8px;
					}
					& + ul { padding-left: 35px; }
				}
				h5.square {
					font-size: 18px;
					&:before { top: 12px; }
				}
				p {
					strong {
						display: inline-block;
						font-weight: bold;
						&.red { color: #ff0000; }
					}
					&.remark {
						font-size: 15px;
						line-height: 26px;
						padding-left: 18px;
						margin-left: 40px;
						position: relative;
						&:before {
							position: absolute;
							content: '※';
							top: 0;
							left: 0;
						}
						& + .remark { margin-top: 10px; }
					}
					& + h5.square { margin-top: 19px; }
					& + ul { margin-top: 8px; }
				}
				strong { font-weight: bold; }
				.pdfLink {
					display: flex;
					width: 100%;
					background: url(../img/contents/arrow_02.png) no-repeat right 30px center;
					background-size: 10px auto;
					line-height: 27px;
					padding: 33px 40px 39px;
					margin: 29px 0 30px;
					border: solid #e9e9e9;
					border-width: 1px 0 1px 0;
					span {
						display: inline-block;
						background: url(../img/contents/icon_pdf.png) no-repeat right center;
						background-size: 18px auto;
						padding-right: 32px;
					}
					small {
						display: inline-block;
						color: #9d9d9d;
						margin-left: 47px;
					}
					&:hover span { color: #009a6b; }
					&:after { display: none; }
				}
				table { margin: 22px 0 29px; }
			}
		}
		#ContBox01 {
			margin-top: 96px;
			.boxContent {
				padding-top: 76px;
				figure {
					img { width: 100%; }
				}
			}
		}
		#ContBox02 {
			.boxContent {
				.subBox01 {
					p + p { margin-top: 8px; }
					h5 {
						font-size: 18px;
						font-weight: bold;
						margin-top: 20px;
						margin-bottom: 5px;
					}
				}
			}
		}
		#ContBox03 {
			.boxContent {
				margin-top: 29px;
				& > p:not(.remark) { padding-left: 20px; }
				& > ul {
					padding-left: 39px;
					margin-top: 26px;
				}
				dl {
					margin-top: 47px;
					dt {
						width: 100%;
						background-color: #009b6b;
						font-weight: bold;
						color: #fff;
						letter-spacing: 0.025em;
						line-height: 30px;
						text-align: center;
						padding: 18px 15px 17px;
					}
					dd {
						width: 100%;
						padding: 29px 28px 37px;
						border: solid #009b6b;
						border-width: 0 1px 1px 1px;
						ul { margin-top: 26px; }
					}
				}
				.subBox01 {
					margin-top: 76px;
					p { margin-bottom: 18px; }
				}
				.subBox02 {
					p {
						line-height: 26px;
						& + p { margin-top: 19px; }
					}
				}
				.subBox03 {
					p { line-height: 30px; }
					table { margin: 33px 0 0; }
				}
				.subBox04 {
					ul {
						padding-left: 39px;
						margin-bottom: 32px;
						li {
							strong {
								font-weight: bold;
								&.red {
									display: block;
									color: #ff0000;
									margin-top: 10px;
								}
							}
							& + li { margin-top: 10px; }
						}
					}
				}
				.subBox05 {
					p {
						line-height: 30px;
						margin-bottom: 21px;
					}
					ul {
						li + li { margin-top: 10px; }
					}
				}
			}
		}
		#ContBox04 {
			margin-bottom: 196px;
			.boxContent {
				.square { margin-top: 40px; }
				ul { padding-left: 35px; }
			}
		}
		#ContainerMenu {
 			.flexBox .flexItem01 { background-color: #e1f4ee; }
 		}
	}
}

/*
教育学部のポリシー
-------------------------------------*/
#PageAdmissions.pagePurpose {
	#Main {
		.contBox {
			margin-top: 96px;
			.boxHead { margin-bottom: 30px; }
			.boxContent {
				padding-left: 22px;
				ul, ol {
					margin-top: 40px;
					li {
						line-height: 36px;
						& + li { margin-top: 20px; }
					}
				}
				ul li {
					&:before { top: 15px; }
				}
			}
		}
		#ContBox01 { margin-top: 39px; }
		#ContBox03 { margin-bottom: 196px; }
		#ContainerMenu {
 			.flexBox .flexItem05 { background-color: #e1f4ee; }
 		}
	}
}

/*
教育内容
-------------------------------------*/
#PageAdmissions.pageEducation {
	#Main {
		.lowerLink {
			&_item {
				a {
					background-image: url(../img/contents/arrow_04.png);
					background-size: 12px auto;
				}
			}
		}
		.contBox {
			margin-top: 120px;
			.boxContent {
				padding-top: 28px;
				& > p { padding-left: 22px; }
				.subBox {
					&_content {
						padding-top: 38px;
						.flexBox {
							flex-wrap: wrap;
							background-color: #eff8f5;
							padding: 34px 45px 37px;
							border-radius: 10px;
							p {
								font-size: 18px;
								font-weight: bold;
								color: #009a6b;
								letter-spacing: 0.025em;
								line-height: 48px;
								position: relative;
								& + p {
									margin-left: 56px;
									&:before {
										position: absolute;
										content: '／';
										top: 0;
										left: -38px
									}
								}
							}
						}
						p + p { margin-top: 0; }
					}
				}
				.subBox01 { margin-top: 84px;}
				.subBox02 { margin-top: 57px; }
			}
		}
		#ContBox01 { margin-top: 99px; }
		#ContBox03 { margin-bottom: 200px; }
		#ContainerMenu {
 			.flexBox .flexItem06 { background-color: #e1f4ee; }
 		}
	}
}

/*
取得できる資格
-------------------------------------*/
#PageAdmissions.pageObtain {
	#Main {
		#ContBox01 {
			margin-bottom: 188px;
			.boxContent {
				.subBox {
					margin-top: 86px;
					&_content {
						padding-top: 28px;
						p { padding-left: 21px; }
						img {
							width: 400px;
							height: auto;
							float: right;
							margin-left: 50px;
						}
					}
					&:after {
						display: block;
						content: '';
						clear: both;
					}
				}
				.subBox01 {
					margin-top: 48px;
				}
			}
		}
		#ContainerMenu {
 			.flexBox .flexItem09 { background-color: #e1f4ee; }
 		}
	}
}

/*
卒業後の進路
-------------------------------------*/
#PageAdmissions.pageAfter {
	#Main {
		#ContBox01 {
			margin-top: 39px;
			margin-bottom: 188px;
			.boxContent {
				padding-top: 28px;
				.subBox01 {
					img {
						float: right;
						margin-left: 83px;
					}
					&:after {
						display: block;
						content: '';
						clear: both;
					}
				}
				& > p { padding-left: 21px; }
				.subBox02 {
			    background-color: #eff8f5;
			    padding: 38px 49px 34px;
					margin-top: 98px;
			    border-radius: 10px;
			    &:before {
			    	display: block;
			    	content: '';
			    	clear: both;
			    }
			    h4 {
				    font-size: 22px;
				    font-weight: bold;
				    color: #009a6b;
				    letter-spacing: .025em;
			    	line-height: 30px;
			    	margin-bottom: 18px;
			    }
			    span {
			    	display: block;
			    	font-size: 15px;
			    	line-height: 30px;
			    	padding-left: 18px;
			    	margin-top: 30px;
			    	position: relative;
			    	&:before {
			    		position: absolute;
			    		content: '※';
			    		top: 0;
			    		left: 0;
			    	}
			    }
				}
			}
		}
		#ContainerMenu {
 			.flexBox .flexItem10 { background-color: #e1f4ee; }
 		}
	}
}

/*
大学院受験生・研究者の方へ
-------------------------------------*/
#PageGraduate, #PageResearch, #PageEducation, #PageStaffList {
	#MainImg {
		#MainImgInner {
			figure { background-image: url(../img/contents/graduate/mainimg.jpg); }
		}
	}
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		.containerMenu {
			.boxHead {
				margin-bottom: 40px;
				h3 {
					display: inline-block;
					font-size: 36px;
					line-height: 44px;
					padding-left: 0;
					&:before {
						width: 50px;
						height: 8px;
						top: 10px;
						left: auto;
						right: -83px;
					}
				}
			}
			.flexBox {
				.flexItem06 {
					background-position: right 18px center;
			    background-image: url(../img/contents/icon_link.png);
			    background-size: 20px auto;
				}
			}
		}
		.containerMenu02 {
			margin-top: 117px;
			.flexBox {
				.flexItem03 {
					background-position: right 18px center;
			    background-image: url(../img/contents/icon_link.png);
			    background-size: 20px auto;
				}
			}
		}
	}
}

/*
大学院受験生の方へ
-------------------------------------*/
#PageGraduate.pageInfo {
	#Main {
		.lowerLink {
			&_item a {
				background-image: url(../img/contents/arrow_04.png);
				background-size: 12px auto;
			}
		}
		.contBox {
			margin-top: 139px;
			.boxTitle {
				span {
					font-size: 24px;
					color: #ff0000;
					padding-left: 10px;
				}
			}
			.boxContent {
				.lowerLink {
					width: 100%;
					margin-top: 15px;
					&_item a {
						display: flex;
						line-height: 26px;
						align-items: center;
						padding: 17px 20px 21px 0;
					}
				}
				.contSubBox {
					margin-top: 100px;
				}
				.subBox {
					margin-top: 60px;
					&_title { margin-bottom: 17px; }
					&_content {
						padding-left: 35px;
						p {
							line-height: 26px;
							&.square {
								margin-top: 26px;
							}
						}
						& > ul {
							margin-top: 26px;
							padding-left: 39px;
							li + li { margin-top: 8px; }
						}
					}
				}
				.subBox01 { margin-top: 40px; }
				.borderTitle { margin-bottom: 20px; }
				.square {
					position: relative;
					line-height: 28px;
					padding-left: 16px;
					strong { font-weight: bold; }
					&:before {
						position: absolute;
						content: '';
						width: 3px;
						height: 3px;
						background-color: #000;
						top: 12px;
						left: 4px;
					}
					& + p:not(.square) {
						line-height: 26px !important;
						padding-left: 28px;
						margin-top: 8px;
					}
					& + ul { padding-left: 35px; }
				}
				.red { color: #f00; }
				h5 {
					font-size: 20px;
					font-weight: bold;
					line-height: 28px;
					margin-bottom: 15px;
					&.square {
						font-size: 18px;
						font-weight: 500;
						margin: 0;
						&:before { top: 12px; }
					}
					& + p { line-height: 40px !important; }
				}
				p {
					strong {
						display: inline-block;
						font-weight: bold;
						&.red { color: #ff0000; }
					}
					&.remark {
						font-size: 15px;
						line-height: 26px;
						padding-left: 18px;
						margin-left: 40px;
						position: relative;
						&:before {
							position: absolute;
							content: '※';
							top: 0;
							left: 0;
						}
						& + .remark { margin-top: 10px; }
					}
					& + h5.square { margin-top: 19px; }
					& + ul { margin-top: 8px; }
					& + ol { margin-top: 18px; }
				}
				strong { font-weight: bold; }
				.pdfLink {
					display: flex;
					align-items: center;
					width: 100%;
					background: url(../img/contents/arrow_02.png) no-repeat right 30px center;
					background-size: 10px auto;
					line-height: 27px;
					padding: 33px 60px 39px 40px;
					margin: 29px 0 30px;
					border: solid #e9e9e9;
					border-width: 1px 0 1px 0;
					span {
						display: inline-block;
						background: url(../img/contents/icon_pdf.png) no-repeat right center;
						background-size: 18px auto;
						padding-right: 32px;
						margin-right: 47px;
					}
					small {
						display: inline-block;
						color: #9d9d9d;
						white-space: nowrap;
					}
					&:hover span { color: #009a6b; }
					&:after { display: none; }
				}
				table { margin: 22px 0 29px; }
			}
		}
		#ContBox01 {
			margin-top: 96px;
			.boxContent {
				padding-top: 76px;
				figure {
					img { width: 100%; }
				}
			}
		}
		#ContBox02 {
			.boxContent {
				ul + h5 { margin-top: 50px; }
				.contSubBox02, .contSubBox05 {
					.subBox02, .subBox04 {
						p { line-height: 40px; }
					}
				}
			}
		}
		#ContBox03 {
			margin-bottom: 188px;
			.boxContent {
				.subBox { margin-top: 45px; }
			}
		}
		.containerMenu01 .flexBox .flexItem01 {
			background-color: #e1f4ee;
		}
	}
}

/*
教育学研究科のポリシー
-------------------------------------*/
#PageGraduate.pagePurpose {
	#Main {
		.contBox {
			margin-top: 96px;
			.boxContent {
				padding-top: 28px;
				padding-left: 21px;
				p {
					line-height: 40px;
					strong {
						display: block;
						font-weight: bold;
					}
					& + p { margin-top: 40px; }
					& + ol { margin-top: 40px;}
				}
				ol {
					li {
						line-height: 36px;
						p { line-height: 36px; }
						& + li { margin-top: 20px; }
					}
					& + p { margin-top: 40px; }
				}
				dl {
					margin: 20px 0 40px;
					dt {
				    width: 100%;
				    background-color: #009b6b;
				    font-weight: bold;
				    color: #fff;
				    letter-spacing: .025em;
				    line-height: 30px;
						padding: 18px 15px 17px;
					}
					dd {
				    width: 100%;
				    padding: 29px 28px 37px;
						border: solid #009b6b;
				    border-width: 0 1px 1px 1px;
					}
				}
			}
		}
		.contSubBox {
			width: calc(100% + 21px);
			margin-left: -21px;
			margin-top: 84px;
			&_content {
				padding-top: 29px;
				padding-left: 22px;
			}
			& + dl { margin-top: 60px !important;}
		}
		.subBox {
			margin-top: 40px;
			&_title { margin-bottom: 15px; }
			&_content { padding-left: 29px; }
		}
		#ContBox01 { margin-top: 39px; }
		#ContBox03 { margin-bottom: 188px; }
		.containerMenu01 .flexBox .flexItem04,
		.containerMenu02 .flexBox .flexItem01 {
			background-color: #e1f4ee;
		}
	}
}

/*
研究内容
-------------------------------------*/
#PageResearch, #PageEducation {
	#Main {
		p {
			line-height: 40px;
			& + p { margin-top: 40px; }
		}
		.categoryList {
			h4 {
				font-size: 30px;
				font-weight: bold;
				letter-spacing: 0.025em;
				line-height: 44px;
				margin: 11px 0 35px;
			}
			.flexBox {
				flex-wrap: wrap;
				margin-bottom: -30px;
				.flexItem {
					display: flex;
					align-items: center;
					width: calc( (100% - 60px) / 3 );
					height: 80px;
					background: #009b6b url(../img/contents/arrow_01.png) no-repeat right 18px center;
					background-size: 8px auto;
					font-size: 18px;
					font-weight: bold;
					color: #fff;
					padding: 0 44px 0 24px;
					margin: 0 30px 30px 0;
					p {
						line-height: 26px !important;
						padding-left: 0 !important;
					}
					small {
						display: block;
						font-size: 14px;
					}
					&:nth-of-type(3n) { margin-right: 0; }
					&:hover { background-color: #22ab38; }
				}
                span.flexItem {
                    background-image: none;
                }
			}
		}
		.containerMenu01 .flexBox .flexItem05,
		.containerMenu02 .flexBox .flexItem02 {
			background-color: #e1f4ee;
		}
	}
}
#PageResearch.pageIndex {
	#Main {
		#ContBox01 {
			margin-top: 39px;
			margin-bottom: 191px;
			.boxContent {
				padding-top: 28px;
				& > p { padding-left: 21px; }
				.contSubBox {
					margin-top: 60px;
					&_content {
						padding-top: 29px;
						padding-left: 21px;
						table {
							margin: 56px 0 14px;
							th {
								padding-top: 17px;
								padding-bottom: 20px;
								&:nth-of-type(1) { width: 33.54838709677419%; }
								&:nth-of-type(2) { width: 32.47311827956989%; }
								&:nth-of-type(3) { width: 33.87096774193548%; }
							}
							td {
								padding-top: 10px;
								padding-bottom: 9px;
							}
						}
					}
				}
				.contSubBox03 {
					.contSubBox_content { padding-left: 0; }
				}
			}
		}
	}
}

/*
研究内容 教育学環専攻
-------------------------------------*/
#PageEducation.pageIndex {
	#Main {
		#ContBox01 {
			margin-top: 39px;
			margin-bottom: 195px;
			.boxHead { margin-bottom: 28px; }
		}
	}
}

/*
研究内容 教育学環専攻 カテゴリ
-------------------------------------*/
#PageEducation.pageCategory {
	#Main {
		#ContBox01 {
			margin-top: 45px;
			.boxContent {
				padding: 0 21px 0 22px;
				.entryBox {
					.wp-caption {
						width: calc( (100% - 60px) / 3 );
						float: left;
						margin-right: 30px;
						&:nth-of-type(3) { margin-right: 0; }
					}
					figure { position: relative; }
					img {
						width: 100% !important;
						height: auto;
						margin: 0 !important;
						position: absolute;
						top: 0;
						left: 0;
					}
					p { margin-top: 15px; }
				}
			}
		}
		#ContBox02 {
			margin-top: 113px;
			margin-bottom: 195px;
			.boxContent {
				padding-top: 60px;
				.subBox {
					margin-bottom: 203px;
					&_content {
						padding: 55px 21px 0 18px;
						.flexBox {
							justify-content: space-between;
							margin-bottom: 37px;
							figure {
								width: 280px;
								height: 380px;
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
							}
							div {
								width: calc(100% - 341px);
								h4 {
									font-size: 19px;
									font-weight: bold;
									color: #009b6b;
									letter-spacing: 0;
									line-height: 29px;
									background: #eff8f5 url(../img/contents/icon_13.png) no-repeat left 27px top 26px;
									background-size: 20px auto;
									padding: 20px 15px 19px 70px;
									margin-bottom: 17px;
									border-radius: 10px;
								}
								ul {
									margin-top: 19px;
									padding-left: 15px;
									li + li { margin-top: 14px; }
								}
							}
						}
					}
				}
			}
		}
	}
}

/*
教員一覧
-------------------------------------*/
#PageStaffList {
	#Main {
		.categoryList {
			margin-top: 173px;
			margin-bottom: 196px;
			h4 {
				font-size: 30px;
				font-weight: bold;
				letter-spacing: 0.025em;
				line-height: 44px;
				margin: 11px 0 35px;
			}
			.flexBox {
				flex-wrap: wrap;
				margin-bottom: -30px;
				.flexItem {
					display: flex;
					align-items: center;
					width: calc( (100% - 60px) / 3 );
					height: 80px;
					background: #009b6b url(../img/contents/arrow_01.png) no-repeat right 18px center;
					background-size: 8px auto;
					font-size: 18px;
					font-weight: bold;
					color: #fff;
					padding: 0 44px 0 24px;
					margin: 0 30px 30px 0;
					p {
						line-height: 26px !important;
						padding-left: 0 !important;
					}
					small {
						display: block;
						font-size: 14px;
					}
					&:nth-of-type(3n) { margin-right: 0; }
					&:hover { background-color: #22ab38; }
				}
			}
		}
		.containerMenu01 .flexBox .flexItem07,
		.containerMenu02 .flexBox .flexItem04 {
			background-color: #e1f4ee;
		}
	}
}
#PageStaffList.pageIndex {
	#Main {
		.contBox {
			margin-top: 80px;
			.leaderImage {
				margin-bottom: 49px;
				img { width: 100%; }
			}
			.boxContent {
				margin-top: 34px;
				.teacher {
					flex-wrap: wrap;
					justify-content: space-between;
					&_item {
						width: calc( (100% - 30px) / 2 );
						min-height: 210px;
						margin-bottom: 30px;
						box-shadow: 0 0 13px rgba(74,74,74,0.08);
						a {
							display: flex;
							width: 100%;
							height: 100%;
							position: relative;
							&:after {
								position: absolute;
								content: 'View';
								width: 100%;
								height: 100%;
								font-family: 'EB Garamond', serif;
								font-size: 18px;
								color: #fff;
								letter-spacing: 0.05em;
								line-height: 20px;
								background-color: rgba(42,142,111,0.64);
								display: flex;
								justify-content: center;
								align-items: center;
								opacity: 0;
								transition: opacity 0.5s ease;
							}
							&:hover {
								&:after { opacity: 1; }
							}
						}
						.photo {
							width: 174px;
							background-position: center;
							background-repeat: no-repeat;
							background-size: 100% auto;
						}
						.text {
							width: calc(100% - 174px);
							padding: 24px 20px 20px 24px;
							border: solid #f2f2f2;
							border-width: 1px 1px 1px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							.name {
								font-size: 22px;
								font-weight: bold;
								line-height: 30px;
							}
							.domain {
								width: 100%;
								height: auto;
								font-size: 14px;
								font-weight: bold;
								color: #009b6b;
								line-height: 20px;
								text-align: justify;
								background: #eff8f5 url(../img/contents/icon_13.png) no-repeat left 19px top 18px;
								background-size: 17px auto;
								padding: 15px 16px 16px 46px;
								border-radius: 5px;
							}
						}
					}
				}
			}
		}
		#ContBox01 { margin-top: 30px; }
	}
}

/*
教員一覧 カテゴリ
-------------------------------------*/
#PageStaffList.pageCategory {
	#Main {
		.contBox {
			.boxContent {
				padding-top: 60px;
				.subBox {
					&_content {
						padding: 55px 21px 0 18px;
						.flexBox {
							justify-content: space-between;
							margin-bottom: 37px;
							figure {
								width: 280px;
								height: 380px;
								background-repeat: no-repeat;
								background-position: center;
								background-size: 100% auto;
							}
							& > div {
								width: calc(100% - 341px);
								h4 {
									font-size: 19px;
									font-weight: bold;
									color: #009b6b;
									letter-spacing: 0;
									line-height: 29px;
									background: #eff8f5 url(../img/contents/icon_13.png) no-repeat left 27px top 26px;
									background-size: 20px auto;
									padding: 20px 15px 19px 70px;
									margin-bottom: 17px;
									border-radius: 10px;
								}
								p { line-height: 40px; }
								ul {
									margin-top: 19px;
									padding-left: 15px;
									li + li { margin-top: 14px; }
								}
							}
						}
					}
					& + .subBox { margin-top: 100px; }
				}
			}
		}
		#ContBox01 { margin-top: 39px; }
		.categoryList { margin-top: 203px; }
	}
}

/*
取得できる資格
-------------------------------------*/
#PageGraduate.pageObtain {
	#Main {
		#ContBox01 {
			margin-bottom: 188px;
			.boxContent {
				.subBox {
					margin-top: 86px;
					&_content {
						padding-top: 28px;
						p {
							line-height: 40px;
							padding-left: 21px;
						}
						img {
							width: 400px;
							height: auto;
							float: right;
							margin-left: 50px;
						}
					}
					&:after {
						display: block;
						content: '';
						clear: both;
					}
				}
				.subBox01 {
					margin-top: 48px;
				}
			}
		}
		.containerMenu01 {
 			.flexBox .flexItem08 { background-color: #e1f4ee; }
 		}
	}
}

/*
取得できる資格 公認心理師
-------------------------------------*/
#PageGraduate.pageKouninup {
	#Main {
		#ContBox01 {
			margin-top: 39px;
			margin-bottom: 199px;
			.boxContent {
				padding-top: 28px;
				p {
					line-height: 40px;
					padding: 0 21px;
					&.remark {
						position: relative;
						padding: 0 21px 0 39px;
						line-height: 30px;
						&:before {
							position: absolute;
							content: '※';
							top: 0;
							left: 21px;
						}
					}
					& + p { margin-top: 16px; }
					& + ol { margin-top: 30px; }
				}
				ol, ul {
					padding: 0 21px;
					li { line-height: 30px; }
					& + p { margin-top: 30px; }
				}
				.borderTitle {
					p { padding-left: 0; }
				}
				.contSubBox {
					margin-top: 68px;
					&_content {
						padding-top: 34px;
						& > div {
							padding-left: 21px;
							margin-top: 47px;
							&:first-of-type { margin-top: 0; }
							& + p { margin: 68px 0 -34px; }
						}
						h4 {
							font-size: 20px;
							font-weight: bold;
							line-height: 30px;
							padding-left: 19px;
							margin-bottom: 18px;
							position: relative;
							a {
								color: #009a6b;
								text-decoration: underline;
							}
							&:before {
								position: absolute;
								content: '';
								width: 7px;
								height: 7px;
								background-color: #009b6b;
								border-radius: 50%;
								top: 11px;
								left: 0;
							}
						}
						ol.maru {
							margin: 0;
							li:before { top: 6px; }
						}
					}
				}
			}
		}
		.containerMenu01 {
 			.flexBox .flexItem08 { background-color: #e1f4ee; }
 		}
	}
}

/*
修了後の進路
-------------------------------------*/
#PageGraduate.pageAfter {
	#Main {
		#ContBox01 {
			margin-top: 39px;
			margin-bottom: 183px;
			.boxContent {
				padding-top: 28px;
				img {
					float: right;
					margin-left: 82px;
					&:after {
						display: block;
						content: '';
						clear: both;
					}
				}
				p {
					line-height: 40px;
					padding-left: 22px;
					&.remark {
						position: relative;
						padding-left: 41px;
						&:before {
							position: absolute;
							content: '※';
							top: 0;
							left: 22px;
						}
					}
				}
				.contSubBox {
					margin-top: 84px;
					&_content { padding-top: 36px; }
					&:last-of-type { margin-bottom: 76px; }
				}
				.subBox {
					&_content {
						margin-top: 15px;
						ul {
							padding-left: 39px;
							li {
								strong {
									display: block;
									font-size: 20px;
									font-weight: bold;
									color: #343434;
									margin-bottom: 12px;
								}
								p { padding-left: 0; }
								& + li { margin-top: 23px; }
							}
						}
					}
				}
			}
		}
		.containerMenu01 {
 			.flexBox .flexItem09 { background-color: #e1f4ee; }
 		}
	}
}

/*
一般の皆様へ
-------------------------------------*/
#PageVisitors.pageIndex {
	#MainImg {
		#MainImgInner {
			figure { background-image: url(../img/contents/visitors/mainimg.jpg); }
		}
	}
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		#ContainerMenu {
			.flexBox {
				.flexItem {
					background-image: url(../img/contents/icon_link.png);
					background-position: right 18px center;
					background-size: 20px auto;
				}
			}
		}
	}
}

/*
在校生・卒業生
-------------------------------------*/
#PageTowards {
	#MainImg {
		#MainImgInner {
			figure { background-image: url(../img/contents/towards/mainimg.jpg); }
		}
	}
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		#ContainerMenu {
			.flexBox {
				.flexItem05, .flexItem08, .flexItem10, .flexItem11 {
					background-image: url(../img/contents/icon_link.png);
					background-position: right 18px center;
					background-size: 20px auto;
				}
			}
		}
	}
}

/*
紀要編集委員会からのお知らせ
-------------------------------------*/
#PageTowards.pageSubmission {
	#Main {
		.contBox {
			&:first-of-type{
				margin-top: 39px;
			}
			margin-top: 96px;
			p {
				line-height: 40px;
				& + p { margin-top: 40px; }
			}
			//margin-top: 39px;
			//margin-bottom: 191px;
			.boxContent {
				padding-top: 28px;
				p { padding-left: 21px; }
			}
		}
		#ContainerMenu{
			margin-top: 183px;
			.flexBox{
				.flexItem01{
					background-color: #e1f4ee;
				}
			}
		}
	}
}

/*
証明書の請求方法
-------------------------------------*/
#PageTowards.pageCertificate {
	#Main {
		.lowerLink {
			margin-top: 15px;
			&_item a {
				display: flex;
				line-height: 26px;
				align-items: center;
				padding: 17px 20px 21px 0;
				background-image: url(../img/contents/arrow_04.png);
				background-size: 12px auto;
			}
		}
		.contBox {
			&:first-of-type{
				margin-top: 39px;
			}
			margin-top: 96px;
			p {
				line-height: 40px;
				& + p, & + ol, & + ul { margin-top: 40px; }
			}
			.boxContent {
				padding-top: 28px;
				p { padding-left: 21px; }
				&> ol, &> ul{
					margin-left: 21px;
					& +ul {
						margin-top: 40px;
					}
				}
				ol {
					li {
						ul { margin-top: 10px; }
					}
				}
			}
			.contSubBox01{
				margin-top: 84px;
				.borderTitle{
					margin-bottom: 20px;
				}
			}
		}
		#ContBox01{
			margin-top: 96px;
		}
		#ContainerMenu{
			margin-top: 183px;
			.flexBox{
				.flexItem02{
					background-color: #e1f4ee;
				}
			}
		}
	}
}

/*
目的とポリシー（教育学部）
-------------------------------------*/
/*
目的とポリシー（教育学研究科）
-------------------------------------*/
#PageTowards.pagePurposeU,
#PageTowards.pagePurposeG{
	#Main {
		.pageTitle{
			& + figure{
				margin: 30px auto 0;
				width: calc(100% - 119px);
				max-width: 1000px;
				img{
					width: 100%;
					height: auto;
				}
			}
		}
		.contBox {
			margin-top: 96px;
			.boxContent {
				padding-top: 28px;
				padding-left: 21px;
				p {
					line-height: 40px;
					strong {
						display: block;
						font-weight: bold;
					}
					& + p { margin-top: 40px; }
					& + ol { margin-top: 40px;}
				}
				ul {
					li {
						line-height: 36px;
						&:before { top: 14px; }
					}
				}
				ol {
					li {
						line-height: 36px;
						p { line-height: 36px; }
						& + li { margin-top: 20px; }
					}
					& + p { margin-top: 40px; }
				}
				dl {
					margin: 20px 0 40px;
					dt {
				    width: 100%;
				    background-color: #009b6b;
				    font-weight: bold;
				    color: #fff;
				    letter-spacing: .025em;
				    line-height: 30px;
						padding: 18px 15px 17px;
					}
					dd {
				    width: 100%;
				    padding: 29px 28px 37px;
						border: solid #009b6b;
				    border-width: 0 1px 1px 1px;
					}
				}
			}
		}
		.contSubBox {
			width: calc(100% + 21px);
			margin-left: -21px;
			margin-top: 84px;
			&_content {
				padding-top: 29px;
				padding-left: 22px;
			}
			& + dl { margin-top: 60px !important;}
		}
		.subBox {
			margin-top: 40px;
			&_title { margin-bottom: 15px; }
			&_content { padding-left: 29px; }
		}
		#ContBox01 { margin-top: 39px; }
		#ContBox03 { margin-bottom: 188px; }
		.containerMenu01 .flexBox .flexItem04,
		.containerMenu02 .flexBox .flexItem01 {
			background-color: #e1f4ee;
		}
	}
}

/*
目的とポリシー（教育学部）
-------------------------------------*/
#PageTowards.pagePurposeU{
	#Main{
		#ContainerMenu{
			.flexBox{
				.flexItem03{
					background-color: #e1f4ee;
				}
			}
		}
	}
}

/*
目的とポリシー（教育学研究科）
-------------------------------------*/
#PageTowards.pagePurposeG{
	#Main{
		#ContainerMenu{
			.flexBox{
				.flexItem04{
					background-color: #e1f4ee;
				}
			}
		}
	}
}

/*
オンラインによるICTを用いた学習・研究のために
-------------------------------------*/
#PageTowards.pageRemote {
	#Main {
		.contBox {
			margin-top: 96px;
			.boxContent {
				padding-top: 28px;
				p {
					line-height: 40px;
					&.square {
						position: relative;
				    padding-left: 16px;
				    line-height: 26px;
				    &:before {
				    	content: "";
					    position: absolute;
					    top: 12px;
					    left: 4px;
					    width: 3px;
					    height: 3px;
					    background-color: #000;
				    }
					}
				}
				& > p, & > ul {
					padding-left: 21px;
				}
				& > p.square {
					padding-left: 37px;
					&:before { left: 26px; }
					& + ul {
						padding-left: 56px;
						margin-top: 8px;
					}
				}
			}
			&:first-of-type { margin-top: 39px; }
		}
		.contSubBox {
			margin-top: 60px;
			&_content {
				padding-top: 29px;
				padding-left: 21px;
				.subBox {
					&:first-of-type { margin-top: 0; }
				}
				.square {
					margin-top: 40px;
					&:first-of-type { margin-top: 0; }
					& + ul {
						padding-left: 35px;
						margin-top: 8px;
					}
				}
			}
			&:first-of-type { margin-top: 30px; }
		}
		.subBox {
			margin-top: 40px;
			&_title { margin-bottom: 17px; }
			&_content {
				padding-left: 35px;
			}
		}
		#ContainerMenu {
			margin-top: 183px;
			.flexBox .flexItem06 { background-color: #e1f4ee; }
		}
	}
}

/*
経済的支援を必要とされる学生の方へ
-------------------------------------*/
#PageTowards.pageSupport {
	#Main {
		.contBox {
			margin-top: 96px;
			.boxContent {
				padding-top: 28px;
				padding-left: 21px;
				p {
					line-height: 40px;
					& + p { margin-top: 40px; }
				}
				.red { color: #f00; }
			}
			&:first-of-type { margin-top: 39px; }
		}
		#ContBox01 {
			.boxContent {
				padding-top: 0;
			}
		}
		#ContainerMenu {
			margin-top: 183px;
			.flexBox .flexItem07 { background-color: #e1f4ee; }
		}
	}
}

/*
教育学部サテライト利用の手引
-------------------------------------*/
#PageTowards.pageGuidance {
	#Main {
		.contBox {
			margin-top: 96px;
			.boxContent {
				padding-top: 28px;
				padding-left: 21px;
				p {
					line-height: 40px;
					& + p { margin-top: 40px; }
					& + ul { margin-top: 30px; }
					&.remark {
						line-height: 26px;
				    padding-left: 22px;
				    margin-top: 10px;
				    position: relative;
				    &:before {
		    	    content: "※";
					    position: absolute;
					    top: 0;
					    left: 0;
				    }
					}
				}
				table {
					margin: 30px 0;
				}
			}
			&:first-of-type { margin-top: 39px; }
		}
		#ContainerMenu {
			margin-top: 183px;
			.flexBox .flexItem09 { background-color: #e1f4ee; }
		}
	}
}

/*
ハラスメント相談窓口等について
-------------------------------------*/
#PageTowards.pageConsultation {
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		.contBox {
			margin-top: 96px;
			.boxContent {
				padding-top: 28px;
                padding-left: 21px;
                p{
                    line-height: 40px;
                    margin-bottom: 15px;
                }
                a {
                    &[target="_blank"] {
                        padding-right: 25px;
                        background-image: url(../img/contents/link_03.png);
                        background-repeat: no-repeat;
                        background-position: right 5px center;
                        background-size: 11px auto;
                    }
                }
			}
		}
		#ContBox01 {
			margin-top: 39px;
		}
        #ContainerMenu{
            margin-top: 100px;
        }
	}
}

/*
NEWS
-------------------------------------*/
#PageNews {
	#MainImg {
		#MainImgInner {
			//figure { background-image: url(../img/contents/news/mainimg.jpg); }
		}
	}
	#Main {
		padding-top: 109px;
		padding-bottom: 202px;
		.catSearch {
			&_title {
				background-color: #009b6b;
				font-size: 20px;
				font-weight: bold;
				color: #fff;
				letter-spacing: 0.025em;
				line-height: 30px;
				padding: 19px 33px 20px;
				&.click {
					background-image: url(../img/contents/open.png);
					background-repeat: no-repeat;
					background-position: right 20px center;
					background-size: 36px auto;
					&.active { background-image: url(../img/contents/close.png); }
				}
			}
			&_content {
				padding: 30px 30px 20px;
				border: solid #009b6b;
				border-width: 0 1px 1px 1px;
				ul {
					display: flex;
					flex-wrap: wrap;
					li {
						font-size: 16px;
						font-weight: bold;
						color: #009b6b;
						letter-spacing: 0.05em;
						line-height: 30px;
						padding-left: 0;
						margin-right: 10px;
						margin-bottom: 10px;
						position: relative;
						a {
							display: block;
							width: 100%;
							height: 100%;
							background-color: #eff8f5;
							text-decoration: none;
							border: 1px solid #eff8f5;
							padding: 4px 12px 4px 16px;
							&:hover {
								background-color: #fff;
								border-color: #009b6b;
								cursor: pointer;
							}
							&.currect {
								color: #fff;
								background-color: #343434;
								border-color: #343434
							}
						}
						& + li { margin-top: 0; }
						&:before { display: none; }
					}
				}
				button {
					display: block;
					width: 147px;
					height: 50px;
					background: url(../img/contents/arrow_02.png) no-repeat right 26px center;
					background-size: 8px auto;
					font-size: 16px;
					font-weight: bold;
					color: #009b6b;
					letter-spacing: 0.05em;
					line-height: 48px;
					text-align: left;
					border: 1px solid #e9e9e9;
					border-radius: 25px;
					padding-left: 32px;
					margin: 16px auto 10px;
					&:hover {
						color: #fff;
						background-color: #009b6b;
						background-image: url(../img/contents/arrow_01.png);
						border-color: #009b6b;
					}
				}
			}
		}
		.catSearch02 { margin-top: 71px; }
		.contBox {
			.boxHead { margin: 49px 0 48px; }
		}
		.pageNation {
			margin-top: 98px;
			display: flex;
			background-color: #8ec21f;
			li {
				height: 66px;
				padding-left: 0;
				span, p, a {
					display: block;
					width: 100%;
					height: 100%;
					font-family: 'EB Garamond', serif;
					font-size: 18px;
					font-weight: 400;
					color: #fff;
					letter-spacing: 0.1em;
					line-height: 66px;
					text-align: center;
					text-decoration: none;
					border-left: 1px solid #4f9404;
					&.current { color: rgba(255,255,255,0.5); }
				}
				a:hover { background-color: #97cc26; }
				&:not(.text) { width: 66px; }
				&.text {
					flex-grow: 1;
					p {
						padding-left: 26px;
						padding-right: 26px;
						text-align: left;
						border-left-width: 0;
					}
				}
				&.first, &.prev, &.next, &.last {
					a {
						text-indent: -9999px;
						background-repeat: no-repeat;
						background-position: center center;
						border-left-width: 0;
					}
				}
				&.first a {
					background-color: #009a6a;
					background-image: url(../img/contents/arrow_07.png);
					background-size: 19px auto;
					&:hover { background-color: #05a674; }
				}
				&.prev {
					a {
						background-color: #22ab38;
						background-image: url(../img/contents/arrow_05.png);
						background-size: 10px auto;
						&:hover { background-color: #28b63f; }
					}
					& + li a { border-left-width: 0; }
				}
				&.next a {
					background-color: #22ab38;
					background-image: url(../img/contents/arrow_01.png);
					background-size: 10px auto;
					&:hover { background-color: #28b63f; }
				}
				&.last a {
					background-color: #009a6a;
					background-image: url(../img/contents/arrow_06.png);
					background-size: 19px auto;
					&:hover { background-color: #05a674; }
				}
				& + li { margin-top: 0; }
				&:before { display: none; }
			}
		}
	}
}
#PageNews.pageIndex {
	#Main {
		.catSearch01 { margin-bottom: 70px; }
	}
}

/*
NEWS 記事詳細
-------------------------------------*/
#PageNews.pageEntry {
	#Main {
		#ContBox {
			.boxHead {
				margin-top: 38px;
				.boxTitle {
					line-height: 46px;
					&:before { top: 13px; }
				}
				.otherInfo {
					padding-left: 56px;
					margin-top: 33px;
					.date {
						font-size: 16px;
				    font-weight: 400;
				    color: #8c8c8c;
				    letter-spacing: 0.05em;
				    margin-right: 29px;
					}
					.cat {
						display: flex;
				    background: url(../img/contents/cat_02.png) no-repeat left bottom;
				    background-size: 14px auto;
				    padding-left: 20px;
						li {
							position: relative;
					    font-size: 14px;
					    font-weight: bold;
					    color: #009b6b;
					    letter-spacing: .05em;
					    line-height: 16px;
					    padding-left: 0;
					    & + li {
					    	margin-top: 0;
								&:before {
									content: ",";
							    position: relative;
							    top: auto;
							    display: inline-block;
							    width: auto;
							    height: auto;
							    background-color: transparent;
							    margin-right: 4px;
							    margin-left: 1px;
							    border-radius: 0;
								}
					    }
					    &:first-of-type:before { display: none; }
						}
					}
				}
			}
			.boxContent {
				.entryBox {
					p { line-height: 40px; }
				}
			}
			.entryPageNav {
				display: flex;
				flex-wrap: wrap;
				margin-top: 203px;
				li {
					width: 17.60416666666667%;
					padding-left: 0;
					&:before { display: none; }
					& + li { margin-top: 0; }
					a {
						display: block;
						width: 100%;
						height: 100%;
						background-color: #22ab38;
						color: #fff;
						letter-spacing: 0.1em;
						line-height: 24px;
						text-decoration: none;
						text-align: center;
						padding: 21px 0;
						span { display: inline-block; }
						&:hover { background-color: #28b63f; }
					}
				}
				.top {
					width: 64.79166666666667%;
					a {
						background-color: #8ec21f;
						font-weight: bold;
						span {
							background: url(../img/contents/icon_14.png) no-repeat left top 2px;
							background-size: 26px auto;
							padding-left: 39px;
						}
						&:hover { background-color: #97cc26; }
					}
				}
				.prev {
					a span {
						background: url(../img/contents/arrow_05.png) no-repeat left center;
						background-size: 10px auto;
						padding-left: 19px;
					}
				}
				.next {
					a span {
						background: url(../img/contents/arrow_01.png) no-repeat right center;
						background-size: 10px auto;
						padding-right: 22px;
					}
				}
			}
		}
	}
}

/*
検索ページ
-------------------------------------*/
#PageSearch.pageIndex {
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		.borderTitle {
			margin-bottom: 39px;
			& > p { line-height: 40px; }
		}
		.pageNation {
			margin-top: 98px;
			display: flex;
			background-color: #8ec21f;
			li {
				height: 66px;
				padding-left: 0;
				span, p, a {
					display: block;
					width: 100%;
					height: 100%;
					font-family: 'EB Garamond', serif;
					font-size: 18px;
					font-weight: 400;
					color: #fff;
					letter-spacing: 0.1em;
					line-height: 66px;
					text-align: center;
					text-decoration: none;
					border-left: 1px solid #4f9404;
					&.current { color: rgba(255,255,255,0.5); }
				}
				a:hover { background-color: #97cc26; }
				&:not(.text) { width: 66px; }
				&.text {
					flex-grow: 1;
					p {
						padding-left: 26px;
						padding-right: 26px;
						text-align: left;
						border-left-width: 0;
					}
				}
				&.first, &.prev, &.next, &.last {
					a {
						text-indent: -9999px;
						background-repeat: no-repeat;
						background-position: center center;
						border-left-width: 0;
					}
				}
				&.first a {
					background-color: #009a6a;
					background-image: url(../img/contents/arrow_07.png);
					background-size: 19px auto;
					&:hover { background-color: #05a674; }
				}
				&.prev {
					a {
						background-color: #22ab38;
						background-image: url(../img/contents/arrow_05.png);
						background-size: 10px auto;
						&:hover { background-color: #28b63f; }
					}
					& + li a { border-left-width: 0; }
				}
				&.next a {
					background-color: #22ab38;
					background-image: url(../img/contents/arrow_01.png);
					background-size: 10px auto;
					&:hover { background-color: #28b63f; }
				}
				&.last a {
					background-color: #009a6a;
					background-image: url(../img/contents/arrow_06.png);
					background-size: 19px auto;
					&:hover { background-color: #05a674; }
				}
				& + li { margin-top: 0; }
				&:before { display: none; }
			}
		}
	}
}

/*
プライバシーポリシー
-------------------------------------*/
#PagePrivacy.pageIndex {
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		#ContBox01 {
			margin-top: 48px;
			p {
				line-height: 40px;
				&.right { text-align: right; }
				& + p { margin-top: 40px; }
			}
			.contSubBox {
				&_content {
					padding-top: 29px;
					padding-left: 22px;
				}
				& + .contSubBox { margin-top: 84px; }
			}
			.subBox {
				&_title { margin-bottom: 15px; }
				&_content {
					padding-left: 29px;
				}
				& + .subBox { margin-top: 40px; }
			}
		}
	}
}

/*
教職員の方へ
-------------------------------------*/
#PageTeachers.pageIndex {
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		.contBox {
			margin-top: 96px;
			.boxContent {
				padding-top: 28px;
                padding-left: 21px;
                a {
                    &[target="_blank"] {
                        padding-right: 25px;
                        background-image: url(../img/contents/link_03.png);
                        background-repeat: no-repeat;
                        background-position: right 5px center;
                        background-size: 11px auto;
                    }
                }
			}
		}
		#ContBox01 {
			margin-top: 39px;
		}
	}
}

/*
404
-------------------------------------*/
#Page404.pageIndex {
	#Main {
		padding-top: 109px;
		padding-bottom: 200px;
		.contBox {
			margin-top: 48px;
			.linkBtn {
				text-align: center;
				margin-top: 30px;
				.button {
			    display: inline-block;
			    border: 1px solid #e9e9e9;
			    border-radius: 40px;
			    padding: 0 31px 0 34px;
			    height: 46px;
			    background-color: #fff;
			    line-height: 44px;
			    letter-spacing: .05em;
			    font-size: 14px;
			    font-weight: bold;
			    color: #009b6b;
			    &:hover {
			    	border-color: #009b6b;
				    background-color: #009b6b;
				    opacity: 1;
				    color: #fff;
			    }
				}
			}
		}
	}
}


@media print,
screen and (max-width: 767px) {
	/*
	トップページ
	 -------------------------------------*/
	#Page.pageIndex {
		#MainImg {
			#MainImgInner {
				.slideImg {
					width: 100%;
					min-width: auto;
					height: 598px;
					padding-top: 0;
					li {
						background-position: center center;
						background-size: 657px auto;
						&:nth-of-type(1) {
							background-image: url(../img/contents/top/mainimgSp_01.jpg);
						}
						&:nth-of-type(2) {
							background-image: url(../img/contents/top/mainimgSp_02.jpg);
						}
						&:nth-of-type(3) {
							background-image: url(../img/contents/top/mainimgSp_03.jpg);
						}
					}
				}
				.button {
					width: 197px;
					height: 40px;
					background-position: right 15px center;
					background-size: 10px auto;
					font-size: 13px;
					letter-spacing: 0.035em;
					line-height: 40px;
					padding-left: 16px;
					border-radius: 25px;
					right: 17px;
					bottom: 78px;
				}
			}
		}
		#Main {
			#ContBox01 {
				margin-bottom: 108px;
				.innerBasic { padding: 0; }
				.flexBox {
					.flexItem {
						width: 50%;
						height: 202px;
						margin-right: 1px;
						&:nth-of-type(even) {
							width: calc(50% - 1px);
							margin-right: 0;
						}
						a {
							background-position: right 15px bottom 34px;
						}
						figure {
							width: calc(100% - 40px);
							height: 115px;
						}
						p {
							position: relative;
							left: auto;
							bottom: auto;
							display: flex;
							align-items: center;
							height: 40px;
							font-size: 15px;
							letter-spacing: 0.025em;
							line-height: 20px;
							padding: 0 50px 0 13px;
							margin-top: 27px;
						}
					}
				}
			}
			#ImportPost {
				margin-bottom: 71px;
				.innerBasic { padding: 0 17px; }
			}
			#ContBox02 {
				margin-top: 108px;
				.innerBasic {
					padding: 0 17px;
				}
			}
			#ContBox03 {
				.boxHead {
					width: 100%;
					padding: 34px 0 54px;
					margin-left: 0;
					h2 {
						font-size: 26px;
						letter-spacing: 0.025em;
						line-height: 35px;
						small {
							font-size: 21px;
							margin-bottom: -4px;
						}
						&:after {
							width: 52px;
							height: 5px;
							bottom: -17px;
						}
					}
				}
				.innerBasic { padding: 0; }
				.boxContent {
					padding: 34px 0 125px;
					.flexBox {
						flex-direction: column;
						.flexItem01 {
							width: 100%;
						}
						.flexItem03 {
							width: 100%;
							margin-top: 30px;
							.clickBox {
								& + .clickBox { margin-top: 1px; }
								.click {
									font-size: 16px;
									font-weight: bold;
									color: #fff;
									letter-spacing: 0;
									background: url(../img/contents/icon_01.png) no-repeat right 25px center;
									background-size: 10px auto;
									padding: 22px 50px 21px 19px;
									&.active {
										background-image: url(../img/contents/icon_02.png);
									}
								}
								.showBox {
									padding: 30px 17px 45px;
									h4 {
										font-size: 19px;
										font-weight: bold;
										text-align: center;
										margin-bottom: 10px;
									}
									p {
										font-size: 15px;
										letter-spacing: 0;
										line-height: 27px;
									}
									figure {
										margin-top: 19px;
										img { width: 100%; }
									}
									.button {
										display: block;
										width: 141px;
										height: 45px;
										background: #000 url(../img/contents/arrow_01.png) no-repeat right 31px center;
										background-size: 8px auto;
										font-size: 15px;
										font-weight: bold;
										color: #fff;
										letter-spacing: 0.05em;
										line-height: 45px;
										border-radius: 40px;
										padding-left: 35px;
										margin: 25px auto 0;
									}
								}
							}
							.clickBox01 .click { background-color: #712512; }
							.clickBox02 .click { background-color: #a4381a; }
							.clickBox03 .click { background-color: #e0684e; }
							.clickBox04 .click { background-color: #f099a8; }
							.clickBox05,.clickBox06 {
								.showBox {
									.button {
										margin-top: 12px;
										& + h4 { margin-top: 50px; }
									}
								}
							}
							.clickBox05 .click { background-color: #e95f7b; }
							.clickBox06 .click { background-color: #ba2b76; }
							.clickBox07 .click { background-color: #25437d; }
							.clickBox08 .click { background-color: #65437c; }
							.clickBox09 .click { background-color: #49873c; }
							.clickBox10 .click { background-color: #3a682f; }
						}
					}
				}
				.popOpenWrapper, .popOpenInner, .popOpen {
					display: none !important;
					pointer-events: none !important;
				}
			}
		}
	}

	/*
	教育学研究科・教育学部について
	 -------------------------------------*/
	#PageOverview, #PageFacility,,#PagePublications {
		#MainImg {
			#MainImgInner {
				figure { background-image: url(../img/contents/overview/mainimg.jpg); }
			}
		}
		#Main {
			padding-top: 70px;
			padding-bottom: 90px;
		}
		&:not(.pageIndex) {
			#Main {
				.boxContent {
					p {
						font-size: 15px;
 						letter-spacing: 0.025em;
 						line-height: 30px;
 						& + p { margin-top: 30px; }
					}
				}
			}
		}
	}
	#PageOverview.pageIndex {
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			#ContBox02 {
				.boxContent {
					padding-bottom: 105px;
					.button { display: none; }
				}
			}
		}
	}

	/*
	研究科長あいさつ
	 -------------------------------------*/
	#PageOverview.pageDean {
	 	#Main {
	 		padding-top: 72px;
	 		padding-bottom: 125px;
	 		#ContBox01 {
	 			margin-bottom: 87px;
	 			.boxHead {
	 				h3 { margin: 34px 0 62px; }
	 			}
	 			.boxContent {
	 				figure {
	 					padding-bottom: 106px;
	 					margin-bottom: 54px;
	 					figcaption {
	 						right: 0;
	 						p {
	 							font-size: 21px;
	 							letter-spacing: 0.025em;
	 							margin-bottom: 10px;
	 						}
	 						span {
	 							font-size: 12px;
	 							letter-spacing: 0.025em;
	 							line-height: 19px;
	 						}
	 						&:before {
	 							height: 77px;
	 							top: -7px;
	 							left: -33px;
	 						}
	 						&:after {
	 							width: 112px;
	 							top: 31px;
	 							left: -135px;
	 						}
	 					}
	 				}
	 				.text { padding: 0; }
	 			}
	 		}
	 	}
	}

	/*
	歩み
	 -------------------------------------*/
	#PageOverview.pageHistory {
	 	#Main {
	 		padding-top: 75px;
	 		#ContBox01 {
	 			margin-top: 33px;
	 			margin-bottom: 97px;
	 			.boxContent {
	 				& > p {
	 					padding-left: 0;
	 					padding-right: 0;
	 				}
	 			}
	 		}
	 	}
	}

	/*
	管理運営組織
	-------------------------------------*/
	#PageOverview.pageMoorg {
		#Main {
			padding-top: 81px;
			#ContBox01 {
				margin-top: 33px;
				margin-bottom: 	101px;
				.boxContent {
					& > p {
	 					padding-left: 0;
	 					padding-right: 0;
	 				}
					figure { margin-top: 52px; }
				}
			}

		}
	}

	/*
	研究教育組織
	-------------------------------------*/
	#PageOverview.pageReorg {
		#Main {
			padding-top: 81px;
			#ContBox01 {
				margin-top: 33px;
				margin-bottom: 	96px;
				.boxContent {
					& > p { text-indent: 0; }
					figure {
						margin-top: 51px;
						margin-bottom: 69px;
						.tabBig { margin-top: 8px; }
					}
					.subBox {
						& + .subBox { margin-top: 47px; }
					}
				}
			}
		}
	}

	/*
	附属施設等
	-------------------------------------*/
	#PageFacility.pageIndex {
		#Main {
			padding-top: 81px;
			#ContBox01 {
				margin-top: 35px;
				margin-bottom: 105px;
				.flexBox {
					flex-wrap: wrap;
					margin-bottom: -40px;
					.flexItem {
						width: calc( (100% - 15px) / 2 );
						margin-right: 15px;
						margin-bottom: 40px;
						a {
							display:flex;
							flex-direction:column;
							&:hover {
								color: #000;
								dt img { opacity: 1; }
							}
						}
						dd {
							flex-grow: 1;
							font-size: 15px;
							letter-spacing: 0.025em;
							line-height: 20px;
							background-position: right center;
							padding: 20px 15px 20px 0;
							display: flex;
							align-items: center;
						}
						&:nth-of-type(3n) { margin-right: 15px; }
						&:nth-of-type(2n) { margin-right: 0; }
					}
				}
			}
		}
	}

	/*
	附属施設等 教育学部図書室
	-------------------------------------*/
	#PageFacility.pageLibrary {
		#Main {
			& > figure {
				width: calc(100% - 34px);
				margin-top: 35px;
				margin-bottom: 55px;
			}
			#ContBox02 {
				margin-top: 50px;
				margin-bottom: 105px;
				.boxContent {
					padding-top: 35px;
					& > p {
						line-height: 23px;
						padding-left: 16px;
						margin-bottom: 35px;
					}
					.pdfLink {
						a {
							padding: 12px 17px 11px;
							background-position: right 15px center;
							background-size: 8px auto;
							span {
								background-position: right center;
								background-size: 15px auto;
								padding-right: 28px;
							}
						}
					}
					.subBox {
						&_title {
							font-size: 18px;
							margin-bottom: 6px;
							&:before { top: 8px; }
						}
						&_content {
							padding-left: 27px;
							h5 {
								font-size: 16px;
								margin-bottom: 2px;
							}
							p {
								letter-spacing: 0.05em;
								strong { font-size: 15px; }
								& + ul { margin-top: 9px; }
							}
							.dotList {
								padding-left: 7px;
								li {
									letter-spacing: 0.05em;
									span { margin-top: 0; }
								}
								& + h5 { margin-top: 3px; }
							}
						}
						& + .subBox { margin-top: 46px; }
					}
					.subBox01 {
						.subBox_content {
							ul {
								li span { margin-top: 0; }
							}
							p {
								line-height: 30px;
								padding-left: 22px;
								margin-top: 19px;
							}
						}
					}
					.subBox04 {
						.subBox_content {
							padding-left: 0;
							table {
								th.bg-green {
									&:nth-of-type(1) { width: 32%; }
									&:nth-of-type(2) { width: 32%; }
									&:nth-of-type(3) { width: 18%; }
									&:nth-of-type(4) { width: 18%; }
								}
								td {
									small { font-size: 10px; }
								}
							}
							.remark {
								font-size: 12px;
								line-height: 21px;
							}
						}
					}
					.subBox09 {
						.subBox_content {
							ul {
								li {
									small {
										font-size: 12px;
										letter-spacing: 0;
										line-height: 21px;
										margin: -3px 0 11px;
									}
									p { line-height: 28px; }
								}
							}
						}
					}
					.subBox10, .subBox11 {
						.subBox_content {
							margin-top: -2px;
							p { line-height: 30px; }
						}
					}
					.subBox12 {
						.subBox_content {
							padding-left: 0;
							p { padding-left: 27px; }
						}
					}
				}
			}
		}
	}

	/*
	附属施設等 グローバル教育展開オフィス
	-------------------------------------*/
	#PageFacility.pageGlobal {
		#Main {
			.lowerPageTitle { margin-top: 76px; }
			.contBox {
				margin-top: 64px;
				.boxHead {
					.title {
						font-size: 20px;
						letter-spacing: 0;
					}
				}
				.boxContent {
					.subBox {
						margin-top: 35px;
						&_title {
							font-size: 18px;
							line-height: 24px;
							text-align: justify;
							&:before { top: 5px; }
						}
						&_content {
							padding-left: 27px;
							p { padding-left: 0; }
						}
					}
				}
			}
			#ContBox01 {
				margin-top: 35px;
				.boxContent {
					.text {
						padding-left: 0;
						img {
							float: none;
							width: 100%;
							margin: 0 0 31px 0;
						}
					}
					figure { margin-top: 51px; }
				}
			}
			#ContBox04 {
				margin-bottom: 100px;
				.boxContent {
					p.red {
						letter-spacing: 0.05em;
						line-height: 24px;
						padding-left: 27px;
						margin-top: 16px;
					}
				}
			}
		}
	}

	/*
	附属施設等 心理教育相談室
	-------------------------------------*/
	#PageFacility.pageRoom {
		#Main {
			.contBox {
				margin-top: 55px;
				p { padding-left: 0; }
			}
			#ContBox01 {
				margin-top: 35px;
				.boxContent {
					.text {
						img {
							float: none;
							width: 100%;
							margin: 0 0 31px 0;
						}
					}
					figure { margin-top: 62px; }
				}
			}
			#ContBox02 {
				margin-top: 75px;
				.boxContent {
					.subBox {
						padding: 35px 30px 32px;
						margin-top: 40px;
						h4 {
							font-size: 18px;
							line-height: 24px;
						}
						ul {
							padding-left: 4px;
							margin-top: 17px;
							li {
								line-height: 30px;
								padding-left: 13px;
								&:before { top:  14px; }
							}
						}
					}
				}
			}
			#ContBox04 {
				.boxContent {
					h4 {
						font-size: 16px;
						text-align: justify;
						padding-left: 0;
						margin-bottom: 2px;
					}
				}
			}
			#ContBox05 {
				margin-bottom: 105px;
				.boxContent {
					.map {
						flex-wrap: wrap;
						margin-top: 32px;
						.mapItem {
							width: 100%;
							a {
								width: 138px;
								height: 39px;
								font-size: 12px;
								line-height: 37px;
								padding-left: 32px;
								background-position: right 30px center;
								background-size: 15px auto;
								margin-top: 25px;
							}
							& + .mapItem { margin-top: 40px; }
						}
					}
				}
			}
		}
	}

	/*
	附属施設等 こころの支援室
	-------------------------------------*/
	#PageFacility.pageSupport {
		#Main {
			.lowerPageTitle { margin-top: 76px; }
			#ContBox01 {
				margin-top: 22px;
				margin-bottom: 94px;
				.boxContent {
					padding-left: 0;
					img {
						float: none;
						width: 100%;
						margin: 0 0 31px 0;
					}
					ol {
						margin-bottom: 12px;
						& > li {
							font-size: 16px;
							font-weight: bold;
							line-height: 32px;
						}
					}
					ul {
						margin: 14px 0;
						padding-left: 18px;
					}
					& > p {
						font-size: 16px;
						letter-spacing: 0.05em;
						line-height: 32px;
					}
				}
			}
		}
	}

	/*
	附属施設等 こころの支援室
	-------------------------------------*/
	#PageFacility.pageResearch {
		#Main {
			.lowerPageTitle { margin-top: 76px; }
			#ContBox01 {
				margin-top: 35px;
				.boxContent {
					padding-left: 0;
					img {
						float: none;
						width: 100%;
						margin: 0 0 31px 0;
					}
				}
			}
			#ContBox02 {
				margin-top: 90px;
				margin-bottom: 70px;
				.boxContent {
					padding-top: 40px;
					figure {
						&:last-of-type { margin-top: 29px; }
					}
				}
			}
		}
	}

	/*
	刊行物・資料
	-------------------------------------*/
	#PagePublications {
		#Main {
			padding-top: 75px;
			.pageTitle {
				.button {
					height: 36px;
					font-size: 12px;
					line-height: 36px;
					background-position: right 12px center;
					background-size: 6px auto;
					padding: 0 26px 0 17px;
					border-radius: 36px;
					top: 22px;
					right: 15px;
				}
			}
		}
	}
	#PagePublications.pageIndex {
		#Main {
			.lowerLink {
				&_item a {
					background-position: right center;
					background-size: 12px auto;
				}
			}
			.contBox {
				margin-top: 73px;
				.boxHead {
					margin-bottom: 40px;
					.button {
						position: relative;
						top: auto;
						right: auto;
						display: inline-block;
						height: 36px;
						font-size: 12px;
						letter-spacing: 0.025em;
						line-height: 34px;
						margin-top: 25px;
						span {
							background-size: 6px auto;
							padding-right: 15px;
						}
					}
				}
				.boxContent {
					.postList {
						li {
							a {
								padding: 20px 36px 20px 16px;
								line-height: 23px;
							}
						}
					}
				}
			}
			#ContBox01 {
				margin-top: 76px;
			}
			#ContBox05 {
				.boxContent {
					.post {
						padding-left: 0;
						p { line-height: 30px; }
					}
				}
			}
			#ContBox06 {
				margin-bottom: 102px;
			}
		}
	}

	/*
	刊行物・資料 カテゴリ
	-------------------------------------*/
	#PagePublications.pageCategory {
		#Main {
			#ContBox01 {
				margin-top: 76px;
				margin-bottom: 102px;
				.boxContent {
					padding-top: 40px;
					.postList {
						li a { padding-left: 16px; }
					}
				}
			}
		}
	}

	/*
	刊行物・資料 記事詳細
	-------------------------------------*/
	#PagePublications.pageEntry {
		#Main {
			#ContBox01 {
				margin-top: 76px;
				margin-bottom: 102px;
				.boxContent {
					padding-top: 38px;
					.entryHead {
						p {
							font-size: 20px;
	            padding-bottom: 8px;
						}
					}
					.entryBox {
						padding-left: 0;
					}
				}
			}
		}
	}

	/*
	広報用ロゴマークについて
	-------------------------------------*/
	#PageOverview.pageEduc {
		#Main {
			#ContBox01 {
				margin-top: 55px;
				margin-bottom: 90px;
			}
		}
	}

	/*
	お問い合わせ先
	-------------------------------------*/
	#PageOverview.pageContact {
		#Main {
			.contBox {
				margin-top: 60px;
				.boxContent {
					margin-top: 35px;
					padding-left: 0;
					p {
						line-height: 30px;
						margin-bottom: 26px;
					}
					table + p { margin-top: 33px; }
				}
			}
			#ContBox01 {
				margin-top: 55px;
			}
			#ContBox05 { margin-bottom: 90px; }
		}
	}

	/*
	アクセス
	-------------------------------------*/
	#PageOverview.pageAccess {
		#Main {
			.contBox {
				.boxHead {
					.boxTitle {
						text-align: justify;
					}
				}
			}
			#ContBox01 {
				margin-top: 55px;
				.boxContent {
					padding-top: 35px;
				}
			}
			#ContBox02 {
				margin-top: 60px;
				.boxContent {
					padding-top: 43px;
					figure {
						img { width: 100%; }
					}
				}
			}
			#ContBox03 {
				margin-top: 60px;
				margin-bottom: 60px;
				.boxContent {
					padding-top: 22px;
					& > p { padding-left: 0; }
					.contSubBox {
						margin-top: 25px;
					}
					.subBox {
						margin-top: 46px;
						&_title {
							text-align: justify;
							margin-bottom: 30px;
						}
						&_content {
							padding-left: 0;
							ol {
								font-size: 15px;
								& + p { margin-top: 20px; }
							}
							li { font-size: 15px; }
							p {
								& + ol { margin-top: 15px; }
							}
						}
					}
				}
			}
		}
	}

	/*
	教育学研究科・教育学部について
	-------------------------------------*/
	#PageAdmissions {
		#Main {
			padding-top: 76px;
			padding-bottom: 90px;
		}
		&:not(.pageIndex) {
			#Main {
				.boxContent {
					p {
						font-size: 15px;
 						letter-spacing: 0.025em;
 						line-height: 30px;
 						& + p { margin-top: 30px; }
					}
				}
			}
		}
		.containerMenu {
			.flexBox {
				.flexItem07, .flexItem08 {
					background-position: right 9px center;
			    background-size: 16px auto;
				}
			}
		}
	}
	#PageAdmissions.pageIndex {
		#Main {
			padding-top: 71px;
			padding-bottom: 90px;
			#ContBox02 {
				.boxContent {
					padding-bottom: 105px;
				}
			}
		}
	}

	/*
	学部受験生の方へ
	-------------------------------------*/
	#PageAdmissions.pageInfo {
		#Main {
			padding-top: 77px;
			.contBox {
				margin-top: 40px;
				.boxTitle {
					span { font-size: 18px; }
				}
				.boxContent {
					.subBox {
						margin-top: 50px;
						&_title {
							font-size: 18px;
							line-height: 24px;
							text-align: justify;
							margin-bottom: 10px;
							&:before { top: 6px; }
							& + .subBox_content { padding-left: 30px; }
						}
						&_content {
							padding-left: 0;
						}
					}
					h5 {
						&.square {
							font-size: 16px;
							&:before { top: 12px; }
							& + p:not(.square) { margin-top: 1px; }
						}
					}
					p {
						&.remark {
							font-size: 13px;
							line-height: 24px;
							padding-left: 15px;
							margin-left: 0;
							& + .remark { margin-top: 13px; }
						}
						& + h5.square { margin-top: 6px; }
					}
					ol {
						li {
							font-size: 15px;
							line-height: 30px;
						}
					}
					table { margin: 22px 0 24px; }
					.square {
						& + ul { padding-left: 18px; }
						&:before { top: 13px; }
					}
					.pdfLink {
						flex-wrap: wrap;
						background-position: right 15px center;
						padding: 18px 16px;
						span {
							background-size: 15px auto;
							padding-right: 27px;
						}
						small {
							margin-top: 2px;
							margin-left: 0;
						}
					}
				}
			}
			#ContBox01 {
				margin-top: 76px;
				.boxContent { padding-top: 34px; }
			}
			#ContBox03 {
				.boxContent {
					& > p:not(.remark) { padding-left: 0; }
					& > ul {
						padding-left: 18px;
						margin-top: 19px;
					}
					dl {
						margin-top: 44px;
						dt {
							font-size: 16px;
							letter-spacing: 0.05em;
							line-height: 24px;
							text-align: justify;
							padding: 22px 15px 20px;
						}
						dd {
							padding: 21px 15px 22px;
							ul {
								margin-top: 10px;
								padding-left: 5px;
							}
						}
					}
					.subBox {
						&_content {
							table {
								width: calc(100% + 15px);
								margin-left: -15px;
							}
						}
					}
					.subBox01 {
						p { margin-bottom: 5px; }
					}
					.subBox02 {
						p {
							line-height: 30px;
							& + p { margin-top: 6px; }
						}
					}
					.subBox03 {
						table { margin-top: 28px; }
					}
					.subBox04 {
						.subBox_content {
							padding-left: 15px;
							table {
								width: 100%;
								margin-left: 0;
							}
							ul {
								padding-left: 28px;
								margin-bottom: 25px;
							}
							p.square {
								line-height: 30px;
								margin-top: 15px;
							}
						}
					}
					.subBox05 {
						p { margin-bottom: 15px; }
						ul {
							li + li { margin-top: 0; }
						}
					}
				}
			}
			#ContBox04 {
				margin-bottom: 105px;
				.boxContent {
					.square { margin-top: 20px; }
					ul { padding-left: 28px; }
				}
			}
		}
	}

	/*
	教育学部のポリシー
	-------------------------------------*/
	#PageAdmissions.pagePurpose {
		#Main {
			.contBox {
				margin-top: 76px;
				.boxHead { margin-bottom: 30px; }
				.boxContent {
					padding-left: 0;
					ul, ol {
						margin-top: 30px;
						li {
							line-height: 30px;
							& + li { margin-top: 10px; }
						}
					}
					ul li {
						&:before { top: 11px; }
					}
				}
			}
			#ContBox03 { margin-bottom: 105px; }
		}
	}

	/*
	教育内容
	-------------------------------------*/
	#PageAdmissions.pageEducation {
		#Main {
			.contBox {
				margin-top: 60px;
				.boxContent {
					padding-top: 28px;
					& > p { padding-left: 0; }
					.subBox {
						&_content {
							padding-top: 38px;
							.flexBox {
								flex-wrap: wrap;
								background-color: #eff8f5;
								padding: 17px 22px 18px;
								border-radius: 10px;
								p {
									font-size: 16px;
									letter-spacing: 0.025em;
									line-height: 32px;
									position: relative;
									& + p {
										margin-left: 28px;
										&:before {
											left: -22px
										}
									}
								}
							}
							p + p { margin-top: 0; }
						}
					}
					.subBox01 { margin-top: 42px;}
					.subBox02 { margin-top: 25px; }
				}
			}
			#ContBox01 { margin-top: 76px; }
			#ContBox03 { margin-bottom: 105px; }
		}
	}

	/*
	取得できる資格
	-------------------------------------*/
	#PageAdmissions.pageObtain {
		#Main {
			#ContBox01 {
				margin-bottom: 105px;
				.boxContent {
					.subBox {
						margin-top: 43px;
						&_content {
							padding-top: 14px;
							p { padding-left: 0; }
							img {
								width: 100%;
								float: none;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}

	/*
	卒業後の進路
	-------------------------------------*/
	#PageAdmissions.pageAfter {
		#Main {
			#ContBox01 {
				margin-top: 39px;
				margin-bottom: 105px;
				.boxContent {
					.subBox01 {
						img {
							float: none;
							display: block;
							margin: 0 auto 30px;
						}
						p { padding-left: 0; }
					}
					.subBox02 {
				    padding: 35px 30px 32px;
						margin-top: 40px;
				    h4 {
					    font-size: 18px;
				    	line-height: 24px;
				    	margin-bottom: 17px;
				    }
				    span {
				    	font-size: 13px;
				    	line-height: 26px;
				    	padding-left: 15px;
				    	margin-top: 26px;
				    }
					}
				}
			}
		}
	}

	/*
	大学院受験生・研究者の方へ
	-------------------------------------*/
	#PageGraduate, #PageResearch, #PageEducation, #PageStaffList {
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			.containerMenu {
				.boxHead {
					margin-bottom: 35px;
					h3 {
						font-size: 29px;
						line-height: 35px;
						&:before {
							width: 40px;
							height: 6px;
							top: 5px;
							right: -54px;
						}
					}
				}
			}
			.containerMenu01 {
				.flexBox {
					.flexItem06 {
						background-position: right 9px center;
				    background-size: 16px auto;
					}
				}
			}
			.containerMenu02 {
				margin-top: 67px;
				.flexBox {
					.flexItem03 {
						background-position: right 9px center;
				    background-size: 16px auto;
					}
				}
			}
		}
		&:not(.pageIndex) #Main .boxContent p {
		    line-height: 30px;
		    letter-spacing: .025em;
		    font-size: 15px;
		}
	}

	/*
	大学院受験生の方へ
	-------------------------------------*/
	#PageGraduate.pageInfo {
		#Main {
			.contBox {
				margin-top: 80px;
				.boxTitle {
					span { font-size: 18px; }
				}
				.boxContent {
					.contSubBox {margin-top: 50px; }
					.subBox {
						margin-top: 50px;
						&_title {
							font-size: 18px;
							line-height: 24px;
							text-align: justify;
							margin-bottom: 10px;
							&:before { top: 6px; }
							& + .subBox_content { padding-left: 30px; }
						}
						&_content {
							padding-left: 0;
							& > ul { padding-left: 18px; }
							p.square { margin-top: 13px; }
						}
					}
					h5 {
						font-size: 18px;
						&.square {
							font-size: 16px;
							&:before { top: 12px; }
							& + p:not(.square) { margin-top: 1px; }
						}
						& + p { line-height: 30px !important; }
					}
					p {
						&.remark {
							font-size: 13px;
							line-height: 24px;
							padding-left: 15px;
							margin-left: 0;
							& + .remark { margin-top: 13px; }
						}
						& + h5.square { margin-top: 6px; }
					}
					ol {
						li {
							font-size: 15px;
							line-height: 30px;
						}
					}
					table { margin: 22px 0 24px; }
					.square {
						& + ul { padding-left: 18px; }
						&:before { top: 13px; }
					}
					.pdfLink {
						flex-wrap: wrap;
						background-position: right 15px center;
						padding: 18px 16px;
						span {
							background-size: 15px auto;
							padding-right: 27px;
						}
						small {
							margin-top: 2px;
							margin-left: 0;
						}
					}
				}
			}
			#ContBox01 {
				margin-top: 40px;
				.boxContent {
					padding-top: 34px;
				}
			}
			#ContBox02 {
				.boxContent {
					ul + h5 { margin-top: 25px; }
					.contSubBox02, .contSubBox05 {
						.subBox02, .subBox04 {
							p { line-height: 30px; }
						}
					}
					.contSubBox03, .contSubBox04, .contSubBox06, .contSubBox07 {
						.subBox { margin-top: 0; }
					}
				}
			}
			#ContBox03 {
				margin-bottom: 105px;
				.boxContent {
					.subBox { margin-top: 30px; }
				}
			}
		}
	}

	/*
	教育学研究科のポリシー
	-------------------------------------*/
	#PageGraduate.pagePurpose {
		#Main {
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
					padding-left: 0;
					p {
						line-height: 30px;
						strong {
							display: block;
							font-weight: bold;
						}
						& + p { margin-top: 30px; }
						& + ol { margin-top: 30px;}
					}
					ol {
						li {
							line-height: 30px;
							p { line-height: 30px; }
							& + li { margin-top: 10px; }
						}
						& + p { margin-top: 30px; }
					}
					dl {
						margin: 20px 0 40px;
						dt {
					    padding: 22px 15px 20px;
					    font-size: 16px;
					    letter-spacing: .05em;
					    line-height: 24px;
					    text-align: justify;
						}
						dd {
					    padding: 21px 15px 22px;
						}
					}
				}
			}
			.contSubBox {
				width: 100%;
				margin-top: 49px;
				margin-left: 0;
				&_content {
					padding-top: 31px;
					padding-left: 0;
				}
				& + dl { margin-top: 40px !important;}
			}
			.subBox {
				&_content { padding-left: 30px; }
			}
			#ContBox01 { margin-top: 36px; }
			#ContBox03 { margin-bottom: 105px; }
		}
	}

	/*
	研究内容
	-------------------------------------*/
	#PageResearch, #PageEducation {
		#Main {
			p {
				line-height: 30px;
				& + p { margin-top: 30px; }
			}
			.categoryList {
				h4 {
					font-size: 22px;
					line-height: 35px;
					margin: 33px 0 23px;
				}
				.flexBox {
					margin-bottom: -15px;
					.flexItem {
						width: calc( (100% - 15px) / 2 );
						height: auto;
						background-position: right 12px center;
						background-size: 6px auto;
						font-size: 14px;
						padding: 21px 24px 22px 12px;
						margin-right: 15px;
						margin-bottom: 15px;
						&:nth-of-type(3n) { margin-right: 15px; }
						&:nth-of-type(2n) { margin-right: 0; }
						p { line-height: 21px !important; }
						small {
							display: inline;
							font-size: 11px;
							margin-top: 0;
						}
					}
				}
			}
		}
	}
	#PageResearch.pageIndex {
		#Main {
			padding-top: 77px;
			#ContBox01 {
				margin-top: 36px;
				margin-bottom: 102px;
				.boxContent {
					padding-top: 15px;
					& > p { padding-left: 0; }
					.contSubBox {
						margin-top: 30px;
						&_content {
							padding-top: 0;
							padding-left: 0;
							table {
								margin: 31px 0 56px;
								th {
									width: 33.23529411764706% !important;
									font-size: 14px;
									padding: 14px 8px 13px;
									vertical-align: middle;
								}
								td {
									font-size: 14px;
									line-height: 21px;
									text-align: justify;
									padding: 12px 8px;
								}
							}
						}
					}
				}
			}
		}
	}

	/*
	研究内容 教育学環専攻
	-------------------------------------*/
	#PageEducation.pageIndex {
		#Main {
			#ContBox01 {
				margin-top: 36px;
				margin-bottom: 102px;
				.boxHead { margin-bottom: 15px; }
			}
		}
	}

	/*
	研究内容 教育学環専攻 カテゴリ
	-------------------------------------*/
	#PageEducation.pageCategory {
		#Main {
			#ContBox01 {
				margin-top: 31px;
				.boxContent {
					padding: 0;
					.entryBox {
						.wp-caption {
							width: calc( (100% - 15px) / 2 );
							margin-right: 15px;
							margin-bottom: 34px;
							&:nth-of-type(3n) { margin-right: 15px; }
							&:nth-of-type(2n) { margin-right: 0; }
						}
					}
				}
			}
			#ContBox02 {
				margin-top: 36px;
				margin-bottom: 102px;
				.boxContent {
					padding-top: 30px;
					.subBox {
						margin-bottom: 73px;
						&_content {
							padding: 40px 0 0 ;
							.flexBox {
								flex-direction: column;
								margin-bottom: 23px;
								figure {
									width: 224px;
									height: 304px;
									margin: 0 auto;
								}
								div {
									width: 100%;
									margin-top: 40px;
									h4 {
										font-size: 15px;
										line-height: 30px;
										background-position: left 21px top 20px;
										background-size: 17px auto;
										padding: 12px 15px 12px 56px;
										margin-bottom: 21px;
									}
									ul {
										padding-left: 15px;
										margin-top: 16px;
										li + li { margin-top: 0; }
									}
								}
							}
						}
					}
				}
			}
		}
	}

	/*
	教員一覧
	-------------------------------------*/
	#PageStaffList {
		#Main {
			.categoryList {
				margin-top: 69px;
				margin-bottom: 102px;
				h4 {
					font-size: 22px;
					line-height: 35px;
					margin: 3px 0 23px;
				}
				.flexBox {
					margin-bottom: -15px;
					.flexItem {
						width: calc( (100% - 15px) / 2 );
						height: auto;
						background-position: right 12px center;
						background-size: 6px auto;
						font-size: 14px;
						padding: 21px 24px 22px 12px;
						margin-right: 15px;
						margin-bottom: 15px;
						&:nth-of-type(3n) { margin-right: 15px; }
						&:nth-of-type(2n) { margin-right: 0; }
						p { line-height: 21px !important; }
						small {
							display: inline;
							font-size: 11px;
							margin-top: 0;
						}
					}
				}
			}
		}
	}
	#PageStaffList.pageIndex {
		#Main {
			.contBox {
				margin-top: 76px;
				.leaderImage { margin-top: 35px; }
				.boxContent {
					.teacher {
						&_item {
							width: 100%;
							min-height: 154px;
							margin-bottom: 30px;
							a:hover:after { display: none; }
							.photo {
								width: 127px;
							}
							.text {
								width: calc(100% - 127px);
								padding: 27px 20px 20px 25px;
								.name {
									font-size: 18px;
									line-height: 20px;
								}
								.domain {
									font-size: 11px;
									line-height: 16px;
									background-position: left 16px top 15px;
									background-size: 12px auto;
									padding: 12px 13px 14px 38px;
								}
							}
						}
					}
				}
			}
			#ContBox01 { margin-top: 46px; }
		}
	}

	/*
	教員一覧 カテゴリ
	-------------------------------------*/
	#PageStaffList.pageCategory {
		#Main {
			.contBox {
				.boxContent {
					padding-top: 30px;
					.subBox {
						&_content {
							padding: 40px 0 0 ;
							.flexBox {
								flex-direction: column;
								margin-bottom: 23px;
								figure {
									width: 224px;
									height: 304px;
									margin: 0 auto;
								}
								div {
									width: 100%;
									margin-top: 40px;
									h4 {
										font-size: 15px;
										line-height: 30px;
										background-position: left 21px top 20px;
										background-size: 17px auto;
										padding: 12px 15px 12px 56px;
										margin-bottom: 21px;
									}
									p { line-height: 30px; }
									ul {
										padding-left: 15px;
										margin-top: 16px;
										li + li { margin-top: 0; }
									}
								}
							}
						}
						& + .subBox { margin-top: 50px; }
					}
				}
			}
			.categoryList { margin-top: 73px; }
		}
	}

	/*
	取得できる資格
	-------------------------------------*/
	#PageGraduate.pageObtain {
		#Main {
			#ContBox01 {
				margin-bottom: 105px;
				.boxContent {
					.subBox {
						margin-top: 43px;
						&_content {
							padding-top: 14px;
							p {
								line-height: 30px;
								padding-left: 0;
							}
							img {
								width: 100%;
								float: none;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}

	/*
	取得できる資格 公認心理師
	-------------------------------------*/
	#PageGraduate.pageKouninup {
		#Main {
			#ContBox01 {
				margin-top: 36px;
				margin-bottom: 102px;
				.boxContent {
					padding-top: 15px;
					p {
						line-height: 30px;
						padding: 0;
						&.remark {
							padding-right: 0;
						}
						& + p { margin-top: 16px; }
					}
					ol, ul {
						padding: 0;
						li { line-height: 30px; }
					}
					.borderTitle {
						p { padding-left: 0; }
					}
					.contSubBox {
						margin-top: 48px;
						&_content {
							padding-top: 32px;
							& > div {
								padding-left: 0;
								margin-top: 25px;
								p {
									padding-left: 21px;
									&.remark { padding-left: 39px; }
								}
								&:first-of-type { margin-top: 0; }
								& + p { margin: 48px 0 -24px; }
							}
							h4 {
								font-size: 18px;
								text-align: justify;
								padding-left: 19px;
								margin-bottom: 10px;
							}
							ol.maru {
								margin: 0;
								li:before { top: 7px; }
							}
						}
					}
				}
			}
		}
	}

	/*
	修了後の進路
	-------------------------------------*/
	#PageGraduate.pageAfter {
		#Main {
			#ContBox01 {
				margin-top: 36px;
				margin-bottom: 102px;
				.boxContent {
					padding-top: 28px;
					img {
						display: block;
						float: none;
						margin: 0 auto 30px;
					}
					p {
						line-height: 30px;
						padding-left: 0;
						&.remark {
							padding-left: 41px;
							&:before {
								left: 22px;
							}
						}
					}
					.contSubBox {
						margin-top: 43px;
						&_content { padding-top: 25px; }
						&:last-of-type { margin-bottom: 38px; }
					}
					.subBox {
						&_content {
							margin-top: 10px;
							padding-left: 30px;
							ul {
								padding-left: 0;
								li {
									strong { font-size: 18px; }
									p { padding-left: 0; }
									& + li { margin-top: 23px; }
								}
							}
						}
					}
				}
			}
			.containerMenu01 {
	 			.flexBox .flexItem09 { background-color: #e1f4ee; }
	 		}
		}
	}

	/*
	一般の皆様へ
	-------------------------------------*/
	#PageVisitors.pageIndex {
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			.postArea {
				.boxContent { padding-bottom: 105px; }
			}
			#ContainerMenu {
				.flexBox {
					.flexItem {
						background-position: right 9px center;
						background-size: 16px auto;
					}
				}
			}
		}
	}

	/*
	在校生・卒業生
	-------------------------------------*/
	#PageTowards {
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			#ContainerMenu {
				.flexBox {
					.flexItem05, .flexItem08, .flexItem10, .flexItem11 {
						background-position: right 9px center;
						background-size: 16px auto;
					}
				}
			}
		}
	}

	/*
	紀要編集委員会からのお知らせ
	-------------------------------------*/
	#PageTowards.pageSubmission {
		#Main {
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
					padding-left: 0;
					p {
						line-height: 30px;
						& + p { margin-top: 30px; }
					}
				}
				&:first-of-type { margin-top: 36px; }
			}
			#ContainerMenu {
				margin-top: 102px;
			}
		}
	}

	/*
	証明書の請求方法
	-------------------------------------*/
	#PageTowards.pageCertificate {
		#Main {
			.lowerLink {
				&_item{
					min-height: 81px;
					height: auto;
				}
			}
			.contBox {
				&:first-of-type{
					margin-top: 50px;
				}
				margin-top: 76px;
				.boxContent {
					padding-top: 28px;
				}
				.contSubBox01{
					margin-top: 50px;
					&> p{
						padding-left: 0;
					}
					&> p ,&> ol, &> ul{
						margin-left: 0;
					}
				}
			}
			#ContainerMenu{
				margin-top: 102px;
			}
		}
	}

	/*
	目的とポリシー（教育学部）
	-------------------------------------*/
	/*
	目的とポリシー（教育学研究科）
	-------------------------------------*/
	#PageTowards.pagePurposeU,
	#PageTowards.pagePurposeG{
		#Main {
			.pageTitle{
				& + figure{
					margin: 46px auto 0;
					width: calc(100% - 34px);
				}
			}
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
					padding-left: 0;
					p {
						line-height: 30px;
						strong {
							display: block;
							font-weight: bold;
						}
						& + p { margin-top: 30px; }
						& + ol { margin-top: 30px;}
					}
					ol {
						li {
							line-height: 30px;
							p { line-height: 30px; }
							& + li { margin-top: 10px; }
						}
						& + p { margin-top: 30px; }
					}
					ul {
						li { line-height: 30px; }
					}
					dl {
						margin: 20px 0 40px;
						dt {
					    padding: 22px 15px 20px;
					    font-size: 16px;
					    letter-spacing: .05em;
					    line-height: 24px;
					    text-align: justify;
						}
						dd {
					    padding: 21px 15px 22px;
						}
					}
				}
			}
			.contSubBox {
				width: 100%;
				margin-top: 49px;
				margin-left: 0;
				&_content {
					padding-top: 31px;
					padding-left: 0;
				}
				& + dl { margin-top: 40px !important;}
			}
			.subBox {
				&_content { padding-left: 30px; }
			}
			#ContBox01 { margin-top: 36px; }
			#ContBox03 { margin-bottom: 105px; }
		}
	}

	/*
	オンラインによるICTを用いた学習・研究のために
	-------------------------------------*/
	#PageTowards.pageRemote {
		#Main {
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
					p {
						line-height: 30px;
						&.square {
					    &:before { top: 13px;}
						}
					}
					& > p, & > ul {
						padding-left: 0;
					}
					& > p.square {
						padding-left: 16px;
						&:before { left: 0; }
						& + ul {
							padding-left: 18px;
						}
					}
				}
				&:first-of-type { margin-top: 36px; }
			}
			.contSubBox {
				margin-top: 49px;
				&_content {
					padding-top: 31px;
					padding-left: 0;
					.subBox {
						&:first-of-type { margin-top: 0; }
					}
					.square {
						margin-top: 30px;
						&:first-of-type { margin-top: 0; }
						& + ul {
							padding-left: 28px;
						}
					}
				}
				&:first-of-type { margin-top: 30px; }
			}
			.subBox {
				margin-top: 40px;
				&_title { margin-bottom: 17px; }
				&_content { padding-left: 30px; }
			}
			#ContainerMenu {
				margin-top: 102px;
			}
		}
	}

	/*
	経済的支援を必要とされる学生の方へ
	-------------------------------------*/
	#PageTowards.pageSupport {
		#Main {
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
					padding-left: 0;
					p {
						line-height: 30px;
						& + p { margin-top: 30px; }
					}
				}
				&:first-of-type { margin-top: 36px; }
			}
			#ContainerMenu {
				margin-top: 102px;
			}
		}
	}

	/*
	教育学部サテライト利用の手引
	-------------------------------------*/
	#PageTowards.pageGuidance {
		#Main {
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
					padding-left: 0;
					p {
						line-height: 30px;
						& + p { margin-top: 30px; }
					}
				}
				&:first-of-type { margin-top: 36px; }
			}
			#ContainerMenu {
				margin-top: 102px;
			}
		}
	}

	/*
	ハラスメント相談窓口等について
	-------------------------------------*/
	#PageTowards.pageConsultation {
		#MainImg {
			#MainImgInner {
				figure { padding-top: 0; }
			}
		}
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
                    padding-left: 0px;
                    p{
                        line-height: 30px;
                        letter-spacing: .025em;
                        font-size: 15px;
                    }
                }
			}
			#ContBox01 { margin-top: 36px; }
		}
	}
	/*
	NEWS
	-------------------------------------*/
	#PageNews {
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			.catSearch {
				&_title {
					font-size: 16px;
					line-height: 24px;
					text-align: center;
					padding: 23px 15px;
				}
				&_content {
					padding: 25px 9px 20px 14px;
					ul {
						li {
							font-size: 12px;
							line-height: 24px;
							a { padding: 4px 14px; }
						}
					}
					button {
						width: 117px;
						height: 40px;
						font-size: 13px;
						line-height: 38px;
						background-position: right 20px center;
						background-size: 6px auto;
						padding-left: 25px;
						margin-top: 20px;
					}
				}
			}
			.contBox {
				.boxHead { margin: 39px 0 20px; }
			}
			.pageNation {
				margin-top: 30px;
				flex-wrap: wrap;
				justify-content: right;
				background-color: #8ec21f;
				li {
					height: 48px;
					span, p, a {
						font-size: 15px;
						line-height: 48px;
					}
					&:not(.text) { width: calc(100% / 7); }
					&.text {
						width: 100%;
						p {
							text-align: center;
							border-bottom: 1px solid #4f9404;
						}
					}
					&.first a {
						background-size: 16px auto;
					}
					&.prev a {
						background-size: 8px auto;
					}
					&.next a {
						background-size: 8px auto;
					}
					&.last a {
						background-size: 16px auto;
					}
				}
			}
		}
	}

	/*
	NEWS 記事詳細
	-------------------------------------*/
	#PageNews.pageEntry {
		#Main {
			#ContBox {
				.boxHead {
					.boxTitle {
						line-height: 30px;
						&:before { top: 6px; }
					}
					.otherInfo {
						flex-direction: column;
						padding-left: 46px;
						margin-top: 20px;
						.date {
							width: 100%;
							font-size: 15px;
					    margin-right: 0;
						}
						.cat {
					    flex-wrap: wrap;
					    width: 100%;
					    background-position: left center;
					    background-size: 10px auto;
					    padding-left: 15px;
					    margin-top: 7px;
							li {
								line-height: 17px;
						    font-size: 13px;
							}
						}
					}
				}
				.boxContent {
					.entryBox {
						p { line-height: 30px; }
					}
				}
				.entryPageNav {
					margin-top: 100px;
					li {
						width: 50%;
						a {
							font-size: 13px;
							padding: 21px 0;
						}
					}
					.top {
						width: 100%;
						order: 1;
						a {
							background-color: #8ec21f;
							font-weight: bold;
							span {
								background-position: left top 5px;
								background-size: 18px auto;
								padding-left: 28px;
							}
						}
					}
					.prev {
						order: 2;
						a {
							border-right: 1px solid #fff;
							span {
								background-size: 6px auto;
								padding-left: 15px;
							}
						}
					}
					.next {
						order: 3;
						a span {
							background-size: 6px auto;
							padding-right: 18px;
						}
					}
				}
			}
		}
	}

	/*
	検索ページ
	-------------------------------------*/
	#PageSearch.pageIndex {
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			.borderTitle {
				margin-bottom: 39px;
				& > p { line-height: 30px; }
			}
			.pageNation {
				margin-top: 30px;
				flex-wrap: wrap;
				justify-content: right;
				background-color: #8ec21f;
				li {
					height: 48px;
					span, p, a {
						font-size: 15px;
						line-height: 48px;
					}
					&:not(.text) { width: calc(100% / 7); }
					&.text {
						width: 100%;
						p {
							text-align: center;
							border-bottom: 1px solid #4f9404;
						}
					}
					&.first a {
						background-size: 16px auto;
					}
					&.prev a {
						background-size: 8px auto;
					}
					&.next a {
						background-size: 8px auto;
					}
					&.last a {
						background-size: 16px auto;
					}
				}
			}
		}
	}

	/*
	プライバシーポリシー
	-------------------------------------*/
	#PagePrivacy.pageIndex {
		#MainImg {
			#MainImgInner {
				figure { padding-top: 0; }
			}
		}
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			#ContBox01 {
				margin-top: 48px;
				p {
					line-height: 30px;
					& + p { margin-top: 30px; }
				}
				.contSubBox {
					&_content {
						padding-top: 31px;
						padding-left: 0px;
					}
					& + .contSubBox { margin-top: 49px; }
				}
				.subBox {
					&_content {
						padding-left: 30px;
					}
				}
			}
		}
	}

	/*
	教職員の方へ
	-------------------------------------*/
	#PageTeachers.pageIndex {
		#MainImg {
			#MainImgInner {
				figure { padding-top: 0; }
			}
		}
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			.contBox {
				margin-top: 76px;
				.boxContent {
					padding-top: 15px;
			    padding-left: 0px;
				}
			}
			#ContBox01 { margin-top: 36px; }
		}
	}

	/*
	404
	-------------------------------------*/
	#Page404.pageIndex {
		#Main {
			padding-top: 72px;
			padding-bottom: 90px;
			.contBox {
				margin-top: 76px;
				.linkBtn {
					.button {
				    height: 36px;
				    line-height: 34px;
				    letter-spacing: .025em;
				    font-size: 12px;
					}
				}
			}
		}
	}


}