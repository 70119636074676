@charset "UTF-8";
@import url(base.css);
@import url(common.css);
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
/*
トップページ
-------------------------------------*/
#Page.pageIndex #MainImg {
  width: 100%; }
  #Page.pageIndex #MainImg #MainImgInner {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    display: block;
    background-color: #fff; }
    #Page.pageIndex #MainImg #MainImgInner .slideImg {
      width: 100%;
      min-width: 1280px;
      padding-top: 64.51425247236766%;
      position: relative;
      left: 50%;
      transform: translateX(-50%); }
      #Page.pageIndex #MainImg #MainImgInner .slideImg li {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        padding-left: 0;
        margin-top: 0 !important; }
        #Page.pageIndex #MainImg #MainImgInner .slideImg li:before {
          display: none; }
        #Page.pageIndex #MainImg #MainImgInner .slideImg li:nth-of-type(1) {
          background-image: url(../img/contents/top/mainimg_01.jpg);
          animation: slider01 24s -1.992s linear infinite; }
        #Page.pageIndex #MainImg #MainImgInner .slideImg li:nth-of-type(2) {
          background-image: url(../img/contents/top/mainimg_02.jpg);
          animation: slider02 24s linear infinite; }
        #Page.pageIndex #MainImg #MainImgInner .slideImg li:nth-of-type(3) {
          background-image: url(../img/contents/top/mainimg_03.jpg);
          animation: slider03 24s linear infinite; }
    #Page.pageIndex #MainImg #MainImgInner .button {
      position: absolute;
      width: 228px;
      height: 46px;
      background: #000 url(../img/contents/arrow_03.png) no-repeat right 16px center;
      background-size: 12px auto;
      font-size: 15px;
      color: #fff;
      letter-spacing: 0.02em;
      line-height: 46px;
      padding-left: 20px;
      border-radius: 25px;
      right: 40px;
      bottom: 40px; }
#Page.pageIndex #Main #ContBox01 {
  padding: 0;
  margin-bottom: 175px; }
  #Page.pageIndex #Main #ContBox01 .innerBasic {
    max-width: 100%; }
  #Page.pageIndex #Main #ContBox01 .flexBox {
    flex-wrap: wrap;
    justify-content: space-between; }
    #Page.pageIndex #Main #ContBox01 .flexBox .flexItem {
      width: calc( (100% - 2px) / 3 );
      height: 361px;
      border-bottom: 1px solid #fff; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: #284376 url(../img/contents/arrow_01.png) no-repeat right 41px bottom 47px;
        background-size: 10px auto;
        transition: background-color 0.5s ease; }
        #Page.pageIndex #Main #ContBox01 .flexBox .flexItem a:hover {
          background-color: #00266f; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem figure {
        width: 74.09470752089136%;
        height: 210px;
        overflow: hidden; }
        #Page.pageIndex #Main #ContBox01 .flexBox .flexItem figure img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem p {
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.025em;
        line-height: 32px;
        position: absolute;
        left: 34px;
        bottom: 38px; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem_04 a {
        background-image: url(../img/contents/link_02.png);
        background-position: right 40px bottom 48px;
        background-size: 14px auto; }
#Page.pageIndex #Main #ImportPost {
  margin-bottom: 104px; }
#Page.pageIndex #Main #ContBox03 {
  position: relative; }
  #Page.pageIndex #Main #ContBox03 .boxHead {
    width: calc(100% + 119px);
    background-color: #f8f8f8;
    padding: 41px 0 66px;
    margin-left: -59px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6; }
    #Page.pageIndex #Main #ContBox03 .boxHead h2 {
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 0.025em;
      line-height: 44px;
      text-align: center;
      position: relative; }
      #Page.pageIndex #Main #ContBox03 .boxHead h2 small {
        display: block;
        font-size: 26px;
        letter-spacing: 0.025em;
        margin-bottom: -2px; }
      #Page.pageIndex #Main #ContBox03 .boxHead h2:after {
        position: absolute;
        content: '';
        width: 64px;
        height: 6px;
        background-color: #009a6a;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%); }
  #Page.pageIndex #Main #ContBox03 .boxContent {
    padding: 56px 25px 163px 0; }
    #Page.pageIndex #Main #ContBox03 .boxContent .flexBox {
      justify-content: center; }
      #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem01 {
        flex-grow: 1; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem01 img {
          display: block;
          width: 100%;
          max-width: 626px;
          height: auto;
          margin: 0 auto; }
      #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 {
        position: relative;
        padding-bottom: 67px;
        margin-left: 24px; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li {
          width: 290px;
          height: 58px;
          text-align: center;
          border-radius: 40px;
          opacity: 1;
          transition: opacity 0.5s ease;
          padding-left: 0; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li:before {
            display: none; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li span {
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            line-height: 58px;
            background: url(../img/contents/arrow_01.png) no-repeat right center;
            background-size: 8px auto;
            padding-right: 27px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-01 {
            background-color: #712512; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-02 {
            background-color: #a4381a; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-03 {
            background-color: #e0684e; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-04 {
            background-color: #f099a8; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-05 {
            background-color: #e95f7b; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-06 {
            background-color: #ba2b76; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-07 {
            background-color: #25437d; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-08 {
            background-color: #65437c; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-09 {
            background-color: #49873c; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-10 {
            background-color: #3a682f; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-06, #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-10 {
            padding-top: 9px; }
            #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-06 span, #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li.js-pop-10 span {
              line-height: 20px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li + li {
            margin-top: 20px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 ul li:hover {
            cursor: pointer;
            opacity: 0.7; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem02 p {
          position: absolute;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 24px;
          white-space: nowrap;
          right: 0;
          bottom: 0; }
  #Page.pageIndex #Main #ContBox03 .popOpenWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    padding-left: 201px;
    z-index: 51;
    display: none; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper.open {
      display: flex; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpenInner {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 70px 0; }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpenInner.center {
        top: 50%;
        transform: translateY(-50%); }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpenInner:hover {
        cursor: pointer; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen {
      display: none;
      width: 59.31417979610751%;
      height: 100%;
      max-height: 100%;
      background-color: #f8f8f8;
      border: 1px solid #ececec;
      position: relative;
      z-index: 1; }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen:hover {
        cursor: auto; }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen h3 {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.025em;
        line-height: 30px;
        padding: 34px 70px 35px; }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content {
        height: calc(100% - 99px);
        overflow-y: scroll;
        scrollbar-width: thin;
        /*firefox用*/ }
        #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content::-webkit-scrollbar {
          width: 6px; }
        #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content::-webkit-scrollbar-thumb {
          background: #d7d7d7;
          border-radius: 6px; }
        #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content .inner {
          height: auto;
          padding: 34px 60px 56px 69px; }
        #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content h4 {
          font-size: 20px;
          font-weight: bold;
          text-align: center; }
          #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content h4 + p {
            margin-top: 10px; }
        #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content p {
          letter-spacing: 0.025em;
          line-height: 30px; }
        #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content figure {
          margin-top: 24px; }
          #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content figure img {
            width: 100%;
            height: auto; }
        #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content a {
          display: block;
          width: 152px;
          height: 46px;
          background: #000 url(../img/contents/arrow_01.png) no-repeat right 39px center;
          background-size: 8px auto;
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 0.05em;
          line-height: 46px;
          padding-left: 42px;
          margin: 28px auto 0;
          border-radius: 30px; }
          #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen .content a + h4 {
            margin-top: 50px; }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen.is_visible {
        display: block; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper button {
      position: absolute; }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper button.popClose {
        width: 58px;
        height: 58px;
        background: url(../img/contents/top/close.png) no-repeat left top;
        background-size: 100% auto;
        top: -67px;
        right: -67px; }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper button.popPrev {
        width: 22px;
        height: 32px;
        background: url(../img/contents/top/arrowL.png) no-repeat left top;
        background-size: 100% auto;
        top: 50%;
        left: -56px;
        transform: translateY(-50%); }
      #Page.pageIndex #Main #ContBox03 .popOpenWrapper button.popNext {
        width: 22px;
        height: 32px;
        background: url(../img/contents/top/arrowR.png) no-repeat left top;
        background-size: 100% auto;
        top: 50%;
        right: -56px;
        transform: translateY(-50%); }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen01 h3 {
      background-color: #712512; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen02 h3 {
      background-color: #a4381a; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen03 h3 {
      background-color: #e0684e; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen04 h3 {
      background-color: #f099a8; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen05 .content a, #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen06 .content a {
      margin-top: 14px; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen05 h3 {
      background-color: #e95f7b; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen06 h3 {
      background-color: #ba2b76; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen07 h3 {
      background-color: #25437d; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen08 h3 {
      background-color: #65437c; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen09 h3 {
      background-color: #49873c; }
    #Page.pageIndex #Main #ContBox03 .popOpenWrapper .popOpen10 h3 {
      background-color: #3a682f; }

@keyframes slider01 {
  0% {
    opacity: 0; }
  8.3% {
    opacity: 1; }
  33.3% {
    opacity: 1; }
  41.6% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }
@keyframes slider02 {
  0% {
    opacity: 0; }
  25.1% {
    opacity: 0; }
  33.4% {
    opacity: 1; }
  58.4% {
    opacity: 1; }
  66.7% {
    opacity: 0; }
  100% {
    opacity: 0; } }
@keyframes slider03 {
  0% {
    opacity: 0; }
  27% {
    opacity: 0; }
  58.4% {
    opacity: 0; }
  66.7% {
    opacity: 1; }
  91.7% {
    opacity: 1; }
  100% {
    opacity: 0; } }
/*
教育学研究科・教育学部について
-------------------------------------*/
#PageOverview #MainImg #MainImgInner figure, #PageFacility #MainImg #MainImgInner figure, #PagePublications #MainImg #MainImgInner figure {
  background-image: url(../img/contents/overview/mainimg.jpg); }
#PageOverview #Main, #PageFacility #Main, #PagePublications #Main {
  padding-top: 110px;
  padding-bottom: 200px; }
#PageOverview:not(.pageIndex) #Main .boxContent p, #PageFacility:not(.pageIndex) #Main .boxContent p, #PagePublications:not(.pageIndex) #Main .boxContent p {
  line-height: 40px; }
  #PageOverview:not(.pageIndex) #Main .boxContent p + p, #PageFacility:not(.pageIndex) #Main .boxContent p + p, #PagePublications:not(.pageIndex) #Main .boxContent p + p {
    margin-top: 40px; }

#PageOverview.pageIndex #Main {
  padding-top: 112px;
  padding-bottom: 199px; }
  #PageOverview.pageIndex #Main #ContBox02 .boxContent {
    padding-bottom: 197px; }

/*
研究科長あいさつ
-------------------------------------*/
#PageOverview.pageDean #Main #ContBox01 {
  margin-bottom: 188px; }
  #PageOverview.pageDean #Main #ContBox01 .boxHead h3 {
    margin: 40px 0 110px; }
  #PageOverview.pageDean #Main #ContBox01 .boxContent figure {
    position: relative;
    padding-bottom: 126px;
    margin-bottom: 76px; }
    #PageOverview.pageDean #Main #ContBox01 .boxContent figure img {
      width: 100%; }
    #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption {
      position: absolute;
      right: 133px;
      bottom: 0; }
      #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption p {
        font-size: 26px;
        letter-spacing: 0;
        line-height: 1;
        margin-bottom: 12px; }
      #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption span {
        display: block;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px; }
      #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 96px;
        background-color: #000;
        top: -9px;
        left: -42px; }
      #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption:after {
        position: absolute;
        content: '';
        width: 140px;
        height: 1px;
        background-color: #000;
        top: 38px;
        left: -168px; }
  #PageOverview.pageDean #Main #ContBox01 .boxContent .text {
    padding: 0 20px; }
#PageOverview.pageDean #Main #ContainerMenu .flexBox .flexItem01 {
  background-color: #e1f4ee; }

/*
歩み
-------------------------------------*/
#PageOverview.pageHistory #Main #ContBox01 {
  margin-top: 48px;
  margin-bottom: 192px; }
  #PageOverview.pageHistory #Main #ContBox01 .boxContent > p {
    padding-left: 20px;
    padding-right: 20px; }
#PageOverview.pageHistory #Main #ContainerMenu .flexBox .flexItem02 {
  background-color: #e1f4ee; }

/*
管理運営組織
-------------------------------------*/
#PageOverview.pageMoorg #Main {
  padding-top: 111px; }
  #PageOverview.pageMoorg #Main #ContBox01 {
    margin-top: 48px;
    margin-bottom: 201px; }
    #PageOverview.pageMoorg #Main #ContBox01 .boxContent > p {
      padding-left: 20px;
      padding-right: 20px; }
    #PageOverview.pageMoorg #Main #ContBox01 .boxContent figure {
      margin-top: 72px; }
      #PageOverview.pageMoorg #Main #ContBox01 .boxContent figure img {
        width: 100%; }
  #PageOverview.pageMoorg #Main #ContainerMenu .flexBox .flexItem03 {
    background-color: #e1f4ee; }

/*
研究教育組織
-------------------------------------*/
#PageOverview.pageReorg #Main #ContBox01 {
  margin-top: 48px;
  margin-bottom: 190px; }
  #PageOverview.pageReorg #Main #ContBox01 .boxContent > p {
    text-indent: 1em; }
  #PageOverview.pageReorg #Main #ContBox01 .boxContent figure {
    margin-top: 49px;
    margin-bottom: 85px; }
    #PageOverview.pageReorg #Main #ContBox01 .boxContent figure img {
      width: 100%; }
  #PageOverview.pageReorg #Main #ContBox01 .boxContent .subBox_content {
    padding-left: 28px; }
  #PageOverview.pageReorg #Main #ContBox01 .boxContent .subBox + .subBox {
    margin-top: 57px; }
#PageOverview.pageReorg #Main #ContainerMenu .flexBox .flexItem04 {
  background-color: #e1f4ee; }

/*
附属施設等
-------------------------------------*/
#PageFacility #Main #ContainerMenu .flexBox .flexItem05 {
  background-color: #e1f4ee; }

#PageFacility.pageIndex #Main {
  padding-top: 111px; }
  #PageFacility.pageIndex #Main #ContBox01 {
    margin-top: 59px;
    margin-bottom: 200px; }
    #PageFacility.pageIndex #Main #ContBox01 .flexBox {
      flex-wrap: wrap;
      margin-bottom: -63px; }
      #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem {
        width: calc( (100% - 60px) / 3 );
        margin-right: 30px;
        margin-bottom: 63px; }
        #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem a {
          display: block;
          width: 100%;
          height: 100%;
          transition: 0.5s ease; }
          #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem a img {
            transition: 0.5s ease; }
          #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem a:hover {
            color: #009a6a; }
            #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem a:hover dt img {
              opacity: 0.7; }
        #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem dt {
          width: 100%; }
          #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem dt img {
            width: 100%; }
        #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem dd {
          font-weight: bold;
          line-height: 40px;
          background: url(../img/contents/arrow_02.png) no-repeat right 3px center;
          background-size: 8px auto;
          padding: 14px 0;
          border-bottom: 1px solid #009a6a; }
        #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem:nth-of-type(3n) {
          margin-right: 0; }

/*
附属施設等 教育学部図書室
-------------------------------------*/
#PageFacility.pageLibrary #Main .lowerLink_item_01 a {
  color: #888; }
#PageFacility.pageLibrary #Main .lowerPageTitle {
  margin-top: 96px; }
#PageFacility.pageLibrary #Main > figure {
  width: calc(100% - 119px);
  max-width: 1000px;
  margin: 47px auto 97px; }
  #PageFacility.pageLibrary #Main > figure img {
    width: 100%; }
#PageFacility.pageLibrary #Main #ContBox01 .boxContent {
  margin-top: 40px; }
#PageFacility.pageLibrary #Main #ContBox02 {
  margin-top: 100px;
  margin-bottom: 200px; }
  #PageFacility.pageLibrary #Main #ContBox02 .boxContent {
    padding-top: 27px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent > p {
      padding-left: 23px;
      margin-bottom: 25px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .pdfLink {
      border-top: 1px solid #e9e9e9;
      margin-bottom: 98px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .pdfLink a {
        display: block;
        width: 100%;
        line-height: 40px;
        background: url(../img/contents/arrow_02.png) no-repeat right 31px center;
        background-size: 10px auto;
        padding: 26px 50px 33px 42px;
        border-bottom: 1px solid #e9e9e9; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .pdfLink a:hover {
          color: #009b6b; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .pdfLink a:after {
          display: none; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .pdfLink a span {
          display: inline-block;
          background: url(../img/contents/icon_pdf.png) no-repeat right 1px top 9px;
          background-size: 19px auto;
          padding-right: 34px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox + .subBox {
      margin-top: 69px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_title {
      font-size: 22px;
      margin-bottom: 16px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content {
      padding-left: 34px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content h5 {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 7px;
        text-indent: -16px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content > p {
        line-height: 26px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content .dotList {
        padding-left: 5px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content .dotList + h5 {
          margin-top: 8px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content p + ul {
        margin-top: 18px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content p strong {
        font-size: 18px;
        font-weight: bold;
        line-height: 40px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content p + table {
        margin-top: 20px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox01 .subBox_content p {
      padding-left: 20px;
      margin-top: 27px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox01 .subBox_content ul li span {
      display: block;
      margin-top: 10px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content {
      padding-left: 28px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table {
        margin-bottom: 16px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(1) {
          width: 26%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(2) {
          width: 34%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(3) {
          width: 26%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(4) {
          width: 14%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table td small {
          font-size: 15px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table td:nth-of-type(2), #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table td:nth-of-type(3) {
          text-align: center; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox07 .subBox_content ul + h5 {
      margin-top: 17px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox09 .subBox_content ul li small {
      display: block;
      font-size: 15px;
      margin: 5px 0 8px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox09 .subBox_content ul li a {
      line-height: 36px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox11 .subBox_content p {
      line-height: 36px;
      margin-top: -5px; }

/*
附属施設等 グローバル教育展開オフィス
-------------------------------------*/
#PageFacility.pageGlobal #Main .lowerLink_item_02 a {
  color: #888; }
#PageFacility.pageGlobal #Main .lowerPageTitle {
  margin-top: 96px; }
#PageFacility.pageGlobal #Main .contBox {
  margin-top: 148px; }
  #PageFacility.pageGlobal #Main .contBox .boxHead .title {
    font-size: 22px; }
  #PageFacility.pageGlobal #Main .contBox .boxContent .subBox {
    margin-top: 48px; }
    #PageFacility.pageGlobal #Main .contBox .boxContent .subBox_content {
      margin-top: 30px; }
      #PageFacility.pageGlobal #Main .contBox .boxContent .subBox_content p {
        padding-left: 22px;
        margin-bottom: 41px; }
#PageFacility.pageGlobal #Main #ContBox01 {
  margin-top: 29px; }
  #PageFacility.pageGlobal #Main #ContBox01 .boxContent .text {
    padding-left: 22px; }
    #PageFacility.pageGlobal #Main #ContBox01 .boxContent .text img {
      float: right;
      width: 300px;
      margin: 6px 0 54px 40px; }
  #PageFacility.pageGlobal #Main #ContBox01 .boxContent figure {
    margin-top: 73px; }
    #PageFacility.pageGlobal #Main #ContBox01 .boxContent figure img {
      width: 100%; }
#PageFacility.pageGlobal #Main #ContBox04 {
  margin-bottom: 146px; }
  #PageFacility.pageGlobal #Main #ContBox04 .boxContent p.red {
    color: #ff0000;
    padding-left: 22px;
    margin-top: 14px; }

/*
附属施設等 心理教育相談室
-------------------------------------*/
#PageFacility.pageRoom #Main .lowerLink_item_03 a {
  color: #888; }
#PageFacility.pageRoom #Main .lowerPageTitle {
  margin-top: 96px; }
#PageFacility.pageRoom #Main .contBox {
  margin-top: 97px; }
  #PageFacility.pageRoom #Main .contBox .boxContent {
    margin-top: 31px; }
  #PageFacility.pageRoom #Main .contBox p {
    padding-left: 22px; }
#PageFacility.pageRoom #Main #ContBox01 {
  margin-top: 29px; }
  #PageFacility.pageRoom #Main #ContBox01 .boxContent {
    margin-top: 0; }
    #PageFacility.pageRoom #Main #ContBox01 .boxContent .text img {
      float: right;
      width: 300px;
      margin: 6px 0 54px 40px; }
    #PageFacility.pageRoom #Main #ContBox01 .boxContent figure {
      width: 100%;
      margin-top: 64px; }
      #PageFacility.pageRoom #Main #ContBox01 .boxContent figure img {
        width: 100%; }
#PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox {
  background-color: #eff8f5;
  padding: 38px 49px 34px;
  border-radius: 10px;
  margin-top: 41px; }
  #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox h4 {
    font-size: 22px;
    font-weight: bold;
    color: #009a6b;
    letter-spacing: 0.025em;
    line-height: 30px; }
  #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox ul {
    padding-left: 23px;
    margin-top: 18px; }
    #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox ul li {
      line-height: 40px;
      padding-left: 12px; }
      #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox ul li:before {
        width: 3px;
        height: 3px;
        top: 18px; }
      #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox ul li + li {
        margin-top: 0; }
#PageFacility.pageRoom #Main #ContBox03 .boxContent table {
  margin-top: 40px; }
#PageFacility.pageRoom #Main #ContBox04 .boxContent h4 {
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  padding-left: 11px;
  margin-bottom: 7px; }
#PageFacility.pageRoom #Main #ContBox04 .boxContent p + h4 {
  margin-top: 17px; }
#PageFacility.pageRoom #Main #ContBox05 {
  margin-bottom: 200px; }
  #PageFacility.pageRoom #Main #ContBox05 .boxContent .map {
    justify-content: space-between;
    margin-top: 36px; }
    #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem {
      width: calc( (100% - 30px) / 2  ); }
      #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem figure {
        border: 1px solid #ececec; }
        #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem figure img {
          width: 100%; }
      #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem a {
        display: block;
        width: 162px;
        height: 42px;
        font-size: 14px;
        font-weight: bold;
        color: #009b6b;
        letter-spacing: 0.05em;
        line-height: 40px;
        background: url(../img/contents/icon_pdf.png) no-repeat right 37px center;
        background-size: 18px auto;
        padding-left: 38px;
        margin: 30px auto 0;
        border: 1px solid #e9e9e9;
        border-radius: 42px;
        transition: 0.5s ease; }
        #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem a:hover {
          color: #fff;
          background-color: #009b6b;
          border-color: #009b6b; }

/*
附属施設等 こころの支援室
-------------------------------------*/
#PageFacility.pageSupport #Main .lowerLink_item_04 a {
  color: #888; }
#PageFacility.pageSupport #Main .lowerPageTitle {
  margin-top: 96px; }
#PageFacility.pageSupport #Main #ContBox01 {
  margin-top: 29px;
  margin-bottom: 188px; }
  #PageFacility.pageSupport #Main #ContBox01 .boxContent {
    padding-left: 22px; }
    #PageFacility.pageSupport #Main #ContBox01 .boxContent img {
      float: right;
      width: 300px;
      margin: 6px 0 27px 40px; }
    #PageFacility.pageSupport #Main #ContBox01 .boxContent ol {
      margin-bottom: 17px; }
      #PageFacility.pageSupport #Main #ContBox01 .boxContent ol > li {
        font-size: 18px;
        font-weight: bold;
        line-height: 30px; }
    #PageFacility.pageSupport #Main #ContBox01 .boxContent ul {
      margin: 18px 0 42px;
      padding-left: 12px; }
      #PageFacility.pageSupport #Main #ContBox01 .boxContent ul li {
        font-weight: 500; }

/*
附属施設等 附属臨床教育実践研究センター
-------------------------------------*/
#PageFacility.pageResearch #Main .lowerLink_item_05 a {
  color: #888; }
#PageFacility.pageResearch #Main .lowerPageTitle {
  margin-top: 96px; }
#PageFacility.pageResearch #Main #ContBox01 {
  margin-top: 29px; }
  #PageFacility.pageResearch #Main #ContBox01 .boxContent {
    padding-left: 22px; }
    #PageFacility.pageResearch #Main #ContBox01 .boxContent img {
      float: right;
      width: 300px;
      margin: 6px 0 27px 40px; }
#PageFacility.pageResearch #Main #ContBox02 {
  margin-top: 84px;
  margin-bottom: 197px; }
  #PageFacility.pageResearch #Main #ContBox02 .boxContent {
    padding-top: 78px; }
    #PageFacility.pageResearch #Main #ContBox02 .boxContent figure:last-of-type {
      margin-top: 130px; }
    #PageFacility.pageResearch #Main #ContBox02 .boxContent figure img {
      width: 100%; }

/*
刊行物・資料
-------------------------------------*/
#PagePublications #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PagePublications #Main .pageTitle {
    position: relative; }
    #PagePublications #Main .pageTitle .button {
      position: absolute;
      height: 46px;
      font-size: 14px;
      font-weight: bold;
      color: #009b6b;
      letter-spacing: 0.05em;
      line-height: 46px;
      background: #fff url(../img/contents/arrow_02.png) no-repeat right 32px center;
      background-size: 8px auto;
      padding: 0 52px 0 34px;
      border-radius: 46px;
      top: 27px;
      right: 21px; }
      #PagePublications #Main .pageTitle .button:hover {
        background-color: #009b6b;
        background-image: url(../img/contents/arrow_01.png);
        color: #fff;
        opacity: 1; }
  #PagePublications #Main .contBox .boxContent .postList li a {
    padding-left: 41px; }
    #PagePublications #Main .contBox .boxContent .postList li a .content {
      width: 100%; }
  #PagePublications #Main #ContainerMenu .flexBox .flexItem06 {
    background-color: #e1f4ee; }

#PagePublications.pageIndex #Main .lowerLink_item a {
  padding-right: 20px;
  background-image: url(../img/contents/arrow_04.png);
  background-position: right 2px center;
  background-size: 11px auto; }
#PagePublications.pageIndex #Main .contBox {
  margin-top: 135px; }
  #PagePublications.pageIndex #Main .contBox .boxHead {
    position: relative;
    margin-bottom: 47px; }
    #PagePublications.pageIndex #Main .contBox .boxHead .button {
      position: absolute;
      display: block;
      height: 46px;
      font-size: 14px;
      font-weight: bold;
      color: #009b6b;
      letter-spacing: 0.05em;
      line-height: 44px;
      background-color: #fff;
      padding: 0 31px 0 34px;
      border: 1px solid #e9e9e9;
      border-radius: 40px;
      top: -9px;
      right: 0; }
      #PagePublications.pageIndex #Main .contBox .boxHead .button span {
        display: inline-block;
        background: url(../img/contents/arrow_02.png) no-repeat right center;
        background-size: 8px auto;
        padding-right: 21px; }
      #PagePublications.pageIndex #Main .contBox .boxHead .button:hover {
        opacity: 1;
        background-color: #009b6b;
        color: #fff;
        border-color: #009b6b; }
        #PagePublications.pageIndex #Main .contBox .boxHead .button:hover span {
          background-image: url(../img/contents/arrow_01.png); }
#PagePublications.pageIndex #Main #ContBox01 {
  margin-top: 100px; }
#PagePublications.pageIndex #Main #ContBox05 .boxHead {
  margin-bottom: 27px; }
#PagePublications.pageIndex #Main #ContBox05 .boxContent .post {
  padding-left: 22px; }
  #PagePublications.pageIndex #Main #ContBox05 .boxContent .post p {
    letter-spacing: 0;
    line-height: 40px; }
#PagePublications.pageIndex #Main #ContBox06 {
  margin-bottom: 204px; }
  #PagePublications.pageIndex #Main #ContBox06 .boxHead {
    margin-bottom: 64px; }

/*
刊行物・資料 カテゴリ
-------------------------------------*/
#PagePublications.pageCategory #Main .lowerLink .currect a {
  color: #888; }
#PagePublications.pageCategory #Main #ContBox01 {
  margin-top: 100px;
  margin-bottom: 185px; }
  #PagePublications.pageCategory #Main #ContBox01 .boxContent {
    padding-top: 47px; }
    #PagePublications.pageCategory #Main #ContBox01 .boxContent .postList li a {
      padding-left: 40px; }
    #PagePublications.pageCategory #Main #ContBox01 .boxContent .thumbPostList_item .content p {
      line-height: 26px; }

/*
刊行物・資料 記事詳細
-------------------------------------*/
#PagePublications.pageEntry #Main #ContBox01 {
  margin-top: 100px;
  margin-bottom: 197px; }
  #PagePublications.pageEntry #Main #ContBox01 .boxContent {
    padding-top: 57px; }
    #PagePublications.pageEntry #Main #ContBox01 .boxContent .entryHead {
      flex-wrap: wrap; }
      #PagePublications.pageEntry #Main #ContBox01 .boxContent .entryHead p {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 0.025em;
        line-height: 30px;
        padding-bottom: 16px;
        border-bottom: 2px solid #009a6b; }
      #PagePublications.pageEntry #Main #ContBox01 .boxContent .entryHead div {
        flex-grow: 1;
        border-bottom: 2px solid #ececec; }
    #PagePublications.pageEntry #Main #ContBox01 .boxContent .entryBox {
      padding-top: 35px;
      padding-left: 22px; }

/*
広報用ロゴマークについて
-------------------------------------*/
#PageOverview.pageEduc #Main #ContBox01 {
  margin-top: 55px;
  margin-bottom: 187px; }
  #PageOverview.pageEduc #Main #ContBox01 .boxContent figure {
    text-align: center;
    margin-bottom: 43px; }
    #PageOverview.pageEduc #Main #ContBox01 .boxContent figure img {
      width: 208px;
      height: auto; }
#PageOverview.pageEduc #Main #ContainerMenu .flexBox .flexItem07 {
  background-color: #e1f4ee; }

/*
お問い合わせ先
-------------------------------------*/
#PageOverview.pageContact #Main .contBox {
  margin-top: 90px; }
  #PageOverview.pageContact #Main .contBox .boxContent {
    margin-top: 55px;
    padding-left: 21px; }
    #PageOverview.pageContact #Main .contBox .boxContent p {
      line-height: 26px;
      margin-bottom: 26px; }
      #PageOverview.pageContact #Main .contBox .boxContent p.red {
        color: #ff0000; }
    #PageOverview.pageContact #Main .contBox .boxContent table + p {
      margin-top: 33px; }
#PageOverview.pageContact #Main #ContBox01 {
  margin-top: 41px; }
#PageOverview.pageContact #Main #ContBox05 {
  margin-bottom: 194px; }
#PageOverview.pageContact #Main #ContainerMenu .flexBox .flexItem08 {
  background-color: #e1f4ee; }

/*
アクセス
-------------------------------------*/
#PageOverview.pageAccess #Main .contBox .boxHead .boxTitle {
  line-height: 40px; }
  #PageOverview.pageAccess #Main .contBox .boxHead .boxTitle:before {
    top: 9px; }
#PageOverview.pageAccess #Main #ContBox01 {
  margin-top: 45px; }
  #PageOverview.pageAccess #Main #ContBox01 .boxContent {
    padding-top: 43px; }
    #PageOverview.pageAccess #Main #ContBox01 .boxContent:after {
      display: block;
      content: '';
      clear: both; }
    #PageOverview.pageAccess #Main #ContBox01 .boxContent .map {
      position: relative;
      width: 100%;
      padding-top: 65.625%;
      margin-bottom: 28px;
      border: 1px solid #ececec;
      overflow: hidden; }
      #PageOverview.pageAccess #Main #ContBox01 .boxContent .map iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    #PageOverview.pageAccess #Main #ContBox01 .boxContent a {
      float: right;
      display: block;
      width: 200px;
      height: 46px;
      font-size: 14px;
      font-weight: bold;
      color: #009b6b;
      letter-spacing: 0.05em;
      line-height: 46px;
      background: url(../img/contents/link_03.png) no-repeat right 30px top 16px;
      background-size: 11px auto;
      padding-left: 30px;
      border: 1px solid #f3f3f3;
      border-radius: 46px; }
      #PageOverview.pageAccess #Main #ContBox01 .boxContent a:hover {
        opacity: 1;
        color: #fff;
        background-image: url(../img/contents/link_02.png);
        background-color: #009b6b;
        border-color: #009b6b; }
#PageOverview.pageAccess #Main #ContBox02 {
  margin-top: 111px; }
  #PageOverview.pageAccess #Main #ContBox02 .boxContent {
    padding-top: 43px; }
    #PageOverview.pageAccess #Main #ContBox02 .boxContent figure img {
      width: 100%; }
#PageOverview.pageAccess #Main #ContBox03 {
  margin-top: 134px;
  margin-bottom: 188px; }
  #PageOverview.pageAccess #Main #ContBox03 .boxContent {
    padding-top: 44px; }
    #PageOverview.pageAccess #Main #ContBox03 .boxContent > p {
      padding-left: 22px; }
    #PageOverview.pageAccess #Main #ContBox03 .boxContent .contSubBox {
      margin-top: 51px; }
    #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox {
      margin-top: 46px; }
      #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_title {
        margin-bottom: 15px; }
      #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content {
        padding-left: 22px; }
        #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content ol + p {
          margin-top: 35px; }
        #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content ol li ul {
          margin-top: 10px; }
        #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content p + ol {
          margin-top: 16px; }
#PageOverview.pageAccess #Main #ContainerMenu .flexBox .flexItem09 {
  background-color: #e1f4ee; }

/*
学部受験生の方へ トップページ
-------------------------------------*/
#PageAdmissions #MainImg #MainImgInner figure {
  background-image: url(../img/contents/admissions/mainimg.jpg); }
#PageAdmissions #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
#PageAdmissions:not(.pageIndex) #Main .boxContent p {
  line-height: 40px; }
  #PageAdmissions:not(.pageIndex) #Main .boxContent p + p {
    margin-top: 40px; }
#PageAdmissions .containerMenu .flexBox .flexItem07, #PageAdmissions .containerMenu .flexBox .flexItem08 {
  background-position: right 18px center;
  background-image: url(../img/contents/icon_link.png);
  background-size: 20px auto; }

#PageAdmissions.pageIndex #Main {
  padding-bottom: 239px; }
  #PageAdmissions.pageIndex #Main #ContBox02 .boxContent {
    padding-bottom: 198px; }

/*
学部受験生の方へ
-------------------------------------*/
#PageAdmissions.pageInfo #Main .lowerLink_item a {
  background-image: url(../img/contents/arrow_04.png);
  background-size: 12px auto; }
#PageAdmissions.pageInfo #Main .contBox {
  margin-top: 139px; }
  #PageAdmissions.pageInfo #Main .contBox .boxTitle span {
    font-size: 24px;
    color: #ff0000;
    padding-left: 10px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox {
    margin-top: 60px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_title {
      margin-bottom: 17px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_content {
      padding-left: 35px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_content p {
        line-height: 26px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_content ul li + li {
        margin-top: 8px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent .borderTitle {
    margin-bottom: 20px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent .square {
    position: relative;
    line-height: 28px;
    padding-left: 16px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .square strong {
      font-weight: bold; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .square:before {
      position: absolute;
      content: '';
      width: 3px;
      height: 3px;
      background-color: #000;
      top: 12px;
      left: 4px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .square + p:not(.square) {
      line-height: 26px;
      padding-left: 28px;
      margin-top: 8px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .square + ul {
      padding-left: 35px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent h5.square {
    font-size: 18px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent h5.square:before {
      top: 12px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent p strong {
    display: inline-block;
    font-weight: bold; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent p strong.red {
      color: #ff0000; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent p.remark {
    font-size: 15px;
    line-height: 26px;
    padding-left: 18px;
    margin-left: 40px;
    position: relative; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent p.remark:before {
      position: absolute;
      content: '※';
      top: 0;
      left: 0; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent p.remark + .remark {
      margin-top: 10px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent p + h5.square {
    margin-top: 19px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent p + ul {
    margin-top: 8px; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent strong {
    font-weight: bold; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink {
    display: flex;
    width: 100%;
    background: url(../img/contents/arrow_02.png) no-repeat right 30px center;
    background-size: 10px auto;
    line-height: 27px;
    padding: 33px 40px 39px;
    margin: 29px 0 30px;
    border: solid #e9e9e9;
    border-width: 1px 0 1px 0; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink span {
      display: inline-block;
      background: url(../img/contents/icon_pdf.png) no-repeat right center;
      background-size: 18px auto;
      padding-right: 32px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink small {
      display: inline-block;
      color: #9d9d9d;
      margin-left: 47px; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink:hover span {
      color: #009a6b; }
    #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink:after {
      display: none; }
  #PageAdmissions.pageInfo #Main .contBox .boxContent table {
    margin: 22px 0 29px; }
#PageAdmissions.pageInfo #Main #ContBox01 {
  margin-top: 96px; }
  #PageAdmissions.pageInfo #Main #ContBox01 .boxContent {
    padding-top: 76px; }
    #PageAdmissions.pageInfo #Main #ContBox01 .boxContent figure img {
      width: 100%; }
#PageAdmissions.pageInfo #Main #ContBox02 .boxContent .subBox01 p + p {
  margin-top: 8px; }
#PageAdmissions.pageInfo #Main #ContBox02 .boxContent .subBox01 h5 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px; }
#PageAdmissions.pageInfo #Main #ContBox03 .boxContent {
  margin-top: 29px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent > p:not(.remark) {
    padding-left: 20px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent > ul {
    padding-left: 39px;
    margin-top: 26px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl {
    margin-top: 47px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl dt {
      width: 100%;
      background-color: #009b6b;
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.025em;
      line-height: 30px;
      text-align: center;
      padding: 18px 15px 17px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl dd {
      width: 100%;
      padding: 29px 28px 37px;
      border: solid #009b6b;
      border-width: 0 1px 1px 1px; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl dd ul {
        margin-top: 26px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox01 {
    margin-top: 76px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox01 p {
      margin-bottom: 18px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox02 p {
    line-height: 26px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox02 p + p {
      margin-top: 19px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox03 p {
    line-height: 30px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox03 table {
    margin: 33px 0 0; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 ul {
    padding-left: 39px;
    margin-bottom: 32px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 ul li strong {
      font-weight: bold; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 ul li strong.red {
        display: block;
        color: #ff0000;
        margin-top: 10px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 ul li + li {
      margin-top: 10px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox05 p {
    line-height: 30px;
    margin-bottom: 21px; }
  #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox05 ul li + li {
    margin-top: 10px; }
#PageAdmissions.pageInfo #Main #ContBox04 {
  margin-bottom: 196px; }
  #PageAdmissions.pageInfo #Main #ContBox04 .boxContent .square {
    margin-top: 40px; }
  #PageAdmissions.pageInfo #Main #ContBox04 .boxContent ul {
    padding-left: 35px; }
#PageAdmissions.pageInfo #Main #ContainerMenu .flexBox .flexItem01 {
  background-color: #e1f4ee; }

/*
教育学部のポリシー
-------------------------------------*/
#PageAdmissions.pagePurpose #Main .contBox {
  margin-top: 96px; }
  #PageAdmissions.pagePurpose #Main .contBox .boxHead {
    margin-bottom: 30px; }
  #PageAdmissions.pagePurpose #Main .contBox .boxContent {
    padding-left: 22px; }
    #PageAdmissions.pagePurpose #Main .contBox .boxContent ul, #PageAdmissions.pagePurpose #Main .contBox .boxContent ol {
      margin-top: 40px; }
      #PageAdmissions.pagePurpose #Main .contBox .boxContent ul li, #PageAdmissions.pagePurpose #Main .contBox .boxContent ol li {
        line-height: 36px; }
        #PageAdmissions.pagePurpose #Main .contBox .boxContent ul li + li, #PageAdmissions.pagePurpose #Main .contBox .boxContent ol li + li {
          margin-top: 20px; }
    #PageAdmissions.pagePurpose #Main .contBox .boxContent ul li:before {
      top: 15px; }
#PageAdmissions.pagePurpose #Main #ContBox01 {
  margin-top: 39px; }
#PageAdmissions.pagePurpose #Main #ContBox03 {
  margin-bottom: 196px; }
#PageAdmissions.pagePurpose #Main #ContainerMenu .flexBox .flexItem05 {
  background-color: #e1f4ee; }

/*
教育内容
-------------------------------------*/
#PageAdmissions.pageEducation #Main .lowerLink_item a {
  background-image: url(../img/contents/arrow_04.png);
  background-size: 12px auto; }
#PageAdmissions.pageEducation #Main .contBox {
  margin-top: 120px; }
  #PageAdmissions.pageEducation #Main .contBox .boxContent {
    padding-top: 28px; }
    #PageAdmissions.pageEducation #Main .contBox .boxContent > p {
      padding-left: 22px; }
    #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content {
      padding-top: 38px; }
      #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox {
        flex-wrap: wrap;
        background-color: #eff8f5;
        padding: 34px 45px 37px;
        border-radius: 10px; }
        #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox p {
          font-size: 18px;
          font-weight: bold;
          color: #009a6b;
          letter-spacing: 0.025em;
          line-height: 48px;
          position: relative; }
          #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox p + p {
            margin-left: 56px; }
            #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox p + p:before {
              position: absolute;
              content: '／';
              top: 0;
              left: -38px; }
      #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content p + p {
        margin-top: 0; }
    #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox01 {
      margin-top: 84px; }
    #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox02 {
      margin-top: 57px; }
#PageAdmissions.pageEducation #Main #ContBox01 {
  margin-top: 99px; }
#PageAdmissions.pageEducation #Main #ContBox03 {
  margin-bottom: 200px; }
#PageAdmissions.pageEducation #Main #ContainerMenu .flexBox .flexItem06 {
  background-color: #e1f4ee; }

/*
取得できる資格
-------------------------------------*/
#PageAdmissions.pageObtain #Main #ContBox01 {
  margin-bottom: 188px; }
  #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox {
    margin-top: 86px; }
    #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox_content {
      padding-top: 28px; }
      #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox_content p {
        padding-left: 21px; }
      #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox_content img {
        width: 400px;
        height: auto;
        float: right;
        margin-left: 50px; }
    #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox:after {
      display: block;
      content: '';
      clear: both; }
  #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox01 {
    margin-top: 48px; }
#PageAdmissions.pageObtain #Main #ContainerMenu .flexBox .flexItem09 {
  background-color: #e1f4ee; }

/*
卒業後の進路
-------------------------------------*/
#PageAdmissions.pageAfter #Main #ContBox01 {
  margin-top: 39px;
  margin-bottom: 188px; }
  #PageAdmissions.pageAfter #Main #ContBox01 .boxContent {
    padding-top: 28px; }
    #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox01 img {
      float: right;
      margin-left: 83px; }
    #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox01:after {
      display: block;
      content: '';
      clear: both; }
    #PageAdmissions.pageAfter #Main #ContBox01 .boxContent > p {
      padding-left: 21px; }
    #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02 {
      background-color: #eff8f5;
      padding: 38px 49px 34px;
      margin-top: 98px;
      border-radius: 10px; }
      #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02:before {
        display: block;
        content: '';
        clear: both; }
      #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02 h4 {
        font-size: 22px;
        font-weight: bold;
        color: #009a6b;
        letter-spacing: .025em;
        line-height: 30px;
        margin-bottom: 18px; }
      #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02 span {
        display: block;
        font-size: 15px;
        line-height: 30px;
        padding-left: 18px;
        margin-top: 30px;
        position: relative; }
        #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02 span:before {
          position: absolute;
          content: '※';
          top: 0;
          left: 0; }
#PageAdmissions.pageAfter #Main #ContainerMenu .flexBox .flexItem10 {
  background-color: #e1f4ee; }

/*
大学院受験生・研究者の方へ
-------------------------------------*/
#PageGraduate #MainImg #MainImgInner figure, #PageResearch #MainImg #MainImgInner figure, #PageEducation #MainImg #MainImgInner figure, #PageStaffList #MainImg #MainImgInner figure {
  background-image: url(../img/contents/graduate/mainimg.jpg); }
#PageGraduate #Main, #PageResearch #Main, #PageEducation #Main, #PageStaffList #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PageGraduate #Main .containerMenu .boxHead, #PageResearch #Main .containerMenu .boxHead, #PageEducation #Main .containerMenu .boxHead, #PageStaffList #Main .containerMenu .boxHead {
    margin-bottom: 40px; }
    #PageGraduate #Main .containerMenu .boxHead h3, #PageResearch #Main .containerMenu .boxHead h3, #PageEducation #Main .containerMenu .boxHead h3, #PageStaffList #Main .containerMenu .boxHead h3 {
      display: inline-block;
      font-size: 36px;
      line-height: 44px;
      padding-left: 0; }
      #PageGraduate #Main .containerMenu .boxHead h3:before, #PageResearch #Main .containerMenu .boxHead h3:before, #PageEducation #Main .containerMenu .boxHead h3:before, #PageStaffList #Main .containerMenu .boxHead h3:before {
        width: 50px;
        height: 8px;
        top: 10px;
        left: auto;
        right: -83px; }
  #PageGraduate #Main .containerMenu .flexBox .flexItem06, #PageResearch #Main .containerMenu .flexBox .flexItem06, #PageEducation #Main .containerMenu .flexBox .flexItem06, #PageStaffList #Main .containerMenu .flexBox .flexItem06 {
    background-position: right 18px center;
    background-image: url(../img/contents/icon_link.png);
    background-size: 20px auto; }
  #PageGraduate #Main .containerMenu02, #PageResearch #Main .containerMenu02, #PageEducation #Main .containerMenu02, #PageStaffList #Main .containerMenu02 {
    margin-top: 117px; }
    #PageGraduate #Main .containerMenu02 .flexBox .flexItem03, #PageResearch #Main .containerMenu02 .flexBox .flexItem03, #PageEducation #Main .containerMenu02 .flexBox .flexItem03, #PageStaffList #Main .containerMenu02 .flexBox .flexItem03 {
      background-position: right 18px center;
      background-image: url(../img/contents/icon_link.png);
      background-size: 20px auto; }

/*
大学院受験生の方へ
-------------------------------------*/
#PageGraduate.pageInfo #Main .lowerLink_item a {
  background-image: url(../img/contents/arrow_04.png);
  background-size: 12px auto; }
#PageGraduate.pageInfo #Main .contBox {
  margin-top: 139px; }
  #PageGraduate.pageInfo #Main .contBox .boxTitle span {
    font-size: 24px;
    color: #ff0000;
    padding-left: 10px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .lowerLink {
    width: 100%;
    margin-top: 15px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .lowerLink_item a {
      display: flex;
      line-height: 26px;
      align-items: center;
      padding: 17px 20px 21px 0; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .contSubBox {
    margin-top: 100px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .subBox {
    margin-top: 60px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_title {
      margin-bottom: 17px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content {
      padding-left: 35px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content p {
        line-height: 26px; }
        #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content p.square {
          margin-top: 26px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content > ul {
        margin-top: 26px;
        padding-left: 39px; }
        #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content > ul li + li {
          margin-top: 8px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .subBox01 {
    margin-top: 40px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .borderTitle {
    margin-bottom: 20px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .square {
    position: relative;
    line-height: 28px;
    padding-left: 16px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .square strong {
      font-weight: bold; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .square:before {
      position: absolute;
      content: '';
      width: 3px;
      height: 3px;
      background-color: #000;
      top: 12px;
      left: 4px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .square + p:not(.square) {
      line-height: 26px !important;
      padding-left: 28px;
      margin-top: 8px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .square + ul {
      padding-left: 35px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .red {
    color: #f00; }
  #PageGraduate.pageInfo #Main .contBox .boxContent h5 {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 15px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent h5.square {
      font-size: 18px;
      font-weight: 500;
      margin: 0; }
      #PageGraduate.pageInfo #Main .contBox .boxContent h5.square:before {
        top: 12px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent h5 + p {
      line-height: 40px !important; }
  #PageGraduate.pageInfo #Main .contBox .boxContent p strong {
    display: inline-block;
    font-weight: bold; }
    #PageGraduate.pageInfo #Main .contBox .boxContent p strong.red {
      color: #ff0000; }
  #PageGraduate.pageInfo #Main .contBox .boxContent p.remark {
    font-size: 15px;
    line-height: 26px;
    padding-left: 18px;
    margin-left: 40px;
    position: relative; }
    #PageGraduate.pageInfo #Main .contBox .boxContent p.remark:before {
      position: absolute;
      content: '※';
      top: 0;
      left: 0; }
    #PageGraduate.pageInfo #Main .contBox .boxContent p.remark + .remark {
      margin-top: 10px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent p + h5.square {
    margin-top: 19px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent p + ul {
    margin-top: 8px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent p + ol {
    margin-top: 18px; }
  #PageGraduate.pageInfo #Main .contBox .boxContent strong {
    font-weight: bold; }
  #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink {
    display: flex;
    align-items: center;
    width: 100%;
    background: url(../img/contents/arrow_02.png) no-repeat right 30px center;
    background-size: 10px auto;
    line-height: 27px;
    padding: 33px 60px 39px 40px;
    margin: 29px 0 30px;
    border: solid #e9e9e9;
    border-width: 1px 0 1px 0; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink span {
      display: inline-block;
      background: url(../img/contents/icon_pdf.png) no-repeat right center;
      background-size: 18px auto;
      padding-right: 32px;
      margin-right: 47px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink small {
      display: inline-block;
      color: #9d9d9d;
      white-space: nowrap; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink:hover span {
      color: #009a6b; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink:after {
      display: none; }
  #PageGraduate.pageInfo #Main .contBox .boxContent table {
    margin: 22px 0 29px; }
#PageGraduate.pageInfo #Main #ContBox01 {
  margin-top: 96px; }
  #PageGraduate.pageInfo #Main #ContBox01 .boxContent {
    padding-top: 76px; }
    #PageGraduate.pageInfo #Main #ContBox01 .boxContent figure img {
      width: 100%; }
#PageGraduate.pageInfo #Main #ContBox02 .boxContent ul + h5 {
  margin-top: 50px; }
#PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox02 .subBox02 p, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox02 .subBox04 p, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox05 .subBox02 p, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox05 .subBox04 p {
  line-height: 40px; }
#PageGraduate.pageInfo #Main #ContBox03 {
  margin-bottom: 188px; }
  #PageGraduate.pageInfo #Main #ContBox03 .boxContent .subBox {
    margin-top: 45px; }
#PageGraduate.pageInfo #Main .containerMenu01 .flexBox .flexItem01 {
  background-color: #e1f4ee; }

/*
教育学研究科のポリシー
-------------------------------------*/
#PageGraduate.pagePurpose #Main .contBox {
  margin-top: 96px; }
  #PageGraduate.pagePurpose #Main .contBox .boxContent {
    padding-top: 28px;
    padding-left: 21px; }
    #PageGraduate.pagePurpose #Main .contBox .boxContent p {
      line-height: 40px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent p strong {
        display: block;
        font-weight: bold; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent p + p {
        margin-top: 40px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent p + ol {
        margin-top: 40px; }
    #PageGraduate.pagePurpose #Main .contBox .boxContent ol li {
      line-height: 36px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent ol li p {
        line-height: 36px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent ol li + li {
        margin-top: 20px; }
    #PageGraduate.pagePurpose #Main .contBox .boxContent ol + p {
      margin-top: 40px; }
    #PageGraduate.pagePurpose #Main .contBox .boxContent dl {
      margin: 20px 0 40px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent dl dt {
        width: 100%;
        background-color: #009b6b;
        font-weight: bold;
        color: #fff;
        letter-spacing: .025em;
        line-height: 30px;
        padding: 18px 15px 17px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent dl dd {
        width: 100%;
        padding: 29px 28px 37px;
        border: solid #009b6b;
        border-width: 0 1px 1px 1px; }
#PageGraduate.pagePurpose #Main .contSubBox {
  width: calc(100% + 21px);
  margin-left: -21px;
  margin-top: 84px; }
  #PageGraduate.pagePurpose #Main .contSubBox_content {
    padding-top: 29px;
    padding-left: 22px; }
  #PageGraduate.pagePurpose #Main .contSubBox + dl {
    margin-top: 60px !important; }
#PageGraduate.pagePurpose #Main .subBox {
  margin-top: 40px; }
  #PageGraduate.pagePurpose #Main .subBox_title {
    margin-bottom: 15px; }
  #PageGraduate.pagePurpose #Main .subBox_content {
    padding-left: 29px; }
#PageGraduate.pagePurpose #Main #ContBox01 {
  margin-top: 39px; }
#PageGraduate.pagePurpose #Main #ContBox03 {
  margin-bottom: 188px; }
#PageGraduate.pagePurpose #Main .containerMenu01 .flexBox .flexItem04,
#PageGraduate.pagePurpose #Main .containerMenu02 .flexBox .flexItem01 {
  background-color: #e1f4ee; }

/*
研究内容
-------------------------------------*/
#PageResearch #Main p, #PageEducation #Main p {
  line-height: 40px; }
  #PageResearch #Main p + p, #PageEducation #Main p + p {
    margin-top: 40px; }
#PageResearch #Main .categoryList h4, #PageEducation #Main .categoryList h4 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.025em;
  line-height: 44px;
  margin: 11px 0 35px; }
#PageResearch #Main .categoryList .flexBox, #PageEducation #Main .categoryList .flexBox {
  flex-wrap: wrap;
  margin-bottom: -30px; }
  #PageResearch #Main .categoryList .flexBox .flexItem, #PageEducation #Main .categoryList .flexBox .flexItem {
    display: flex;
    align-items: center;
    width: calc( (100% - 60px) / 3 );
    height: 80px;
    background: #009b6b url(../img/contents/arrow_01.png) no-repeat right 18px center;
    background-size: 8px auto;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    padding: 0 44px 0 24px;
    margin: 0 30px 30px 0; }
    #PageResearch #Main .categoryList .flexBox .flexItem p, #PageEducation #Main .categoryList .flexBox .flexItem p {
      line-height: 26px !important;
      padding-left: 0 !important; }
    #PageResearch #Main .categoryList .flexBox .flexItem small, #PageEducation #Main .categoryList .flexBox .flexItem small {
      display: block;
      font-size: 14px; }
    #PageResearch #Main .categoryList .flexBox .flexItem:nth-of-type(3n), #PageEducation #Main .categoryList .flexBox .flexItem:nth-of-type(3n) {
      margin-right: 0; }
    #PageResearch #Main .categoryList .flexBox .flexItem:hover, #PageEducation #Main .categoryList .flexBox .flexItem:hover {
      background-color: #22ab38; }
  #PageResearch #Main .categoryList .flexBox span.flexItem, #PageEducation #Main .categoryList .flexBox span.flexItem {
    background-image: none; }
#PageResearch #Main .containerMenu01 .flexBox .flexItem05,
#PageResearch #Main .containerMenu02 .flexBox .flexItem02, #PageEducation #Main .containerMenu01 .flexBox .flexItem05,
#PageEducation #Main .containerMenu02 .flexBox .flexItem02 {
  background-color: #e1f4ee; }

#PageResearch.pageIndex #Main #ContBox01 {
  margin-top: 39px;
  margin-bottom: 191px; }
  #PageResearch.pageIndex #Main #ContBox01 .boxContent {
    padding-top: 28px; }
    #PageResearch.pageIndex #Main #ContBox01 .boxContent > p {
      padding-left: 21px; }
    #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox {
      margin-top: 60px; }
      #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content {
        padding-top: 29px;
        padding-left: 21px; }
        #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table {
          margin: 56px 0 14px; }
          #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table th {
            padding-top: 17px;
            padding-bottom: 20px; }
            #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table th:nth-of-type(1) {
              width: 33.54838709677419%; }
            #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table th:nth-of-type(2) {
              width: 32.47311827956989%; }
            #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table th:nth-of-type(3) {
              width: 33.87096774193548%; }
          #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table td {
            padding-top: 10px;
            padding-bottom: 9px; }
    #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox03 .contSubBox_content {
      padding-left: 0; }

/*
研究内容 教育学環専攻
-------------------------------------*/
#PageEducation.pageIndex #Main #ContBox01 {
  margin-top: 39px;
  margin-bottom: 195px; }
  #PageEducation.pageIndex #Main #ContBox01 .boxHead {
    margin-bottom: 28px; }

/*
研究内容 教育学環専攻 カテゴリ
-------------------------------------*/
#PageEducation.pageCategory #Main #ContBox01 {
  margin-top: 45px; }
  #PageEducation.pageCategory #Main #ContBox01 .boxContent {
    padding: 0 21px 0 22px; }
    #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox .wp-caption {
      width: calc( (100% - 60px) / 3 );
      float: left;
      margin-right: 30px; }
      #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox .wp-caption:nth-of-type(3) {
        margin-right: 0; }
    #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox figure {
      position: relative; }
    #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox img {
      width: 100% !important;
      height: auto;
      margin: 0 !important;
      position: absolute;
      top: 0;
      left: 0; }
    #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox p {
      margin-top: 15px; }
#PageEducation.pageCategory #Main #ContBox02 {
  margin-top: 113px;
  margin-bottom: 195px; }
  #PageEducation.pageCategory #Main #ContBox02 .boxContent {
    padding-top: 60px; }
    #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox {
      margin-bottom: 203px; }
      #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content {
        padding: 55px 21px 0 18px; }
        #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox {
          justify-content: space-between;
          margin-bottom: 37px; }
          #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox figure {
            width: 280px;
            height: 380px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover; }
          #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div {
            width: calc(100% - 341px); }
            #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div h4 {
              font-size: 19px;
              font-weight: bold;
              color: #009b6b;
              letter-spacing: 0;
              line-height: 29px;
              background: #eff8f5 url(../img/contents/icon_13.png) no-repeat left 27px top 26px;
              background-size: 20px auto;
              padding: 20px 15px 19px 70px;
              margin-bottom: 17px;
              border-radius: 10px; }
            #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div ul {
              margin-top: 19px;
              padding-left: 15px; }
              #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div ul li + li {
                margin-top: 14px; }

/*
教員一覧
-------------------------------------*/
#PageStaffList #Main .categoryList {
  margin-top: 173px;
  margin-bottom: 196px; }
  #PageStaffList #Main .categoryList h4 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.025em;
    line-height: 44px;
    margin: 11px 0 35px; }
  #PageStaffList #Main .categoryList .flexBox {
    flex-wrap: wrap;
    margin-bottom: -30px; }
    #PageStaffList #Main .categoryList .flexBox .flexItem {
      display: flex;
      align-items: center;
      width: calc( (100% - 60px) / 3 );
      height: 80px;
      background: #009b6b url(../img/contents/arrow_01.png) no-repeat right 18px center;
      background-size: 8px auto;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      padding: 0 44px 0 24px;
      margin: 0 30px 30px 0; }
      #PageStaffList #Main .categoryList .flexBox .flexItem p {
        line-height: 26px !important;
        padding-left: 0 !important; }
      #PageStaffList #Main .categoryList .flexBox .flexItem small {
        display: block;
        font-size: 14px; }
      #PageStaffList #Main .categoryList .flexBox .flexItem:nth-of-type(3n) {
        margin-right: 0; }
      #PageStaffList #Main .categoryList .flexBox .flexItem:hover {
        background-color: #22ab38; }
#PageStaffList #Main .containerMenu01 .flexBox .flexItem07,
#PageStaffList #Main .containerMenu02 .flexBox .flexItem04 {
  background-color: #e1f4ee; }

#PageStaffList.pageIndex #Main .contBox {
  margin-top: 80px; }
  #PageStaffList.pageIndex #Main .contBox .leaderImage {
    margin-bottom: 49px; }
    #PageStaffList.pageIndex #Main .contBox .leaderImage img {
      width: 100%; }
  #PageStaffList.pageIndex #Main .contBox .boxContent {
    margin-top: 34px; }
    #PageStaffList.pageIndex #Main .contBox .boxContent .teacher {
      flex-wrap: wrap;
      justify-content: space-between; }
      #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item {
        width: calc( (100% - 30px) / 2 );
        min-height: 210px;
        margin-bottom: 30px;
        box-shadow: 0 0 13px rgba(74, 74, 74, 0.08); }
        #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item a {
          display: flex;
          width: 100%;
          height: 100%;
          position: relative; }
          #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item a:after {
            position: absolute;
            content: 'View';
            width: 100%;
            height: 100%;
            font-family: 'EB Garamond', serif;
            font-size: 18px;
            color: #fff;
            letter-spacing: 0.05em;
            line-height: 20px;
            background-color: rgba(42, 142, 111, 0.64);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.5s ease; }
          #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item a:hover:after {
            opacity: 1; }
        #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .photo {
          width: 174px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto; }
        #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .text {
          width: calc(100% - 174px);
          padding: 24px 20px 20px 24px;
          border: solid #f2f2f2;
          border-width: 1px 1px 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .text .name {
            font-size: 22px;
            font-weight: bold;
            line-height: 30px; }
          #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .text .domain {
            width: 100%;
            height: auto;
            font-size: 14px;
            font-weight: bold;
            color: #009b6b;
            line-height: 20px;
            text-align: justify;
            background: #eff8f5 url(../img/contents/icon_13.png) no-repeat left 19px top 18px;
            background-size: 17px auto;
            padding: 15px 16px 16px 46px;
            border-radius: 5px; }
#PageStaffList.pageIndex #Main #ContBox01 {
  margin-top: 30px; }

/*
教員一覧 カテゴリ
-------------------------------------*/
#PageStaffList.pageCategory #Main .contBox .boxContent {
  padding-top: 60px; }
  #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content {
    padding: 55px 21px 0 18px; }
    #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox {
      justify-content: space-between;
      margin-bottom: 37px; }
      #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox figure {
        width: 280px;
        height: 380px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto; }
      #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox > div {
        width: calc(100% - 341px); }
        #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox > div h4 {
          font-size: 19px;
          font-weight: bold;
          color: #009b6b;
          letter-spacing: 0;
          line-height: 29px;
          background: #eff8f5 url(../img/contents/icon_13.png) no-repeat left 27px top 26px;
          background-size: 20px auto;
          padding: 20px 15px 19px 70px;
          margin-bottom: 17px;
          border-radius: 10px; }
        #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox > div p {
          line-height: 40px; }
        #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox > div ul {
          margin-top: 19px;
          padding-left: 15px; }
          #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox > div ul li + li {
            margin-top: 14px; }
  #PageStaffList.pageCategory #Main .contBox .boxContent .subBox + .subBox {
    margin-top: 100px; }
#PageStaffList.pageCategory #Main #ContBox01 {
  margin-top: 39px; }
#PageStaffList.pageCategory #Main .categoryList {
  margin-top: 203px; }

/*
取得できる資格
-------------------------------------*/
#PageGraduate.pageObtain #Main #ContBox01 {
  margin-bottom: 188px; }
  #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox {
    margin-top: 86px; }
    #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox_content {
      padding-top: 28px; }
      #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox_content p {
        line-height: 40px;
        padding-left: 21px; }
      #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox_content img {
        width: 400px;
        height: auto;
        float: right;
        margin-left: 50px; }
    #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox:after {
      display: block;
      content: '';
      clear: both; }
  #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox01 {
    margin-top: 48px; }
#PageGraduate.pageObtain #Main .containerMenu01 .flexBox .flexItem08 {
  background-color: #e1f4ee; }

/*
取得できる資格 公認心理師
-------------------------------------*/
#PageGraduate.pageKouninup #Main #ContBox01 {
  margin-top: 39px;
  margin-bottom: 199px; }
  #PageGraduate.pageKouninup #Main #ContBox01 .boxContent {
    padding-top: 28px; }
    #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p {
      line-height: 40px;
      padding: 0 21px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p.remark {
        position: relative;
        padding: 0 21px 0 39px;
        line-height: 30px; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p.remark:before {
          position: absolute;
          content: '※';
          top: 0;
          left: 21px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p + p {
        margin-top: 16px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p + ol {
        margin-top: 30px; }
    #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ol, #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ul {
      padding: 0 21px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ol li, #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ul li {
        line-height: 30px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ol + p, #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ul + p {
        margin-top: 30px; }
    #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .borderTitle p {
      padding-left: 0; }
    #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox {
      margin-top: 68px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content {
        padding-top: 34px; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div {
          padding-left: 21px;
          margin-top: 47px; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div:first-of-type {
            margin-top: 0; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div + p {
            margin: 68px 0 -34px; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content h4 {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          padding-left: 19px;
          margin-bottom: 18px;
          position: relative; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content h4 a {
            color: #009a6b;
            text-decoration: underline; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content h4:before {
            position: absolute;
            content: '';
            width: 7px;
            height: 7px;
            background-color: #009b6b;
            border-radius: 50%;
            top: 11px;
            left: 0; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content ol.maru {
          margin: 0; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content ol.maru li:before {
            top: 6px; }
#PageGraduate.pageKouninup #Main .containerMenu01 .flexBox .flexItem08 {
  background-color: #e1f4ee; }

/*
修了後の進路
-------------------------------------*/
#PageGraduate.pageAfter #Main #ContBox01 {
  margin-top: 39px;
  margin-bottom: 183px; }
  #PageGraduate.pageAfter #Main #ContBox01 .boxContent {
    padding-top: 28px; }
    #PageGraduate.pageAfter #Main #ContBox01 .boxContent img {
      float: right;
      margin-left: 82px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent img:after {
        display: block;
        content: '';
        clear: both; }
    #PageGraduate.pageAfter #Main #ContBox01 .boxContent p {
      line-height: 40px;
      padding-left: 22px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent p.remark {
        position: relative;
        padding-left: 41px; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent p.remark:before {
          position: absolute;
          content: '※';
          top: 0;
          left: 22px; }
    #PageGraduate.pageAfter #Main #ContBox01 .boxContent .contSubBox {
      margin-top: 84px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent .contSubBox_content {
        padding-top: 36px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent .contSubBox:last-of-type {
        margin-bottom: 76px; }
    #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content {
      margin-top: 15px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul {
        padding-left: 39px; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul li strong {
          display: block;
          font-size: 20px;
          font-weight: bold;
          color: #343434;
          margin-bottom: 12px; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul li p {
          padding-left: 0; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul li + li {
          margin-top: 23px; }
#PageGraduate.pageAfter #Main .containerMenu01 .flexBox .flexItem09 {
  background-color: #e1f4ee; }

/*
一般の皆様へ
-------------------------------------*/
#PageVisitors.pageIndex #MainImg #MainImgInner figure {
  background-image: url(../img/contents/visitors/mainimg.jpg); }
#PageVisitors.pageIndex #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PageVisitors.pageIndex #Main #ContainerMenu .flexBox .flexItem {
    background-image: url(../img/contents/icon_link.png);
    background-position: right 18px center;
    background-size: 20px auto; }

/*
在校生・卒業生
-------------------------------------*/
#PageTowards #MainImg #MainImgInner figure {
  background-image: url(../img/contents/towards/mainimg.jpg); }
#PageTowards #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PageTowards #Main #ContainerMenu .flexBox .flexItem05, #PageTowards #Main #ContainerMenu .flexBox .flexItem08, #PageTowards #Main #ContainerMenu .flexBox .flexItem10, #PageTowards #Main #ContainerMenu .flexBox .flexItem11 {
    background-image: url(../img/contents/icon_link.png);
    background-position: right 18px center;
    background-size: 20px auto; }

/*
紀要編集委員会からのお知らせ
-------------------------------------*/
#PageTowards.pageSubmission #Main .contBox {
  margin-top: 96px; }
  #PageTowards.pageSubmission #Main .contBox:first-of-type {
    margin-top: 39px; }
  #PageTowards.pageSubmission #Main .contBox p {
    line-height: 40px; }
    #PageTowards.pageSubmission #Main .contBox p + p {
      margin-top: 40px; }
  #PageTowards.pageSubmission #Main .contBox .boxContent {
    padding-top: 28px; }
    #PageTowards.pageSubmission #Main .contBox .boxContent p {
      padding-left: 21px; }
#PageTowards.pageSubmission #Main #ContainerMenu {
  margin-top: 183px; }
  #PageTowards.pageSubmission #Main #ContainerMenu .flexBox .flexItem01 {
    background-color: #e1f4ee; }

/*
証明書の請求方法
-------------------------------------*/
#PageTowards.pageCertificate #Main .lowerLink {
  margin-top: 15px; }
  #PageTowards.pageCertificate #Main .lowerLink_item a {
    display: flex;
    line-height: 26px;
    align-items: center;
    padding: 17px 20px 21px 0;
    background-image: url(../img/contents/arrow_04.png);
    background-size: 12px auto; }
#PageTowards.pageCertificate #Main .contBox {
  margin-top: 96px; }
  #PageTowards.pageCertificate #Main .contBox:first-of-type {
    margin-top: 39px; }
  #PageTowards.pageCertificate #Main .contBox p {
    line-height: 40px; }
    #PageTowards.pageCertificate #Main .contBox p + p, #PageTowards.pageCertificate #Main .contBox p + ol, #PageTowards.pageCertificate #Main .contBox p + ul {
      margin-top: 40px; }
  #PageTowards.pageCertificate #Main .contBox .boxContent {
    padding-top: 28px; }
    #PageTowards.pageCertificate #Main .contBox .boxContent p {
      padding-left: 21px; }
    #PageTowards.pageCertificate #Main .contBox .boxContent > ol, #PageTowards.pageCertificate #Main .contBox .boxContent > ul {
      margin-left: 21px; }
      #PageTowards.pageCertificate #Main .contBox .boxContent > ol + ul, #PageTowards.pageCertificate #Main .contBox .boxContent > ul + ul {
        margin-top: 40px; }
    #PageTowards.pageCertificate #Main .contBox .boxContent ol li ul {
      margin-top: 10px; }
  #PageTowards.pageCertificate #Main .contBox .contSubBox01 {
    margin-top: 84px; }
    #PageTowards.pageCertificate #Main .contBox .contSubBox01 .borderTitle {
      margin-bottom: 20px; }
#PageTowards.pageCertificate #Main #ContBox01 {
  margin-top: 96px; }
#PageTowards.pageCertificate #Main #ContainerMenu {
  margin-top: 183px; }
  #PageTowards.pageCertificate #Main #ContainerMenu .flexBox .flexItem02 {
    background-color: #e1f4ee; }

/*
目的とポリシー（教育学部）
-------------------------------------*/
/*
目的とポリシー（教育学研究科）
-------------------------------------*/
#PageTowards.pagePurposeU #Main .pageTitle + figure,
#PageTowards.pagePurposeG #Main .pageTitle + figure {
  margin: 30px auto 0;
  width: calc(100% - 119px);
  max-width: 1000px; }
  #PageTowards.pagePurposeU #Main .pageTitle + figure img,
  #PageTowards.pagePurposeG #Main .pageTitle + figure img {
    width: 100%;
    height: auto; }
#PageTowards.pagePurposeU #Main .contBox,
#PageTowards.pagePurposeG #Main .contBox {
  margin-top: 96px; }
  #PageTowards.pagePurposeU #Main .contBox .boxContent,
  #PageTowards.pagePurposeG #Main .contBox .boxContent {
    padding-top: 28px;
    padding-left: 21px; }
    #PageTowards.pagePurposeU #Main .contBox .boxContent p,
    #PageTowards.pagePurposeG #Main .contBox .boxContent p {
      line-height: 40px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent p strong,
      #PageTowards.pagePurposeG #Main .contBox .boxContent p strong {
        display: block;
        font-weight: bold; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent p + p,
      #PageTowards.pagePurposeG #Main .contBox .boxContent p + p {
        margin-top: 40px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent p + ol,
      #PageTowards.pagePurposeG #Main .contBox .boxContent p + ol {
        margin-top: 40px; }
    #PageTowards.pagePurposeU #Main .contBox .boxContent ul li,
    #PageTowards.pagePurposeG #Main .contBox .boxContent ul li {
      line-height: 36px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent ul li:before,
      #PageTowards.pagePurposeG #Main .contBox .boxContent ul li:before {
        top: 14px; }
    #PageTowards.pagePurposeU #Main .contBox .boxContent ol li,
    #PageTowards.pagePurposeG #Main .contBox .boxContent ol li {
      line-height: 36px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent ol li p,
      #PageTowards.pagePurposeG #Main .contBox .boxContent ol li p {
        line-height: 36px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent ol li + li,
      #PageTowards.pagePurposeG #Main .contBox .boxContent ol li + li {
        margin-top: 20px; }
    #PageTowards.pagePurposeU #Main .contBox .boxContent ol + p,
    #PageTowards.pagePurposeG #Main .contBox .boxContent ol + p {
      margin-top: 40px; }
    #PageTowards.pagePurposeU #Main .contBox .boxContent dl,
    #PageTowards.pagePurposeG #Main .contBox .boxContent dl {
      margin: 20px 0 40px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent dl dt,
      #PageTowards.pagePurposeG #Main .contBox .boxContent dl dt {
        width: 100%;
        background-color: #009b6b;
        font-weight: bold;
        color: #fff;
        letter-spacing: .025em;
        line-height: 30px;
        padding: 18px 15px 17px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent dl dd,
      #PageTowards.pagePurposeG #Main .contBox .boxContent dl dd {
        width: 100%;
        padding: 29px 28px 37px;
        border: solid #009b6b;
        border-width: 0 1px 1px 1px; }
#PageTowards.pagePurposeU #Main .contSubBox,
#PageTowards.pagePurposeG #Main .contSubBox {
  width: calc(100% + 21px);
  margin-left: -21px;
  margin-top: 84px; }
  #PageTowards.pagePurposeU #Main .contSubBox_content,
  #PageTowards.pagePurposeG #Main .contSubBox_content {
    padding-top: 29px;
    padding-left: 22px; }
  #PageTowards.pagePurposeU #Main .contSubBox + dl,
  #PageTowards.pagePurposeG #Main .contSubBox + dl {
    margin-top: 60px !important; }
#PageTowards.pagePurposeU #Main .subBox,
#PageTowards.pagePurposeG #Main .subBox {
  margin-top: 40px; }
  #PageTowards.pagePurposeU #Main .subBox_title,
  #PageTowards.pagePurposeG #Main .subBox_title {
    margin-bottom: 15px; }
  #PageTowards.pagePurposeU #Main .subBox_content,
  #PageTowards.pagePurposeG #Main .subBox_content {
    padding-left: 29px; }
#PageTowards.pagePurposeU #Main #ContBox01,
#PageTowards.pagePurposeG #Main #ContBox01 {
  margin-top: 39px; }
#PageTowards.pagePurposeU #Main #ContBox03,
#PageTowards.pagePurposeG #Main #ContBox03 {
  margin-bottom: 188px; }
#PageTowards.pagePurposeU #Main .containerMenu01 .flexBox .flexItem04,
#PageTowards.pagePurposeU #Main .containerMenu02 .flexBox .flexItem01,
#PageTowards.pagePurposeG #Main .containerMenu01 .flexBox .flexItem04,
#PageTowards.pagePurposeG #Main .containerMenu02 .flexBox .flexItem01 {
  background-color: #e1f4ee; }

/*
目的とポリシー（教育学部）
-------------------------------------*/
#PageTowards.pagePurposeU #Main #ContainerMenu .flexBox .flexItem03 {
  background-color: #e1f4ee; }

/*
目的とポリシー（教育学研究科）
-------------------------------------*/
#PageTowards.pagePurposeG #Main #ContainerMenu .flexBox .flexItem04 {
  background-color: #e1f4ee; }

/*
オンラインによるICTを用いた学習・研究のために
-------------------------------------*/
#PageTowards.pageRemote #Main .contBox {
  margin-top: 96px; }
  #PageTowards.pageRemote #Main .contBox .boxContent {
    padding-top: 28px; }
    #PageTowards.pageRemote #Main .contBox .boxContent p {
      line-height: 40px; }
      #PageTowards.pageRemote #Main .contBox .boxContent p.square {
        position: relative;
        padding-left: 16px;
        line-height: 26px; }
        #PageTowards.pageRemote #Main .contBox .boxContent p.square:before {
          content: "";
          position: absolute;
          top: 12px;
          left: 4px;
          width: 3px;
          height: 3px;
          background-color: #000; }
    #PageTowards.pageRemote #Main .contBox .boxContent > p, #PageTowards.pageRemote #Main .contBox .boxContent > ul {
      padding-left: 21px; }
    #PageTowards.pageRemote #Main .contBox .boxContent > p.square {
      padding-left: 37px; }
      #PageTowards.pageRemote #Main .contBox .boxContent > p.square:before {
        left: 26px; }
      #PageTowards.pageRemote #Main .contBox .boxContent > p.square + ul {
        padding-left: 56px;
        margin-top: 8px; }
  #PageTowards.pageRemote #Main .contBox:first-of-type {
    margin-top: 39px; }
#PageTowards.pageRemote #Main .contSubBox {
  margin-top: 60px; }
  #PageTowards.pageRemote #Main .contSubBox_content {
    padding-top: 29px;
    padding-left: 21px; }
    #PageTowards.pageRemote #Main .contSubBox_content .subBox:first-of-type {
      margin-top: 0; }
    #PageTowards.pageRemote #Main .contSubBox_content .square {
      margin-top: 40px; }
      #PageTowards.pageRemote #Main .contSubBox_content .square:first-of-type {
        margin-top: 0; }
      #PageTowards.pageRemote #Main .contSubBox_content .square + ul {
        padding-left: 35px;
        margin-top: 8px; }
  #PageTowards.pageRemote #Main .contSubBox:first-of-type {
    margin-top: 30px; }
#PageTowards.pageRemote #Main .subBox {
  margin-top: 40px; }
  #PageTowards.pageRemote #Main .subBox_title {
    margin-bottom: 17px; }
  #PageTowards.pageRemote #Main .subBox_content {
    padding-left: 35px; }
#PageTowards.pageRemote #Main #ContainerMenu {
  margin-top: 183px; }
  #PageTowards.pageRemote #Main #ContainerMenu .flexBox .flexItem06 {
    background-color: #e1f4ee; }

/*
経済的支援を必要とされる学生の方へ
-------------------------------------*/
#PageTowards.pageSupport #Main .contBox {
  margin-top: 96px; }
  #PageTowards.pageSupport #Main .contBox .boxContent {
    padding-top: 28px;
    padding-left: 21px; }
    #PageTowards.pageSupport #Main .contBox .boxContent p {
      line-height: 40px; }
      #PageTowards.pageSupport #Main .contBox .boxContent p + p {
        margin-top: 40px; }
    #PageTowards.pageSupport #Main .contBox .boxContent .red {
      color: #f00; }
  #PageTowards.pageSupport #Main .contBox:first-of-type {
    margin-top: 39px; }
#PageTowards.pageSupport #Main #ContBox01 .boxContent {
  padding-top: 0; }
#PageTowards.pageSupport #Main #ContainerMenu {
  margin-top: 183px; }
  #PageTowards.pageSupport #Main #ContainerMenu .flexBox .flexItem07 {
    background-color: #e1f4ee; }

/*
教育学部サテライト利用の手引
-------------------------------------*/
#PageTowards.pageGuidance #Main .contBox {
  margin-top: 96px; }
  #PageTowards.pageGuidance #Main .contBox .boxContent {
    padding-top: 28px;
    padding-left: 21px; }
    #PageTowards.pageGuidance #Main .contBox .boxContent p {
      line-height: 40px; }
      #PageTowards.pageGuidance #Main .contBox .boxContent p + p {
        margin-top: 40px; }
      #PageTowards.pageGuidance #Main .contBox .boxContent p + ul {
        margin-top: 30px; }
      #PageTowards.pageGuidance #Main .contBox .boxContent p.remark {
        line-height: 26px;
        padding-left: 22px;
        margin-top: 10px;
        position: relative; }
        #PageTowards.pageGuidance #Main .contBox .boxContent p.remark:before {
          content: "※";
          position: absolute;
          top: 0;
          left: 0; }
    #PageTowards.pageGuidance #Main .contBox .boxContent table {
      margin: 30px 0; }
  #PageTowards.pageGuidance #Main .contBox:first-of-type {
    margin-top: 39px; }
#PageTowards.pageGuidance #Main #ContainerMenu {
  margin-top: 183px; }
  #PageTowards.pageGuidance #Main #ContainerMenu .flexBox .flexItem09 {
    background-color: #e1f4ee; }

/*
ハラスメント相談窓口等について
-------------------------------------*/
#PageTowards.pageConsultation #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PageTowards.pageConsultation #Main .contBox {
    margin-top: 96px; }
    #PageTowards.pageConsultation #Main .contBox .boxContent {
      padding-top: 28px;
      padding-left: 21px; }
      #PageTowards.pageConsultation #Main .contBox .boxContent p {
        line-height: 40px;
        margin-bottom: 15px; }
      #PageTowards.pageConsultation #Main .contBox .boxContent a[target="_blank"] {
        padding-right: 25px;
        background-image: url(../img/contents/link_03.png);
        background-repeat: no-repeat;
        background-position: right 5px center;
        background-size: 11px auto; }
  #PageTowards.pageConsultation #Main #ContBox01 {
    margin-top: 39px; }
  #PageTowards.pageConsultation #Main #ContainerMenu {
    margin-top: 100px; }

/*
NEWS
-------------------------------------*/
#PageNews #Main {
  padding-top: 109px;
  padding-bottom: 202px; }
  #PageNews #Main .catSearch_title {
    background-color: #009b6b;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.025em;
    line-height: 30px;
    padding: 19px 33px 20px; }
    #PageNews #Main .catSearch_title.click {
      background-image: url(../img/contents/open.png);
      background-repeat: no-repeat;
      background-position: right 20px center;
      background-size: 36px auto; }
      #PageNews #Main .catSearch_title.click.active {
        background-image: url(../img/contents/close.png); }
  #PageNews #Main .catSearch_content {
    padding: 30px 30px 20px;
    border: solid #009b6b;
    border-width: 0 1px 1px 1px; }
    #PageNews #Main .catSearch_content ul {
      display: flex;
      flex-wrap: wrap; }
      #PageNews #Main .catSearch_content ul li {
        font-size: 16px;
        font-weight: bold;
        color: #009b6b;
        letter-spacing: 0.05em;
        line-height: 30px;
        padding-left: 0;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative; }
        #PageNews #Main .catSearch_content ul li a {
          display: block;
          width: 100%;
          height: 100%;
          background-color: #eff8f5;
          text-decoration: none;
          border: 1px solid #eff8f5;
          padding: 4px 12px 4px 16px; }
          #PageNews #Main .catSearch_content ul li a:hover {
            background-color: #fff;
            border-color: #009b6b;
            cursor: pointer; }
          #PageNews #Main .catSearch_content ul li a.currect {
            color: #fff;
            background-color: #343434;
            border-color: #343434; }
        #PageNews #Main .catSearch_content ul li + li {
          margin-top: 0; }
        #PageNews #Main .catSearch_content ul li:before {
          display: none; }
    #PageNews #Main .catSearch_content button {
      display: block;
      width: 147px;
      height: 50px;
      background: url(../img/contents/arrow_02.png) no-repeat right 26px center;
      background-size: 8px auto;
      font-size: 16px;
      font-weight: bold;
      color: #009b6b;
      letter-spacing: 0.05em;
      line-height: 48px;
      text-align: left;
      border: 1px solid #e9e9e9;
      border-radius: 25px;
      padding-left: 32px;
      margin: 16px auto 10px; }
      #PageNews #Main .catSearch_content button:hover {
        color: #fff;
        background-color: #009b6b;
        background-image: url(../img/contents/arrow_01.png);
        border-color: #009b6b; }
  #PageNews #Main .catSearch02 {
    margin-top: 71px; }
  #PageNews #Main .contBox .boxHead {
    margin: 49px 0 48px; }
  #PageNews #Main .pageNation {
    margin-top: 98px;
    display: flex;
    background-color: #8ec21f; }
    #PageNews #Main .pageNation li {
      height: 66px;
      padding-left: 0; }
      #PageNews #Main .pageNation li span, #PageNews #Main .pageNation li p, #PageNews #Main .pageNation li a {
        display: block;
        width: 100%;
        height: 100%;
        font-family: 'EB Garamond', serif;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        letter-spacing: 0.1em;
        line-height: 66px;
        text-align: center;
        text-decoration: none;
        border-left: 1px solid #4f9404; }
        #PageNews #Main .pageNation li span.current, #PageNews #Main .pageNation li p.current, #PageNews #Main .pageNation li a.current {
          color: rgba(255, 255, 255, 0.5); }
      #PageNews #Main .pageNation li a:hover {
        background-color: #97cc26; }
      #PageNews #Main .pageNation li:not(.text) {
        width: 66px; }
      #PageNews #Main .pageNation li.text {
        flex-grow: 1; }
        #PageNews #Main .pageNation li.text p {
          padding-left: 26px;
          padding-right: 26px;
          text-align: left;
          border-left-width: 0; }
      #PageNews #Main .pageNation li.first a, #PageNews #Main .pageNation li.prev a, #PageNews #Main .pageNation li.next a, #PageNews #Main .pageNation li.last a {
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: center center;
        border-left-width: 0; }
      #PageNews #Main .pageNation li.first a {
        background-color: #009a6a;
        background-image: url(../img/contents/arrow_07.png);
        background-size: 19px auto; }
        #PageNews #Main .pageNation li.first a:hover {
          background-color: #05a674; }
      #PageNews #Main .pageNation li.prev a {
        background-color: #22ab38;
        background-image: url(../img/contents/arrow_05.png);
        background-size: 10px auto; }
        #PageNews #Main .pageNation li.prev a:hover {
          background-color: #28b63f; }
      #PageNews #Main .pageNation li.prev + li a {
        border-left-width: 0; }
      #PageNews #Main .pageNation li.next a {
        background-color: #22ab38;
        background-image: url(../img/contents/arrow_01.png);
        background-size: 10px auto; }
        #PageNews #Main .pageNation li.next a:hover {
          background-color: #28b63f; }
      #PageNews #Main .pageNation li.last a {
        background-color: #009a6a;
        background-image: url(../img/contents/arrow_06.png);
        background-size: 19px auto; }
        #PageNews #Main .pageNation li.last a:hover {
          background-color: #05a674; }
      #PageNews #Main .pageNation li + li {
        margin-top: 0; }
      #PageNews #Main .pageNation li:before {
        display: none; }

#PageNews.pageIndex #Main .catSearch01 {
  margin-bottom: 70px; }

/*
NEWS 記事詳細
-------------------------------------*/
#PageNews.pageEntry #Main #ContBox .boxHead {
  margin-top: 38px; }
  #PageNews.pageEntry #Main #ContBox .boxHead .boxTitle {
    line-height: 46px; }
    #PageNews.pageEntry #Main #ContBox .boxHead .boxTitle:before {
      top: 13px; }
  #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo {
    padding-left: 56px;
    margin-top: 33px; }
    #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .date {
      font-size: 16px;
      font-weight: 400;
      color: #8c8c8c;
      letter-spacing: 0.05em;
      margin-right: 29px; }
    #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .cat {
      display: flex;
      background: url(../img/contents/cat_02.png) no-repeat left bottom;
      background-size: 14px auto;
      padding-left: 20px; }
      #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .cat li {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        color: #009b6b;
        letter-spacing: .05em;
        line-height: 16px;
        padding-left: 0; }
        #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .cat li + li {
          margin-top: 0; }
          #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .cat li + li:before {
            content: ",";
            position: relative;
            top: auto;
            display: inline-block;
            width: auto;
            height: auto;
            background-color: transparent;
            margin-right: 4px;
            margin-left: 1px;
            border-radius: 0; }
        #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .cat li:first-of-type:before {
          display: none; }
#PageNews.pageEntry #Main #ContBox .boxContent .entryBox p {
  line-height: 40px; }
#PageNews.pageEntry #Main #ContBox .entryPageNav {
  display: flex;
  flex-wrap: wrap;
  margin-top: 203px; }
  #PageNews.pageEntry #Main #ContBox .entryPageNav li {
    width: 17.60416666666667%;
    padding-left: 0; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav li:before {
      display: none; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav li + li {
      margin-top: 0; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav li a {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #22ab38;
      color: #fff;
      letter-spacing: 0.1em;
      line-height: 24px;
      text-decoration: none;
      text-align: center;
      padding: 21px 0; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav li a span {
        display: inline-block; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav li a:hover {
        background-color: #28b63f; }
  #PageNews.pageEntry #Main #ContBox .entryPageNav .top {
    width: 64.79166666666667%; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav .top a {
      background-color: #8ec21f;
      font-weight: bold; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav .top a span {
        background: url(../img/contents/icon_14.png) no-repeat left top 2px;
        background-size: 26px auto;
        padding-left: 39px; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav .top a:hover {
        background-color: #97cc26; }
  #PageNews.pageEntry #Main #ContBox .entryPageNav .prev a span {
    background: url(../img/contents/arrow_05.png) no-repeat left center;
    background-size: 10px auto;
    padding-left: 19px; }
  #PageNews.pageEntry #Main #ContBox .entryPageNav .next a span {
    background: url(../img/contents/arrow_01.png) no-repeat right center;
    background-size: 10px auto;
    padding-right: 22px; }

/*
検索ページ
-------------------------------------*/
#PageSearch.pageIndex #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PageSearch.pageIndex #Main .borderTitle {
    margin-bottom: 39px; }
    #PageSearch.pageIndex #Main .borderTitle > p {
      line-height: 40px; }
  #PageSearch.pageIndex #Main .pageNation {
    margin-top: 98px;
    display: flex;
    background-color: #8ec21f; }
    #PageSearch.pageIndex #Main .pageNation li {
      height: 66px;
      padding-left: 0; }
      #PageSearch.pageIndex #Main .pageNation li span, #PageSearch.pageIndex #Main .pageNation li p, #PageSearch.pageIndex #Main .pageNation li a {
        display: block;
        width: 100%;
        height: 100%;
        font-family: 'EB Garamond', serif;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        letter-spacing: 0.1em;
        line-height: 66px;
        text-align: center;
        text-decoration: none;
        border-left: 1px solid #4f9404; }
        #PageSearch.pageIndex #Main .pageNation li span.current, #PageSearch.pageIndex #Main .pageNation li p.current, #PageSearch.pageIndex #Main .pageNation li a.current {
          color: rgba(255, 255, 255, 0.5); }
      #PageSearch.pageIndex #Main .pageNation li a:hover {
        background-color: #97cc26; }
      #PageSearch.pageIndex #Main .pageNation li:not(.text) {
        width: 66px; }
      #PageSearch.pageIndex #Main .pageNation li.text {
        flex-grow: 1; }
        #PageSearch.pageIndex #Main .pageNation li.text p {
          padding-left: 26px;
          padding-right: 26px;
          text-align: left;
          border-left-width: 0; }
      #PageSearch.pageIndex #Main .pageNation li.first a, #PageSearch.pageIndex #Main .pageNation li.prev a, #PageSearch.pageIndex #Main .pageNation li.next a, #PageSearch.pageIndex #Main .pageNation li.last a {
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: center center;
        border-left-width: 0; }
      #PageSearch.pageIndex #Main .pageNation li.first a {
        background-color: #009a6a;
        background-image: url(../img/contents/arrow_07.png);
        background-size: 19px auto; }
        #PageSearch.pageIndex #Main .pageNation li.first a:hover {
          background-color: #05a674; }
      #PageSearch.pageIndex #Main .pageNation li.prev a {
        background-color: #22ab38;
        background-image: url(../img/contents/arrow_05.png);
        background-size: 10px auto; }
        #PageSearch.pageIndex #Main .pageNation li.prev a:hover {
          background-color: #28b63f; }
      #PageSearch.pageIndex #Main .pageNation li.prev + li a {
        border-left-width: 0; }
      #PageSearch.pageIndex #Main .pageNation li.next a {
        background-color: #22ab38;
        background-image: url(../img/contents/arrow_01.png);
        background-size: 10px auto; }
        #PageSearch.pageIndex #Main .pageNation li.next a:hover {
          background-color: #28b63f; }
      #PageSearch.pageIndex #Main .pageNation li.last a {
        background-color: #009a6a;
        background-image: url(../img/contents/arrow_06.png);
        background-size: 19px auto; }
        #PageSearch.pageIndex #Main .pageNation li.last a:hover {
          background-color: #05a674; }
      #PageSearch.pageIndex #Main .pageNation li + li {
        margin-top: 0; }
      #PageSearch.pageIndex #Main .pageNation li:before {
        display: none; }

/*
プライバシーポリシー
-------------------------------------*/
#PagePrivacy.pageIndex #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PagePrivacy.pageIndex #Main #ContBox01 {
    margin-top: 48px; }
    #PagePrivacy.pageIndex #Main #ContBox01 p {
      line-height: 40px; }
      #PagePrivacy.pageIndex #Main #ContBox01 p.right {
        text-align: right; }
      #PagePrivacy.pageIndex #Main #ContBox01 p + p {
        margin-top: 40px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .contSubBox_content {
      padding-top: 29px;
      padding-left: 22px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .contSubBox + .contSubBox {
      margin-top: 84px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .subBox_title {
      margin-bottom: 15px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .subBox_content {
      padding-left: 29px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .subBox + .subBox {
      margin-top: 40px; }

/*
教職員の方へ
-------------------------------------*/
#PageTeachers.pageIndex #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #PageTeachers.pageIndex #Main .contBox {
    margin-top: 96px; }
    #PageTeachers.pageIndex #Main .contBox .boxContent {
      padding-top: 28px;
      padding-left: 21px; }
      #PageTeachers.pageIndex #Main .contBox .boxContent a[target="_blank"] {
        padding-right: 25px;
        background-image: url(../img/contents/link_03.png);
        background-repeat: no-repeat;
        background-position: right 5px center;
        background-size: 11px auto; }
  #PageTeachers.pageIndex #Main #ContBox01 {
    margin-top: 39px; }

/*
404
-------------------------------------*/
#Page404.pageIndex #Main {
  padding-top: 109px;
  padding-bottom: 200px; }
  #Page404.pageIndex #Main .contBox {
    margin-top: 48px; }
    #Page404.pageIndex #Main .contBox .linkBtn {
      text-align: center;
      margin-top: 30px; }
      #Page404.pageIndex #Main .contBox .linkBtn .button {
        display: inline-block;
        border: 1px solid #e9e9e9;
        border-radius: 40px;
        padding: 0 31px 0 34px;
        height: 46px;
        background-color: #fff;
        line-height: 44px;
        letter-spacing: .05em;
        font-size: 14px;
        font-weight: bold;
        color: #009b6b; }
        #Page404.pageIndex #Main .contBox .linkBtn .button:hover {
          border-color: #009b6b;
          background-color: #009b6b;
          opacity: 1;
          color: #fff; }

@media print, screen and (max-width: 767px) {
  /*
  トップページ
   -------------------------------------*/
  #Page.pageIndex #MainImg #MainImgInner .slideImg {
    width: 100%;
    min-width: auto;
    height: 598px;
    padding-top: 0; }
    #Page.pageIndex #MainImg #MainImgInner .slideImg li {
      background-position: center center;
      background-size: 657px auto; }
      #Page.pageIndex #MainImg #MainImgInner .slideImg li:nth-of-type(1) {
        background-image: url(../img/contents/top/mainimgSp_01.jpg); }
      #Page.pageIndex #MainImg #MainImgInner .slideImg li:nth-of-type(2) {
        background-image: url(../img/contents/top/mainimgSp_02.jpg); }
      #Page.pageIndex #MainImg #MainImgInner .slideImg li:nth-of-type(3) {
        background-image: url(../img/contents/top/mainimgSp_03.jpg); }
  #Page.pageIndex #MainImg #MainImgInner .button {
    width: 197px;
    height: 40px;
    background-position: right 15px center;
    background-size: 10px auto;
    font-size: 13px;
    letter-spacing: 0.035em;
    line-height: 40px;
    padding-left: 16px;
    border-radius: 25px;
    right: 17px;
    bottom: 78px; }
  #Page.pageIndex #Main #ContBox01 {
    margin-bottom: 108px; }
    #Page.pageIndex #Main #ContBox01 .innerBasic {
      padding: 0; }
    #Page.pageIndex #Main #ContBox01 .flexBox .flexItem {
      width: 50%;
      height: 202px;
      margin-right: 1px; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem:nth-of-type(even) {
        width: calc(50% - 1px);
        margin-right: 0; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem a {
        background-position: right 15px bottom 34px; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem figure {
        width: calc(100% - 40px);
        height: 115px; }
      #Page.pageIndex #Main #ContBox01 .flexBox .flexItem p {
        position: relative;
        left: auto;
        bottom: auto;
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 15px;
        letter-spacing: 0.025em;
        line-height: 20px;
        padding: 0 50px 0 13px;
        margin-top: 27px; }
  #Page.pageIndex #Main #ImportPost {
    margin-bottom: 71px; }
    #Page.pageIndex #Main #ImportPost .innerBasic {
      padding: 0 17px; }
  #Page.pageIndex #Main #ContBox02 {
    margin-top: 108px; }
    #Page.pageIndex #Main #ContBox02 .innerBasic {
      padding: 0 17px; }
  #Page.pageIndex #Main #ContBox03 .boxHead {
    width: 100%;
    padding: 34px 0 54px;
    margin-left: 0; }
    #Page.pageIndex #Main #ContBox03 .boxHead h2 {
      font-size: 26px;
      letter-spacing: 0.025em;
      line-height: 35px; }
      #Page.pageIndex #Main #ContBox03 .boxHead h2 small {
        font-size: 21px;
        margin-bottom: -4px; }
      #Page.pageIndex #Main #ContBox03 .boxHead h2:after {
        width: 52px;
        height: 5px;
        bottom: -17px; }
  #Page.pageIndex #Main #ContBox03 .innerBasic {
    padding: 0; }
  #Page.pageIndex #Main #ContBox03 .boxContent {
    padding: 34px 0 125px; }
    #Page.pageIndex #Main #ContBox03 .boxContent .flexBox {
      flex-direction: column; }
      #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem01 {
        width: 100%; }
      #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 {
        width: 100%;
        margin-top: 30px; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox + .clickBox {
          margin-top: 1px; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .click {
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 0;
          background: url(../img/contents/icon_01.png) no-repeat right 25px center;
          background-size: 10px auto;
          padding: 22px 66px 21px 19px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .click.active {
            background-image: url(../img/contents/icon_02.png); }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .showBox {
          padding: 30px 17px 45px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .showBox h4 {
            font-size: 19px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 10px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .showBox p {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 27px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .showBox figure {
            margin-top: 19px; }
            #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .showBox figure img {
              width: 100%; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox .showBox .button {
            display: block;
            width: 141px;
            height: 45px;
            background: #000 url(../img/contents/arrow_01.png) no-repeat right 31px center;
            background-size: 8px auto;
            font-size: 15px;
            font-weight: bold;
            color: #fff;
            letter-spacing: 0.05em;
            line-height: 45px;
            border-radius: 40px;
            padding-left: 35px;
            margin: 25px auto 0; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox01 .click {
          background-color: #712512; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox02 .click {
          background-color: #a4381a; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox03 .click {
          background-color: #e0684e; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox04 .click {
          background-color: #f099a8; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox05 .showBox .button, #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox06 .showBox .button {
          margin-top: 12px; }
          #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox05 .showBox .button + h4, #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox06 .showBox .button + h4 {
            margin-top: 50px; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox05 .click {
          background-color: #e95f7b; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox06 .click {
          background-color: #ba2b76; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox07 .click {
          background-color: #25437d; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox08 .click {
          background-color: #65437c; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox09 .click {
          background-color: #49873c; }
        #Page.pageIndex #Main #ContBox03 .boxContent .flexBox .flexItem03 .clickBox10 .click {
          background-color: #3a682f; }
  #Page.pageIndex #Main #ContBox03 .popOpenWrapper, #Page.pageIndex #Main #ContBox03 .popOpenInner, #Page.pageIndex #Main #ContBox03 .popOpen {
    display: none !important;
    pointer-events: none !important; }

  /*
  教育学研究科・教育学部について
   -------------------------------------*/
  #PageOverview #MainImg #MainImgInner figure, #PageFacility #MainImg #MainImgInner figure, #PagePublications #MainImg #MainImgInner figure {
    background-image: url(../img/contents/overview/mainimg.jpg); }
  #PageOverview #Main, #PageFacility #Main, #PagePublications #Main {
    padding-top: 70px;
    padding-bottom: 90px; }
  #PageOverview:not(.pageIndex) #Main .boxContent p, #PageFacility:not(.pageIndex) #Main .boxContent p, #PagePublications:not(.pageIndex) #Main .boxContent p {
    font-size: 15px;
    letter-spacing: 0.025em;
    line-height: 30px; }
    #PageOverview:not(.pageIndex) #Main .boxContent p + p, #PageFacility:not(.pageIndex) #Main .boxContent p + p, #PagePublications:not(.pageIndex) #Main .boxContent p + p {
      margin-top: 30px; }

  #PageOverview.pageIndex #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageOverview.pageIndex #Main #ContBox02 .boxContent {
      padding-bottom: 105px; }
      #PageOverview.pageIndex #Main #ContBox02 .boxContent .button {
        display: none; }

  /*
  研究科長あいさつ
   -------------------------------------*/
  #PageOverview.pageDean #Main {
    padding-top: 72px;
    padding-bottom: 125px; }
    #PageOverview.pageDean #Main #ContBox01 {
      margin-bottom: 87px; }
      #PageOverview.pageDean #Main #ContBox01 .boxHead h3 {
        margin: 34px 0 62px; }
      #PageOverview.pageDean #Main #ContBox01 .boxContent figure {
        padding-bottom: 106px;
        margin-bottom: 54px; }
        #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption {
          right: 0; }
          #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption p {
            font-size: 21px;
            letter-spacing: 0.025em;
            margin-bottom: 10px; }
          #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption span {
            font-size: 12px;
            letter-spacing: 0.025em;
            line-height: 19px; }
          #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption:before {
            height: 77px;
            top: -7px;
            left: -33px; }
          #PageOverview.pageDean #Main #ContBox01 .boxContent figure figcaption:after {
            width: 112px;
            top: 31px;
            left: -135px; }
      #PageOverview.pageDean #Main #ContBox01 .boxContent .text {
        padding: 0; }

  /*
  歩み
   -------------------------------------*/
  #PageOverview.pageHistory #Main {
    padding-top: 75px; }
    #PageOverview.pageHistory #Main #ContBox01 {
      margin-top: 33px;
      margin-bottom: 97px; }
      #PageOverview.pageHistory #Main #ContBox01 .boxContent > p {
        padding-left: 0;
        padding-right: 0; }

  /*
  管理運営組織
  -------------------------------------*/
  #PageOverview.pageMoorg #Main {
    padding-top: 81px; }
    #PageOverview.pageMoorg #Main #ContBox01 {
      margin-top: 33px;
      margin-bottom: 101px; }
      #PageOverview.pageMoorg #Main #ContBox01 .boxContent > p {
        padding-left: 0;
        padding-right: 0; }
      #PageOverview.pageMoorg #Main #ContBox01 .boxContent figure {
        margin-top: 52px; }

  /*
  研究教育組織
  -------------------------------------*/
  #PageOverview.pageReorg #Main {
    padding-top: 81px; }
    #PageOverview.pageReorg #Main #ContBox01 {
      margin-top: 33px;
      margin-bottom: 96px; }
      #PageOverview.pageReorg #Main #ContBox01 .boxContent > p {
        text-indent: 0; }
      #PageOverview.pageReorg #Main #ContBox01 .boxContent figure {
        margin-top: 51px;
        margin-bottom: 69px; }
        #PageOverview.pageReorg #Main #ContBox01 .boxContent figure .tabBig {
          margin-top: 8px; }
      #PageOverview.pageReorg #Main #ContBox01 .boxContent .subBox + .subBox {
        margin-top: 47px; }

  /*
  附属施設等
  -------------------------------------*/
  #PageFacility.pageIndex #Main {
    padding-top: 81px; }
    #PageFacility.pageIndex #Main #ContBox01 {
      margin-top: 35px;
      margin-bottom: 105px; }
      #PageFacility.pageIndex #Main #ContBox01 .flexBox {
        flex-wrap: wrap;
        margin-bottom: -40px; }
        #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem {
          width: calc( (100% - 15px) / 2 );
          margin-right: 15px;
          margin-bottom: 40px; }
          #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem a {
            display: flex;
            flex-direction: column; }
            #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem a:hover {
              color: #000; }
              #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem a:hover dt img {
                opacity: 1; }
          #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem dd {
            flex-grow: 1;
            font-size: 15px;
            letter-spacing: 0.025em;
            line-height: 20px;
            background-position: right center;
            padding: 20px 15px 20px 0;
            display: flex;
            align-items: center; }
          #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem:nth-of-type(3n) {
            margin-right: 15px; }
          #PageFacility.pageIndex #Main #ContBox01 .flexBox .flexItem:nth-of-type(2n) {
            margin-right: 0; }

  /*
  附属施設等 教育学部図書室
  -------------------------------------*/
  #PageFacility.pageLibrary #Main > figure {
    width: calc(100% - 34px);
    margin-top: 35px;
    margin-bottom: 55px; }
  #PageFacility.pageLibrary #Main #ContBox02 {
    margin-top: 50px;
    margin-bottom: 105px; }
    #PageFacility.pageLibrary #Main #ContBox02 .boxContent {
      padding-top: 35px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent > p {
        line-height: 23px;
        padding-left: 16px;
        margin-bottom: 35px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .pdfLink a {
        padding: 12px 17px 11px;
        background-position: right 15px center;
        background-size: 8px auto; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .pdfLink a span {
          background-position: right center;
          background-size: 15px auto;
          padding-right: 28px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_title {
        font-size: 18px;
        margin-bottom: 6px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_title:before {
          top: 8px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content {
        padding-left: 27px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content h5 {
          font-size: 16px;
          margin-bottom: 2px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content p {
          letter-spacing: 0.05em; }
          #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content p strong {
            font-size: 15px; }
          #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content p + ul {
            margin-top: 9px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content .dotList {
          padding-left: 7px; }
          #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content .dotList li {
            letter-spacing: 0.05em; }
            #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content .dotList li span {
              margin-top: 0; }
          #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox_content .dotList + h5 {
            margin-top: 3px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox + .subBox {
        margin-top: 46px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox01 .subBox_content ul li span {
        margin-top: 0; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox01 .subBox_content p {
        line-height: 30px;
        padding-left: 22px;
        margin-top: 19px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content {
        padding-left: 0; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(1) {
          width: 32%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(2) {
          width: 32%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(3) {
          width: 18%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table th.bg-green:nth-of-type(4) {
          width: 18%; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content table td small {
          font-size: 10px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox04 .subBox_content .remark {
          font-size: 12px;
          line-height: 21px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox09 .subBox_content ul li small {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 21px;
        margin: -3px 0 11px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox09 .subBox_content ul li p {
        line-height: 28px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox10 .subBox_content, #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox11 .subBox_content {
        margin-top: -2px; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox10 .subBox_content p, #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox11 .subBox_content p {
          line-height: 30px; }
      #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox12 .subBox_content {
        padding-left: 0; }
        #PageFacility.pageLibrary #Main #ContBox02 .boxContent .subBox12 .subBox_content p {
          padding-left: 27px; }

  /*
  附属施設等 グローバル教育展開オフィス
  -------------------------------------*/
  #PageFacility.pageGlobal #Main .lowerPageTitle {
    margin-top: 76px; }
  #PageFacility.pageGlobal #Main .contBox {
    margin-top: 64px; }
    #PageFacility.pageGlobal #Main .contBox .boxHead .title {
      font-size: 20px;
      letter-spacing: 0; }
    #PageFacility.pageGlobal #Main .contBox .boxContent .subBox {
      margin-top: 35px; }
      #PageFacility.pageGlobal #Main .contBox .boxContent .subBox_title {
        font-size: 18px;
        line-height: 24px;
        text-align: justify; }
        #PageFacility.pageGlobal #Main .contBox .boxContent .subBox_title:before {
          top: 5px; }
      #PageFacility.pageGlobal #Main .contBox .boxContent .subBox_content {
        padding-left: 27px; }
        #PageFacility.pageGlobal #Main .contBox .boxContent .subBox_content p {
          padding-left: 0; }
  #PageFacility.pageGlobal #Main #ContBox01 {
    margin-top: 35px; }
    #PageFacility.pageGlobal #Main #ContBox01 .boxContent .text {
      padding-left: 0; }
      #PageFacility.pageGlobal #Main #ContBox01 .boxContent .text img {
        float: none;
        width: 100%;
        margin: 0 0 31px 0; }
    #PageFacility.pageGlobal #Main #ContBox01 .boxContent figure {
      margin-top: 51px; }
  #PageFacility.pageGlobal #Main #ContBox04 {
    margin-bottom: 100px; }
    #PageFacility.pageGlobal #Main #ContBox04 .boxContent p.red {
      letter-spacing: 0.05em;
      line-height: 24px;
      padding-left: 27px;
      margin-top: 16px; }

  /*
  附属施設等 心理教育相談室
  -------------------------------------*/
  #PageFacility.pageRoom #Main .contBox {
    margin-top: 55px; }
    #PageFacility.pageRoom #Main .contBox p {
      padding-left: 0; }
  #PageFacility.pageRoom #Main #ContBox01 {
    margin-top: 35px; }
    #PageFacility.pageRoom #Main #ContBox01 .boxContent .text img {
      float: none;
      width: 100%;
      margin: 0 0 31px 0; }
    #PageFacility.pageRoom #Main #ContBox01 .boxContent figure {
      margin-top: 62px; }
  #PageFacility.pageRoom #Main #ContBox02 {
    margin-top: 75px; }
    #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox {
      padding: 35px 30px 32px;
      margin-top: 40px; }
      #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox h4 {
        font-size: 18px;
        line-height: 24px; }
      #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox ul {
        padding-left: 4px;
        margin-top: 17px; }
        #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox ul li {
          line-height: 30px;
          padding-left: 13px; }
          #PageFacility.pageRoom #Main #ContBox02 .boxContent .subBox ul li:before {
            top: 14px; }
  #PageFacility.pageRoom #Main #ContBox04 .boxContent h4 {
    font-size: 16px;
    text-align: justify;
    padding-left: 0;
    margin-bottom: 2px; }
  #PageFacility.pageRoom #Main #ContBox05 {
    margin-bottom: 105px; }
    #PageFacility.pageRoom #Main #ContBox05 .boxContent .map {
      flex-wrap: wrap;
      margin-top: 32px; }
      #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem {
        width: 100%; }
        #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem a {
          width: 138px;
          height: 39px;
          font-size: 12px;
          line-height: 37px;
          padding-left: 32px;
          background-position: right 30px center;
          background-size: 15px auto;
          margin-top: 25px; }
        #PageFacility.pageRoom #Main #ContBox05 .boxContent .map .mapItem + .mapItem {
          margin-top: 40px; }

  /*
  附属施設等 こころの支援室
  -------------------------------------*/
  #PageFacility.pageSupport #Main .lowerPageTitle {
    margin-top: 76px; }
  #PageFacility.pageSupport #Main #ContBox01 {
    margin-top: 22px;
    margin-bottom: 94px; }
    #PageFacility.pageSupport #Main #ContBox01 .boxContent {
      padding-left: 0; }
      #PageFacility.pageSupport #Main #ContBox01 .boxContent img {
        float: none;
        width: 100%;
        margin: 0 0 31px 0; }
      #PageFacility.pageSupport #Main #ContBox01 .boxContent ol {
        margin-bottom: 12px; }
        #PageFacility.pageSupport #Main #ContBox01 .boxContent ol > li {
          font-size: 16px;
          font-weight: bold;
          line-height: 32px; }
      #PageFacility.pageSupport #Main #ContBox01 .boxContent ul {
        margin: 14px 0;
        padding-left: 18px; }
      #PageFacility.pageSupport #Main #ContBox01 .boxContent > p {
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 32px; }

  /*
  附属施設等 こころの支援室
  -------------------------------------*/
  #PageFacility.pageResearch #Main .lowerPageTitle {
    margin-top: 76px; }
  #PageFacility.pageResearch #Main #ContBox01 {
    margin-top: 35px; }
    #PageFacility.pageResearch #Main #ContBox01 .boxContent {
      padding-left: 0; }
      #PageFacility.pageResearch #Main #ContBox01 .boxContent img {
        float: none;
        width: 100%;
        margin: 0 0 31px 0; }
  #PageFacility.pageResearch #Main #ContBox02 {
    margin-top: 90px;
    margin-bottom: 70px; }
    #PageFacility.pageResearch #Main #ContBox02 .boxContent {
      padding-top: 40px; }
      #PageFacility.pageResearch #Main #ContBox02 .boxContent figure:last-of-type {
        margin-top: 29px; }

  /*
  刊行物・資料
  -------------------------------------*/
  #PagePublications #Main {
    padding-top: 75px; }
    #PagePublications #Main .pageTitle .button {
      height: 36px;
      font-size: 12px;
      line-height: 36px;
      background-position: right 12px center;
      background-size: 6px auto;
      padding: 0 26px 0 17px;
      border-radius: 36px;
      top: 22px;
      right: 15px; }

  #PagePublications.pageIndex #Main .lowerLink_item a {
    background-position: right center;
    background-size: 12px auto; }
  #PagePublications.pageIndex #Main .contBox {
    margin-top: 73px; }
    #PagePublications.pageIndex #Main .contBox .boxHead {
      margin-bottom: 40px; }
      #PagePublications.pageIndex #Main .contBox .boxHead .button {
        position: relative;
        top: auto;
        right: auto;
        display: inline-block;
        height: 36px;
        font-size: 12px;
        letter-spacing: 0.025em;
        line-height: 34px;
        margin-top: 25px; }
        #PagePublications.pageIndex #Main .contBox .boxHead .button span {
          background-size: 6px auto;
          padding-right: 15px; }
    #PagePublications.pageIndex #Main .contBox .boxContent .postList li a {
      padding: 20px 36px 20px 16px;
      line-height: 23px; }
  #PagePublications.pageIndex #Main #ContBox01 {
    margin-top: 76px; }
  #PagePublications.pageIndex #Main #ContBox05 .boxContent .post {
    padding-left: 0; }
    #PagePublications.pageIndex #Main #ContBox05 .boxContent .post p {
      line-height: 30px; }
  #PagePublications.pageIndex #Main #ContBox06 {
    margin-bottom: 102px; }

  /*
  刊行物・資料 カテゴリ
  -------------------------------------*/
  #PagePublications.pageCategory #Main #ContBox01 {
    margin-top: 76px;
    margin-bottom: 102px; }
    #PagePublications.pageCategory #Main #ContBox01 .boxContent {
      padding-top: 40px; }
      #PagePublications.pageCategory #Main #ContBox01 .boxContent .postList li a {
        padding-left: 16px; }

  /*
  刊行物・資料 記事詳細
  -------------------------------------*/
  #PagePublications.pageEntry #Main #ContBox01 {
    margin-top: 76px;
    margin-bottom: 102px; }
    #PagePublications.pageEntry #Main #ContBox01 .boxContent {
      padding-top: 38px; }
      #PagePublications.pageEntry #Main #ContBox01 .boxContent .entryHead p {
        font-size: 20px;
        padding-bottom: 8px; }
      #PagePublications.pageEntry #Main #ContBox01 .boxContent .entryBox {
        padding-left: 0; }

  /*
  広報用ロゴマークについて
  -------------------------------------*/
  #PageOverview.pageEduc #Main #ContBox01 {
    margin-top: 55px;
    margin-bottom: 90px; }

  /*
  お問い合わせ先
  -------------------------------------*/
  #PageOverview.pageContact #Main .contBox {
    margin-top: 60px; }
    #PageOverview.pageContact #Main .contBox .boxContent {
      margin-top: 35px;
      padding-left: 0; }
      #PageOverview.pageContact #Main .contBox .boxContent p {
        line-height: 30px;
        margin-bottom: 26px; }
      #PageOverview.pageContact #Main .contBox .boxContent table + p {
        margin-top: 33px; }
  #PageOverview.pageContact #Main #ContBox01 {
    margin-top: 55px; }
  #PageOverview.pageContact #Main #ContBox05 {
    margin-bottom: 90px; }

  /*
  アクセス
  -------------------------------------*/
  #PageOverview.pageAccess #Main .contBox .boxHead .boxTitle {
    text-align: justify; }
  #PageOverview.pageAccess #Main #ContBox01 {
    margin-top: 55px; }
    #PageOverview.pageAccess #Main #ContBox01 .boxContent {
      padding-top: 35px; }
  #PageOverview.pageAccess #Main #ContBox02 {
    margin-top: 60px; }
    #PageOverview.pageAccess #Main #ContBox02 .boxContent {
      padding-top: 43px; }
      #PageOverview.pageAccess #Main #ContBox02 .boxContent figure img {
        width: 100%; }
  #PageOverview.pageAccess #Main #ContBox03 {
    margin-top: 60px;
    margin-bottom: 60px; }
    #PageOverview.pageAccess #Main #ContBox03 .boxContent {
      padding-top: 22px; }
      #PageOverview.pageAccess #Main #ContBox03 .boxContent > p {
        padding-left: 0; }
      #PageOverview.pageAccess #Main #ContBox03 .boxContent .contSubBox {
        margin-top: 25px; }
      #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox {
        margin-top: 46px; }
        #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_title {
          text-align: justify;
          margin-bottom: 30px; }
        #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content {
          padding-left: 0; }
          #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content ol {
            font-size: 15px; }
            #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content ol + p {
              margin-top: 20px; }
          #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content li {
            font-size: 15px; }
          #PageOverview.pageAccess #Main #ContBox03 .boxContent .subBox_content p + ol {
            margin-top: 15px; }

  /*
  教育学研究科・教育学部について
  -------------------------------------*/
  #PageAdmissions #Main {
    padding-top: 76px;
    padding-bottom: 90px; }
  #PageAdmissions:not(.pageIndex) #Main .boxContent p {
    font-size: 15px;
    letter-spacing: 0.025em;
    line-height: 30px; }
    #PageAdmissions:not(.pageIndex) #Main .boxContent p + p {
      margin-top: 30px; }
  #PageAdmissions .containerMenu .flexBox .flexItem07, #PageAdmissions .containerMenu .flexBox .flexItem08 {
    background-position: right 9px center;
    background-size: 16px auto; }

  #PageAdmissions.pageIndex #Main {
    padding-top: 71px;
    padding-bottom: 90px; }
    #PageAdmissions.pageIndex #Main #ContBox02 .boxContent {
      padding-bottom: 105px; }

  /*
  学部受験生の方へ
  -------------------------------------*/
  #PageAdmissions.pageInfo #Main {
    padding-top: 77px; }
    #PageAdmissions.pageInfo #Main .contBox {
      margin-top: 40px; }
      #PageAdmissions.pageInfo #Main .contBox .boxTitle span {
        font-size: 18px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox {
        margin-top: 50px; }
        #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_title {
          font-size: 18px;
          line-height: 24px;
          text-align: justify;
          margin-bottom: 10px; }
          #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_title:before {
            top: 6px; }
          #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_title + .subBox_content {
            padding-left: 30px; }
        #PageAdmissions.pageInfo #Main .contBox .boxContent .subBox_content {
          padding-left: 0; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent h5.square {
        font-size: 16px; }
        #PageAdmissions.pageInfo #Main .contBox .boxContent h5.square:before {
          top: 12px; }
        #PageAdmissions.pageInfo #Main .contBox .boxContent h5.square + p:not(.square) {
          margin-top: 1px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent p.remark {
        font-size: 13px;
        line-height: 24px;
        padding-left: 15px;
        margin-left: 0; }
        #PageAdmissions.pageInfo #Main .contBox .boxContent p.remark + .remark {
          margin-top: 13px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent p + h5.square {
        margin-top: 6px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent ol li {
        font-size: 15px;
        line-height: 30px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent table {
        margin: 22px 0 24px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent .square + ul {
        padding-left: 18px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent .square:before {
        top: 13px; }
      #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink {
        flex-wrap: wrap;
        background-position: right 15px center;
        padding: 18px 16px; }
        #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink span {
          background-size: 15px auto;
          padding-right: 27px; }
        #PageAdmissions.pageInfo #Main .contBox .boxContent .pdfLink small {
          margin-top: 2px;
          margin-left: 0; }
    #PageAdmissions.pageInfo #Main #ContBox01 {
      margin-top: 76px; }
      #PageAdmissions.pageInfo #Main #ContBox01 .boxContent {
        padding-top: 34px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent > p:not(.remark) {
      padding-left: 0; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent > ul {
      padding-left: 18px;
      margin-top: 19px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl {
      margin-top: 44px; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl dt {
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 24px;
        text-align: justify;
        padding: 22px 15px 20px; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl dd {
        padding: 21px 15px 22px; }
        #PageAdmissions.pageInfo #Main #ContBox03 .boxContent dl dd ul {
          margin-top: 10px;
          padding-left: 5px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox_content table {
      width: calc(100% + 15px);
      margin-left: -15px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox01 p {
      margin-bottom: 5px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox02 p {
      line-height: 30px; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox02 p + p {
        margin-top: 6px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox03 table {
      margin-top: 28px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 .subBox_content {
      padding-left: 15px; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 .subBox_content table {
        width: 100%;
        margin-left: 0; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 .subBox_content ul {
        padding-left: 28px;
        margin-bottom: 25px; }
      #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox04 .subBox_content p.square {
        line-height: 30px;
        margin-top: 15px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox05 p {
      margin-bottom: 15px; }
    #PageAdmissions.pageInfo #Main #ContBox03 .boxContent .subBox05 ul li + li {
      margin-top: 0; }
    #PageAdmissions.pageInfo #Main #ContBox04 {
      margin-bottom: 105px; }
      #PageAdmissions.pageInfo #Main #ContBox04 .boxContent .square {
        margin-top: 20px; }
      #PageAdmissions.pageInfo #Main #ContBox04 .boxContent ul {
        padding-left: 28px; }

  /*
  教育学部のポリシー
  -------------------------------------*/
  #PageAdmissions.pagePurpose #Main .contBox {
    margin-top: 76px; }
    #PageAdmissions.pagePurpose #Main .contBox .boxHead {
      margin-bottom: 30px; }
    #PageAdmissions.pagePurpose #Main .contBox .boxContent {
      padding-left: 0; }
      #PageAdmissions.pagePurpose #Main .contBox .boxContent ul, #PageAdmissions.pagePurpose #Main .contBox .boxContent ol {
        margin-top: 30px; }
        #PageAdmissions.pagePurpose #Main .contBox .boxContent ul li, #PageAdmissions.pagePurpose #Main .contBox .boxContent ol li {
          line-height: 30px; }
          #PageAdmissions.pagePurpose #Main .contBox .boxContent ul li + li, #PageAdmissions.pagePurpose #Main .contBox .boxContent ol li + li {
            margin-top: 10px; }
      #PageAdmissions.pagePurpose #Main .contBox .boxContent ul li:before {
        top: 11px; }
  #PageAdmissions.pagePurpose #Main #ContBox03 {
    margin-bottom: 105px; }

  /*
  教育内容
  -------------------------------------*/
  #PageAdmissions.pageEducation #Main .contBox {
    margin-top: 60px; }
    #PageAdmissions.pageEducation #Main .contBox .boxContent {
      padding-top: 28px; }
      #PageAdmissions.pageEducation #Main .contBox .boxContent > p {
        padding-left: 0; }
      #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content {
        padding-top: 38px; }
        #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox {
          flex-wrap: wrap;
          background-color: #eff8f5;
          padding: 17px 22px 18px;
          border-radius: 10px; }
          #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox p {
            font-size: 16px;
            letter-spacing: 0.025em;
            line-height: 32px;
            position: relative; }
            #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox p + p {
              margin-left: 28px; }
              #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content .flexBox p + p:before {
                left: -22px; }
        #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox_content p + p {
          margin-top: 0; }
      #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox01 {
        margin-top: 42px; }
      #PageAdmissions.pageEducation #Main .contBox .boxContent .subBox02 {
        margin-top: 25px; }
  #PageAdmissions.pageEducation #Main #ContBox01 {
    margin-top: 76px; }
  #PageAdmissions.pageEducation #Main #ContBox03 {
    margin-bottom: 105px; }

  /*
  取得できる資格
  -------------------------------------*/
  #PageAdmissions.pageObtain #Main #ContBox01 {
    margin-bottom: 105px; }
    #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox {
      margin-top: 43px; }
      #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox_content {
        padding-top: 14px; }
        #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox_content p {
          padding-left: 0; }
        #PageAdmissions.pageObtain #Main #ContBox01 .boxContent .subBox_content img {
          width: 100%;
          float: none;
          margin-left: 0; }

  /*
  卒業後の進路
  -------------------------------------*/
  #PageAdmissions.pageAfter #Main #ContBox01 {
    margin-top: 39px;
    margin-bottom: 105px; }
    #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox01 img {
      float: none;
      display: block;
      margin: 0 auto 30px; }
    #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox01 p {
      padding-left: 0; }
    #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02 {
      padding: 35px 30px 32px;
      margin-top: 40px; }
      #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02 h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 17px; }
      #PageAdmissions.pageAfter #Main #ContBox01 .boxContent .subBox02 span {
        font-size: 13px;
        line-height: 26px;
        padding-left: 15px;
        margin-top: 26px; }

  /*
  大学院受験生・研究者の方へ
  -------------------------------------*/
  #PageGraduate #Main, #PageResearch #Main, #PageEducation #Main, #PageStaffList #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageGraduate #Main .containerMenu .boxHead, #PageResearch #Main .containerMenu .boxHead, #PageEducation #Main .containerMenu .boxHead, #PageStaffList #Main .containerMenu .boxHead {
      margin-bottom: 35px; }
      #PageGraduate #Main .containerMenu .boxHead h3, #PageResearch #Main .containerMenu .boxHead h3, #PageEducation #Main .containerMenu .boxHead h3, #PageStaffList #Main .containerMenu .boxHead h3 {
        font-size: 29px;
        line-height: 35px; }
        #PageGraduate #Main .containerMenu .boxHead h3:before, #PageResearch #Main .containerMenu .boxHead h3:before, #PageEducation #Main .containerMenu .boxHead h3:before, #PageStaffList #Main .containerMenu .boxHead h3:before {
          width: 40px;
          height: 6px;
          top: 5px;
          right: -54px; }
    #PageGraduate #Main .containerMenu01 .flexBox .flexItem06, #PageResearch #Main .containerMenu01 .flexBox .flexItem06, #PageEducation #Main .containerMenu01 .flexBox .flexItem06, #PageStaffList #Main .containerMenu01 .flexBox .flexItem06 {
      background-position: right 9px center;
      background-size: 16px auto; }
    #PageGraduate #Main .containerMenu02, #PageResearch #Main .containerMenu02, #PageEducation #Main .containerMenu02, #PageStaffList #Main .containerMenu02 {
      margin-top: 67px; }
      #PageGraduate #Main .containerMenu02 .flexBox .flexItem03, #PageResearch #Main .containerMenu02 .flexBox .flexItem03, #PageEducation #Main .containerMenu02 .flexBox .flexItem03, #PageStaffList #Main .containerMenu02 .flexBox .flexItem03 {
        background-position: right 9px center;
        background-size: 16px auto; }
  #PageGraduate:not(.pageIndex) #Main .boxContent p, #PageResearch:not(.pageIndex) #Main .boxContent p, #PageEducation:not(.pageIndex) #Main .boxContent p, #PageStaffList:not(.pageIndex) #Main .boxContent p {
    line-height: 30px;
    letter-spacing: .025em;
    font-size: 15px; }

  /*
  大学院受験生の方へ
  -------------------------------------*/
  #PageGraduate.pageInfo #Main .contBox {
    margin-top: 80px; }
    #PageGraduate.pageInfo #Main .contBox .boxTitle span {
      font-size: 18px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .contSubBox {
      margin-top: 50px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .subBox {
      margin-top: 50px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_title {
        font-size: 18px;
        line-height: 24px;
        text-align: justify;
        margin-bottom: 10px; }
        #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_title:before {
          top: 6px; }
        #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_title + .subBox_content {
          padding-left: 30px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content {
        padding-left: 0; }
        #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content > ul {
          padding-left: 18px; }
        #PageGraduate.pageInfo #Main .contBox .boxContent .subBox_content p.square {
          margin-top: 13px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent h5 {
      font-size: 18px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent h5.square {
        font-size: 16px; }
        #PageGraduate.pageInfo #Main .contBox .boxContent h5.square:before {
          top: 12px; }
        #PageGraduate.pageInfo #Main .contBox .boxContent h5.square + p:not(.square) {
          margin-top: 1px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent h5 + p {
        line-height: 30px !important; }
    #PageGraduate.pageInfo #Main .contBox .boxContent p.remark {
      font-size: 13px;
      line-height: 24px;
      padding-left: 15px;
      margin-left: 0; }
      #PageGraduate.pageInfo #Main .contBox .boxContent p.remark + .remark {
        margin-top: 13px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent p + h5.square {
      margin-top: 6px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent ol li {
      font-size: 15px;
      line-height: 30px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent table {
      margin: 22px 0 24px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .square + ul {
      padding-left: 18px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .square:before {
      top: 13px; }
    #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink {
      flex-wrap: wrap;
      background-position: right 15px center;
      padding: 18px 16px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink span {
        background-size: 15px auto;
        padding-right: 27px; }
      #PageGraduate.pageInfo #Main .contBox .boxContent .pdfLink small {
        margin-top: 2px;
        margin-left: 0; }
  #PageGraduate.pageInfo #Main #ContBox01 {
    margin-top: 40px; }
    #PageGraduate.pageInfo #Main #ContBox01 .boxContent {
      padding-top: 34px; }
  #PageGraduate.pageInfo #Main #ContBox02 .boxContent ul + h5 {
    margin-top: 25px; }
  #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox02 .subBox02 p, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox02 .subBox04 p, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox05 .subBox02 p, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox05 .subBox04 p {
    line-height: 30px; }
  #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox03 .subBox, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox04 .subBox, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox06 .subBox, #PageGraduate.pageInfo #Main #ContBox02 .boxContent .contSubBox07 .subBox {
    margin-top: 0; }
  #PageGraduate.pageInfo #Main #ContBox03 {
    margin-bottom: 105px; }
    #PageGraduate.pageInfo #Main #ContBox03 .boxContent .subBox {
      margin-top: 30px; }

  /*
  教育学研究科のポリシー
  -------------------------------------*/
  #PageGraduate.pagePurpose #Main .contBox {
    margin-top: 76px; }
    #PageGraduate.pagePurpose #Main .contBox .boxContent {
      padding-top: 15px;
      padding-left: 0; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent p {
        line-height: 30px; }
        #PageGraduate.pagePurpose #Main .contBox .boxContent p strong {
          display: block;
          font-weight: bold; }
        #PageGraduate.pagePurpose #Main .contBox .boxContent p + p {
          margin-top: 30px; }
        #PageGraduate.pagePurpose #Main .contBox .boxContent p + ol {
          margin-top: 30px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent ol li {
        line-height: 30px; }
        #PageGraduate.pagePurpose #Main .contBox .boxContent ol li p {
          line-height: 30px; }
        #PageGraduate.pagePurpose #Main .contBox .boxContent ol li + li {
          margin-top: 10px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent ol + p {
        margin-top: 30px; }
      #PageGraduate.pagePurpose #Main .contBox .boxContent dl {
        margin: 20px 0 40px; }
        #PageGraduate.pagePurpose #Main .contBox .boxContent dl dt {
          padding: 22px 15px 20px;
          font-size: 16px;
          letter-spacing: .05em;
          line-height: 24px;
          text-align: justify; }
        #PageGraduate.pagePurpose #Main .contBox .boxContent dl dd {
          padding: 21px 15px 22px; }
  #PageGraduate.pagePurpose #Main .contSubBox {
    width: 100%;
    margin-top: 49px;
    margin-left: 0; }
    #PageGraduate.pagePurpose #Main .contSubBox_content {
      padding-top: 31px;
      padding-left: 0; }
    #PageGraduate.pagePurpose #Main .contSubBox + dl {
      margin-top: 40px !important; }
  #PageGraduate.pagePurpose #Main .subBox_content {
    padding-left: 30px; }
  #PageGraduate.pagePurpose #Main #ContBox01 {
    margin-top: 36px; }
  #PageGraduate.pagePurpose #Main #ContBox03 {
    margin-bottom: 105px; }

  /*
  研究内容
  -------------------------------------*/
  #PageResearch #Main p, #PageEducation #Main p {
    line-height: 30px; }
    #PageResearch #Main p + p, #PageEducation #Main p + p {
      margin-top: 30px; }
  #PageResearch #Main .categoryList h4, #PageEducation #Main .categoryList h4 {
    font-size: 22px;
    line-height: 35px;
    margin: 33px 0 23px; }
  #PageResearch #Main .categoryList .flexBox, #PageEducation #Main .categoryList .flexBox {
    margin-bottom: -15px; }
    #PageResearch #Main .categoryList .flexBox .flexItem, #PageEducation #Main .categoryList .flexBox .flexItem {
      width: calc( (100% - 15px) / 2 );
      height: auto;
      background-position: right 12px center;
      background-size: 6px auto;
      font-size: 14px;
      padding: 21px 24px 22px 12px;
      margin-right: 15px;
      margin-bottom: 15px; }
      #PageResearch #Main .categoryList .flexBox .flexItem:nth-of-type(3n), #PageEducation #Main .categoryList .flexBox .flexItem:nth-of-type(3n) {
        margin-right: 15px; }
      #PageResearch #Main .categoryList .flexBox .flexItem:nth-of-type(2n), #PageEducation #Main .categoryList .flexBox .flexItem:nth-of-type(2n) {
        margin-right: 0; }
      #PageResearch #Main .categoryList .flexBox .flexItem p, #PageEducation #Main .categoryList .flexBox .flexItem p {
        line-height: 21px !important; }
      #PageResearch #Main .categoryList .flexBox .flexItem small, #PageEducation #Main .categoryList .flexBox .flexItem small {
        display: inline;
        font-size: 11px;
        margin-top: 0; }

  #PageResearch.pageIndex #Main {
    padding-top: 77px; }
    #PageResearch.pageIndex #Main #ContBox01 {
      margin-top: 36px;
      margin-bottom: 102px; }
      #PageResearch.pageIndex #Main #ContBox01 .boxContent {
        padding-top: 15px; }
        #PageResearch.pageIndex #Main #ContBox01 .boxContent > p {
          padding-left: 0; }
        #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox {
          margin-top: 30px; }
          #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content {
            padding-top: 0;
            padding-left: 0; }
            #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table {
              margin: 31px 0 56px; }
              #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table th {
                width: 33.23529411764706% !important;
                font-size: 14px;
                padding: 14px 8px 13px;
                vertical-align: middle; }
              #PageResearch.pageIndex #Main #ContBox01 .boxContent .contSubBox_content table td {
                font-size: 14px;
                line-height: 21px;
                text-align: justify;
                padding: 12px 8px; }

  /*
  研究内容 教育学環専攻
  -------------------------------------*/
  #PageEducation.pageIndex #Main #ContBox01 {
    margin-top: 36px;
    margin-bottom: 102px; }
    #PageEducation.pageIndex #Main #ContBox01 .boxHead {
      margin-bottom: 15px; }

  /*
  研究内容 教育学環専攻 カテゴリ
  -------------------------------------*/
  #PageEducation.pageCategory #Main #ContBox01 {
    margin-top: 31px; }
    #PageEducation.pageCategory #Main #ContBox01 .boxContent {
      padding: 0; }
      #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox .wp-caption {
        width: calc( (100% - 15px) / 2 );
        margin-right: 15px;
        margin-bottom: 34px; }
        #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox .wp-caption:nth-of-type(3n) {
          margin-right: 15px; }
        #PageEducation.pageCategory #Main #ContBox01 .boxContent .entryBox .wp-caption:nth-of-type(2n) {
          margin-right: 0; }
  #PageEducation.pageCategory #Main #ContBox02 {
    margin-top: 36px;
    margin-bottom: 102px; }
    #PageEducation.pageCategory #Main #ContBox02 .boxContent {
      padding-top: 30px; }
      #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox {
        margin-bottom: 73px; }
        #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content {
          padding: 40px 0 0; }
          #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox {
            flex-direction: column;
            margin-bottom: 23px; }
            #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox figure {
              width: 224px;
              height: 304px;
              margin: 0 auto; }
            #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div {
              width: 100%;
              margin-top: 40px; }
              #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div h4 {
                font-size: 15px;
                line-height: 30px;
                background-position: left 21px top 20px;
                background-size: 17px auto;
                padding: 12px 15px 12px 56px;
                margin-bottom: 21px; }
              #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div ul {
                padding-left: 15px;
                margin-top: 16px; }
                #PageEducation.pageCategory #Main #ContBox02 .boxContent .subBox_content .flexBox div ul li + li {
                  margin-top: 0; }

  /*
  教員一覧
  -------------------------------------*/
  #PageStaffList #Main .categoryList {
    margin-top: 69px;
    margin-bottom: 102px; }
    #PageStaffList #Main .categoryList h4 {
      font-size: 22px;
      line-height: 35px;
      margin: 3px 0 23px; }
    #PageStaffList #Main .categoryList .flexBox {
      margin-bottom: -15px; }
      #PageStaffList #Main .categoryList .flexBox .flexItem {
        width: calc( (100% - 15px) / 2 );
        height: auto;
        background-position: right 12px center;
        background-size: 6px auto;
        font-size: 14px;
        padding: 21px 24px 22px 12px;
        margin-right: 15px;
        margin-bottom: 15px; }
        #PageStaffList #Main .categoryList .flexBox .flexItem:nth-of-type(3n) {
          margin-right: 15px; }
        #PageStaffList #Main .categoryList .flexBox .flexItem:nth-of-type(2n) {
          margin-right: 0; }
        #PageStaffList #Main .categoryList .flexBox .flexItem p {
          line-height: 21px !important; }
        #PageStaffList #Main .categoryList .flexBox .flexItem small {
          display: inline;
          font-size: 11px;
          margin-top: 0; }

  #PageStaffList.pageIndex #Main .contBox {
    margin-top: 76px; }
    #PageStaffList.pageIndex #Main .contBox .leaderImage {
      margin-top: 35px; }
    #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item {
      width: 100%;
      min-height: 154px;
      margin-bottom: 30px; }
      #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item a:hover:after {
        display: none; }
      #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .photo {
        width: 127px; }
      #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .text {
        width: calc(100% - 127px);
        padding: 27px 20px 20px 25px; }
        #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .text .name {
          font-size: 18px;
          line-height: 20px; }
        #PageStaffList.pageIndex #Main .contBox .boxContent .teacher_item .text .domain {
          font-size: 11px;
          line-height: 16px;
          background-position: left 16px top 15px;
          background-size: 12px auto;
          padding: 12px 13px 14px 38px; }
  #PageStaffList.pageIndex #Main #ContBox01 {
    margin-top: 46px; }

  /*
  教員一覧 カテゴリ
  -------------------------------------*/
  #PageStaffList.pageCategory #Main .contBox .boxContent {
    padding-top: 30px; }
    #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content {
      padding: 40px 0 0; }
      #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox {
        flex-direction: column;
        margin-bottom: 23px; }
        #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox figure {
          width: 224px;
          height: 304px;
          margin: 0 auto; }
        #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox div {
          width: 100%;
          margin-top: 40px; }
          #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox div h4 {
            font-size: 15px;
            line-height: 30px;
            background-position: left 21px top 20px;
            background-size: 17px auto;
            padding: 12px 15px 12px 56px;
            margin-bottom: 21px; }
          #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox div p {
            line-height: 30px; }
          #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox div ul {
            padding-left: 15px;
            margin-top: 16px; }
            #PageStaffList.pageCategory #Main .contBox .boxContent .subBox_content .flexBox div ul li + li {
              margin-top: 0; }
    #PageStaffList.pageCategory #Main .contBox .boxContent .subBox + .subBox {
      margin-top: 50px; }
  #PageStaffList.pageCategory #Main .categoryList {
    margin-top: 73px; }

  /*
  取得できる資格
  -------------------------------------*/
  #PageGraduate.pageObtain #Main #ContBox01 {
    margin-bottom: 105px; }
    #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox {
      margin-top: 43px; }
      #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox_content {
        padding-top: 14px; }
        #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox_content p {
          line-height: 30px;
          padding-left: 0; }
        #PageGraduate.pageObtain #Main #ContBox01 .boxContent .subBox_content img {
          width: 100%;
          float: none;
          margin-left: 0; }

  /*
  取得できる資格 公認心理師
  -------------------------------------*/
  #PageGraduate.pageKouninup #Main #ContBox01 {
    margin-top: 36px;
    margin-bottom: 102px; }
    #PageGraduate.pageKouninup #Main #ContBox01 .boxContent {
      padding-top: 15px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p {
        line-height: 30px;
        padding: 0; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p.remark {
          padding-right: 0; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent p + p {
          margin-top: 16px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ol, #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ul {
        padding: 0; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ol li, #PageGraduate.pageKouninup #Main #ContBox01 .boxContent ul li {
          line-height: 30px; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .borderTitle p {
        padding-left: 0; }
      #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox {
        margin-top: 48px; }
        #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content {
          padding-top: 32px; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div {
            padding-left: 0;
            margin-top: 25px; }
            #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div p {
              padding-left: 21px; }
              #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div p.remark {
                padding-left: 39px; }
            #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div:first-of-type {
              margin-top: 0; }
            #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content > div + p {
              margin: 48px 0 -24px; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content h4 {
            font-size: 18px;
            text-align: justify;
            padding-left: 19px;
            margin-bottom: 10px; }
          #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content ol.maru {
            margin: 0; }
            #PageGraduate.pageKouninup #Main #ContBox01 .boxContent .contSubBox_content ol.maru li:before {
              top: 7px; }

  /*
  修了後の進路
  -------------------------------------*/
  #PageGraduate.pageAfter #Main #ContBox01 {
    margin-top: 36px;
    margin-bottom: 102px; }
    #PageGraduate.pageAfter #Main #ContBox01 .boxContent {
      padding-top: 28px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent img {
        display: block;
        float: none;
        margin: 0 auto 30px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent p {
        line-height: 30px;
        padding-left: 0; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent p.remark {
          padding-left: 41px; }
          #PageGraduate.pageAfter #Main #ContBox01 .boxContent p.remark:before {
            left: 22px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent .contSubBox {
        margin-top: 43px; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent .contSubBox_content {
          padding-top: 25px; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent .contSubBox:last-of-type {
          margin-bottom: 38px; }
      #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content {
        margin-top: 10px;
        padding-left: 30px; }
        #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul {
          padding-left: 0; }
          #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul li strong {
            font-size: 18px; }
          #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul li p {
            padding-left: 0; }
          #PageGraduate.pageAfter #Main #ContBox01 .boxContent .subBox_content ul li + li {
            margin-top: 23px; }
  #PageGraduate.pageAfter #Main .containerMenu01 .flexBox .flexItem09 {
    background-color: #e1f4ee; }

  /*
  一般の皆様へ
  -------------------------------------*/
  #PageVisitors.pageIndex #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageVisitors.pageIndex #Main .postArea .boxContent {
      padding-bottom: 105px; }
    #PageVisitors.pageIndex #Main #ContainerMenu .flexBox .flexItem {
      background-position: right 9px center;
      background-size: 16px auto; }

  /*
  在校生・卒業生
  -------------------------------------*/
  #PageTowards #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageTowards #Main #ContainerMenu .flexBox .flexItem05, #PageTowards #Main #ContainerMenu .flexBox .flexItem08, #PageTowards #Main #ContainerMenu .flexBox .flexItem10, #PageTowards #Main #ContainerMenu .flexBox .flexItem11 {
      background-position: right 9px center;
      background-size: 16px auto; }

  /*
  紀要編集委員会からのお知らせ
  -------------------------------------*/
  #PageTowards.pageSubmission #Main .contBox {
    margin-top: 76px; }
    #PageTowards.pageSubmission #Main .contBox .boxContent {
      padding-top: 15px;
      padding-left: 0; }
      #PageTowards.pageSubmission #Main .contBox .boxContent p {
        line-height: 30px; }
        #PageTowards.pageSubmission #Main .contBox .boxContent p + p {
          margin-top: 30px; }
    #PageTowards.pageSubmission #Main .contBox:first-of-type {
      margin-top: 36px; }
  #PageTowards.pageSubmission #Main #ContainerMenu {
    margin-top: 102px; }

  /*
  証明書の請求方法
  -------------------------------------*/
  #PageTowards.pageCertificate #Main .lowerLink_item {
    min-height: 81px;
    height: auto; }
  #PageTowards.pageCertificate #Main .contBox {
    margin-top: 76px; }
    #PageTowards.pageCertificate #Main .contBox:first-of-type {
      margin-top: 50px; }
    #PageTowards.pageCertificate #Main .contBox .boxContent {
      padding-top: 28px; }
    #PageTowards.pageCertificate #Main .contBox .contSubBox01 {
      margin-top: 50px; }
      #PageTowards.pageCertificate #Main .contBox .contSubBox01 > p {
        padding-left: 0; }
      #PageTowards.pageCertificate #Main .contBox .contSubBox01 > p, #PageTowards.pageCertificate #Main .contBox .contSubBox01 > ol, #PageTowards.pageCertificate #Main .contBox .contSubBox01 > ul {
        margin-left: 0; }
  #PageTowards.pageCertificate #Main #ContainerMenu {
    margin-top: 102px; }

  /*
  目的とポリシー（教育学部）
  -------------------------------------*/
  /*
  目的とポリシー（教育学研究科）
  -------------------------------------*/
  #PageTowards.pagePurposeU #Main .pageTitle + figure,
  #PageTowards.pagePurposeG #Main .pageTitle + figure {
    margin: 46px auto 0;
    width: calc(100% - 34px); }
  #PageTowards.pagePurposeU #Main .contBox,
  #PageTowards.pagePurposeG #Main .contBox {
    margin-top: 76px; }
    #PageTowards.pagePurposeU #Main .contBox .boxContent,
    #PageTowards.pagePurposeG #Main .contBox .boxContent {
      padding-top: 15px;
      padding-left: 0; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent p,
      #PageTowards.pagePurposeG #Main .contBox .boxContent p {
        line-height: 30px; }
        #PageTowards.pagePurposeU #Main .contBox .boxContent p strong,
        #PageTowards.pagePurposeG #Main .contBox .boxContent p strong {
          display: block;
          font-weight: bold; }
        #PageTowards.pagePurposeU #Main .contBox .boxContent p + p,
        #PageTowards.pagePurposeG #Main .contBox .boxContent p + p {
          margin-top: 30px; }
        #PageTowards.pagePurposeU #Main .contBox .boxContent p + ol,
        #PageTowards.pagePurposeG #Main .contBox .boxContent p + ol {
          margin-top: 30px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent ol li,
      #PageTowards.pagePurposeG #Main .contBox .boxContent ol li {
        line-height: 30px; }
        #PageTowards.pagePurposeU #Main .contBox .boxContent ol li p,
        #PageTowards.pagePurposeG #Main .contBox .boxContent ol li p {
          line-height: 30px; }
        #PageTowards.pagePurposeU #Main .contBox .boxContent ol li + li,
        #PageTowards.pagePurposeG #Main .contBox .boxContent ol li + li {
          margin-top: 10px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent ol + p,
      #PageTowards.pagePurposeG #Main .contBox .boxContent ol + p {
        margin-top: 30px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent ul li,
      #PageTowards.pagePurposeG #Main .contBox .boxContent ul li {
        line-height: 30px; }
      #PageTowards.pagePurposeU #Main .contBox .boxContent dl,
      #PageTowards.pagePurposeG #Main .contBox .boxContent dl {
        margin: 20px 0 40px; }
        #PageTowards.pagePurposeU #Main .contBox .boxContent dl dt,
        #PageTowards.pagePurposeG #Main .contBox .boxContent dl dt {
          padding: 22px 15px 20px;
          font-size: 16px;
          letter-spacing: .05em;
          line-height: 24px;
          text-align: justify; }
        #PageTowards.pagePurposeU #Main .contBox .boxContent dl dd,
        #PageTowards.pagePurposeG #Main .contBox .boxContent dl dd {
          padding: 21px 15px 22px; }
  #PageTowards.pagePurposeU #Main .contSubBox,
  #PageTowards.pagePurposeG #Main .contSubBox {
    width: 100%;
    margin-top: 49px;
    margin-left: 0; }
    #PageTowards.pagePurposeU #Main .contSubBox_content,
    #PageTowards.pagePurposeG #Main .contSubBox_content {
      padding-top: 31px;
      padding-left: 0; }
    #PageTowards.pagePurposeU #Main .contSubBox + dl,
    #PageTowards.pagePurposeG #Main .contSubBox + dl {
      margin-top: 40px !important; }
  #PageTowards.pagePurposeU #Main .subBox_content,
  #PageTowards.pagePurposeG #Main .subBox_content {
    padding-left: 30px; }
  #PageTowards.pagePurposeU #Main #ContBox01,
  #PageTowards.pagePurposeG #Main #ContBox01 {
    margin-top: 36px; }
  #PageTowards.pagePurposeU #Main #ContBox03,
  #PageTowards.pagePurposeG #Main #ContBox03 {
    margin-bottom: 105px; }

  /*
  オンラインによるICTを用いた学習・研究のために
  -------------------------------------*/
  #PageTowards.pageRemote #Main .contBox {
    margin-top: 76px; }
    #PageTowards.pageRemote #Main .contBox .boxContent {
      padding-top: 15px; }
      #PageTowards.pageRemote #Main .contBox .boxContent p {
        line-height: 30px; }
        #PageTowards.pageRemote #Main .contBox .boxContent p.square:before {
          top: 13px; }
      #PageTowards.pageRemote #Main .contBox .boxContent > p, #PageTowards.pageRemote #Main .contBox .boxContent > ul {
        padding-left: 0; }
      #PageTowards.pageRemote #Main .contBox .boxContent > p.square {
        padding-left: 16px; }
        #PageTowards.pageRemote #Main .contBox .boxContent > p.square:before {
          left: 0; }
        #PageTowards.pageRemote #Main .contBox .boxContent > p.square + ul {
          padding-left: 18px; }
    #PageTowards.pageRemote #Main .contBox:first-of-type {
      margin-top: 36px; }
  #PageTowards.pageRemote #Main .contSubBox {
    margin-top: 49px; }
    #PageTowards.pageRemote #Main .contSubBox_content {
      padding-top: 31px;
      padding-left: 0; }
      #PageTowards.pageRemote #Main .contSubBox_content .subBox:first-of-type {
        margin-top: 0; }
      #PageTowards.pageRemote #Main .contSubBox_content .square {
        margin-top: 30px; }
        #PageTowards.pageRemote #Main .contSubBox_content .square:first-of-type {
          margin-top: 0; }
        #PageTowards.pageRemote #Main .contSubBox_content .square + ul {
          padding-left: 28px; }
    #PageTowards.pageRemote #Main .contSubBox:first-of-type {
      margin-top: 30px; }
  #PageTowards.pageRemote #Main .subBox {
    margin-top: 40px; }
    #PageTowards.pageRemote #Main .subBox_title {
      margin-bottom: 17px; }
    #PageTowards.pageRemote #Main .subBox_content {
      padding-left: 30px; }
  #PageTowards.pageRemote #Main #ContainerMenu {
    margin-top: 102px; }

  /*
  経済的支援を必要とされる学生の方へ
  -------------------------------------*/
  #PageTowards.pageSupport #Main .contBox {
    margin-top: 76px; }
    #PageTowards.pageSupport #Main .contBox .boxContent {
      padding-top: 15px;
      padding-left: 0; }
      #PageTowards.pageSupport #Main .contBox .boxContent p {
        line-height: 30px; }
        #PageTowards.pageSupport #Main .contBox .boxContent p + p {
          margin-top: 30px; }
    #PageTowards.pageSupport #Main .contBox:first-of-type {
      margin-top: 36px; }
  #PageTowards.pageSupport #Main #ContainerMenu {
    margin-top: 102px; }

  /*
  教育学部サテライト利用の手引
  -------------------------------------*/
  #PageTowards.pageGuidance #Main .contBox {
    margin-top: 76px; }
    #PageTowards.pageGuidance #Main .contBox .boxContent {
      padding-top: 15px;
      padding-left: 0; }
      #PageTowards.pageGuidance #Main .contBox .boxContent p {
        line-height: 30px; }
        #PageTowards.pageGuidance #Main .contBox .boxContent p + p {
          margin-top: 30px; }
    #PageTowards.pageGuidance #Main .contBox:first-of-type {
      margin-top: 36px; }
  #PageTowards.pageGuidance #Main #ContainerMenu {
    margin-top: 102px; }

  /*
  ハラスメント相談窓口等について
  -------------------------------------*/
  #PageTowards.pageConsultation #MainImg #MainImgInner figure {
    padding-top: 0; }
  #PageTowards.pageConsultation #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageTowards.pageConsultation #Main .contBox {
      margin-top: 76px; }
      #PageTowards.pageConsultation #Main .contBox .boxContent {
        padding-top: 15px;
        padding-left: 0px; }
        #PageTowards.pageConsultation #Main .contBox .boxContent p {
          line-height: 30px;
          letter-spacing: .025em;
          font-size: 15px; }
    #PageTowards.pageConsultation #Main #ContBox01 {
      margin-top: 36px; }

  /*
  NEWS
  -------------------------------------*/
  #PageNews #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageNews #Main .catSearch_title {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 23px 15px; }
    #PageNews #Main .catSearch_content {
      padding: 25px 9px 20px 14px; }
      #PageNews #Main .catSearch_content ul li {
        font-size: 12px;
        line-height: 24px; }
        #PageNews #Main .catSearch_content ul li a {
          padding: 4px 14px; }
      #PageNews #Main .catSearch_content button {
        width: 117px;
        height: 40px;
        font-size: 13px;
        line-height: 38px;
        background-position: right 20px center;
        background-size: 6px auto;
        padding-left: 25px;
        margin-top: 20px; }
    #PageNews #Main .contBox .boxHead {
      margin: 39px 0 20px; }
    #PageNews #Main .pageNation {
      margin-top: 30px;
      flex-wrap: wrap;
      justify-content: right;
      background-color: #8ec21f; }
      #PageNews #Main .pageNation li {
        height: 48px; }
        #PageNews #Main .pageNation li span, #PageNews #Main .pageNation li p, #PageNews #Main .pageNation li a {
          font-size: 15px;
          line-height: 48px; }
        #PageNews #Main .pageNation li:not(.text) {
          width: calc(100% / 7); }
        #PageNews #Main .pageNation li.text {
          width: 100%; }
          #PageNews #Main .pageNation li.text p {
            text-align: center;
            border-bottom: 1px solid #4f9404; }
        #PageNews #Main .pageNation li.first a {
          background-size: 16px auto; }
        #PageNews #Main .pageNation li.prev a {
          background-size: 8px auto; }
        #PageNews #Main .pageNation li.next a {
          background-size: 8px auto; }
        #PageNews #Main .pageNation li.last a {
          background-size: 16px auto; }

  /*
  NEWS 記事詳細
  -------------------------------------*/
  #PageNews.pageEntry #Main #ContBox .boxHead .boxTitle {
    line-height: 30px; }
    #PageNews.pageEntry #Main #ContBox .boxHead .boxTitle:before {
      top: 6px; }
  #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo {
    flex-direction: column;
    padding-left: 46px;
    margin-top: 20px; }
    #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .date {
      width: 100%;
      font-size: 15px;
      margin-right: 0; }
    #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .cat {
      flex-wrap: wrap;
      width: 100%;
      background-position: left center;
      background-size: 10px auto;
      padding-left: 15px;
      margin-top: 7px; }
      #PageNews.pageEntry #Main #ContBox .boxHead .otherInfo .cat li {
        line-height: 17px;
        font-size: 13px; }
  #PageNews.pageEntry #Main #ContBox .boxContent .entryBox p {
    line-height: 30px; }
  #PageNews.pageEntry #Main #ContBox .entryPageNav {
    margin-top: 100px; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav li {
      width: 50%; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav li a {
        font-size: 13px;
        padding: 21px 0; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav .top {
      width: 100%;
      order: 1; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav .top a {
        background-color: #8ec21f;
        font-weight: bold; }
        #PageNews.pageEntry #Main #ContBox .entryPageNav .top a span {
          background-position: left top 5px;
          background-size: 18px auto;
          padding-left: 28px; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav .prev {
      order: 2; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav .prev a {
        border-right: 1px solid #fff; }
        #PageNews.pageEntry #Main #ContBox .entryPageNav .prev a span {
          background-size: 6px auto;
          padding-left: 15px; }
    #PageNews.pageEntry #Main #ContBox .entryPageNav .next {
      order: 3; }
      #PageNews.pageEntry #Main #ContBox .entryPageNav .next a span {
        background-size: 6px auto;
        padding-right: 18px; }

  /*
  検索ページ
  -------------------------------------*/
  #PageSearch.pageIndex #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageSearch.pageIndex #Main .borderTitle {
      margin-bottom: 39px; }
      #PageSearch.pageIndex #Main .borderTitle > p {
        line-height: 30px; }
    #PageSearch.pageIndex #Main .pageNation {
      margin-top: 30px;
      flex-wrap: wrap;
      justify-content: right;
      background-color: #8ec21f; }
      #PageSearch.pageIndex #Main .pageNation li {
        height: 48px; }
        #PageSearch.pageIndex #Main .pageNation li span, #PageSearch.pageIndex #Main .pageNation li p, #PageSearch.pageIndex #Main .pageNation li a {
          font-size: 15px;
          line-height: 48px; }
        #PageSearch.pageIndex #Main .pageNation li:not(.text) {
          width: calc(100% / 7); }
        #PageSearch.pageIndex #Main .pageNation li.text {
          width: 100%; }
          #PageSearch.pageIndex #Main .pageNation li.text p {
            text-align: center;
            border-bottom: 1px solid #4f9404; }
        #PageSearch.pageIndex #Main .pageNation li.first a {
          background-size: 16px auto; }
        #PageSearch.pageIndex #Main .pageNation li.prev a {
          background-size: 8px auto; }
        #PageSearch.pageIndex #Main .pageNation li.next a {
          background-size: 8px auto; }
        #PageSearch.pageIndex #Main .pageNation li.last a {
          background-size: 16px auto; }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePrivacy.pageIndex #MainImg #MainImgInner figure {
    padding-top: 0; }
  #PagePrivacy.pageIndex #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PagePrivacy.pageIndex #Main #ContBox01 {
      margin-top: 48px; }
      #PagePrivacy.pageIndex #Main #ContBox01 p {
        line-height: 30px; }
        #PagePrivacy.pageIndex #Main #ContBox01 p + p {
          margin-top: 30px; }
      #PagePrivacy.pageIndex #Main #ContBox01 .contSubBox_content {
        padding-top: 31px;
        padding-left: 0px; }
      #PagePrivacy.pageIndex #Main #ContBox01 .contSubBox + .contSubBox {
        margin-top: 49px; }
      #PagePrivacy.pageIndex #Main #ContBox01 .subBox_content {
        padding-left: 30px; }

  /*
  教職員の方へ
  -------------------------------------*/
  #PageTeachers.pageIndex #MainImg #MainImgInner figure {
    padding-top: 0; }
  #PageTeachers.pageIndex #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #PageTeachers.pageIndex #Main .contBox {
      margin-top: 76px; }
      #PageTeachers.pageIndex #Main .contBox .boxContent {
        padding-top: 15px;
        padding-left: 0px; }
    #PageTeachers.pageIndex #Main #ContBox01 {
      margin-top: 36px; }

  /*
  404
  -------------------------------------*/
  #Page404.pageIndex #Main {
    padding-top: 72px;
    padding-bottom: 90px; }
    #Page404.pageIndex #Main .contBox {
      margin-top: 76px; }
      #Page404.pageIndex #Main .contBox .linkBtn .button {
        height: 36px;
        line-height: 34px;
        letter-spacing: .025em;
        font-size: 12px; } }
